import { IonModal, IonButton, IonText } from '@ionic/react';
import { FC } from 'react';
import { CloseIcon } from 'src/assets/icons';

import './ConfirmModal.scss';

interface ConfirmModalProps {
  isOpen: boolean;
  title: string;
  subText?: string;
  onDismiss: (isConfirmed: boolean) => void;
  children?: JSX.Element;
  disableConfirmBtn?: boolean;
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  isOpen,
  onDismiss,
  title,
  subText,
  children,
  disableConfirmBtn,
}) => {
  return (
    <IonModal
      isOpen={isOpen}
      className='confirmModal'
      onDidDismiss={() => onDismiss(false)}
    >
      <div className='confirmModal__header'>
        <IonButton
          className='confirmModal__close'
          fill='clear'
          onClick={() => onDismiss(false)}
          color={'dark'}
        >
          <CloseIcon />
        </IonButton>
        <IonText color={'dark'} className='confirmModal__title'>
          <h4>{title}</h4>
        </IonText>
      </div>
      <IonText>
        <p className='confirmModal__text'>{subText}</p>
      </IonText>

      {children}

      <div className='confirmModal__actions'>
        <IonButton
          onClick={() => onDismiss(false)}
          expand='full'
          fill='solid'
          color={'medium'}
        >
          Cancel
        </IonButton>
        <IonButton
          onClick={() => onDismiss(true)}
          expand='full'
          fill='solid'
          color={'primary'}
          disabled={disableConfirmBtn}
        >
          Confirm
        </IonButton>
      </div>
    </IonModal>
  );
};

export default ConfirmModal;
