import {
  IonChip,
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSkeletonText,
  IonText,
  useIonToast,
  useIonViewWillEnter,
} from '@ionic/react';
import { FC, useEffect, useState } from 'react';

import { QueryFilters, Transaction } from 'src/types';
import { fetchTransactions } from '../../api';
import { isApolloError } from '@apollo/client';

import TransactionItem from '../../components/TransactionItem';

import cx from 'classnames';
import './Transactions.scss';

interface TransactionsState {
  page: number;
  transactions: Transaction[];
  totalTransactions: number;
  activeDirection: TransactionDirections | '';
  isFetchingTransactions: boolean;
}

enum TransactionDirections {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
}

const Transactions: FC = () => {
  const [present] = useIonToast();

  const [state, setState] = useState<TransactionsState>({
    page: 1,
    transactions: [],
    activeDirection: '',
    totalTransactions: 0,
    isFetchingTransactions: true,
  });

  const handleStateUpdate = (newState: Partial<TransactionsState>) =>
    setState({ ...state, ...newState });

  const setActiveDirection = (direction: TransactionDirections | '') => {
    handleStateUpdate({ activeDirection: direction });
  };

  const handleFetchTransactions = (filters: QueryFilters) => {
    return fetchTransactions(filters);
  };

  const getTransactions = (refresh = false) => {
    handleStateUpdate({
      isFetchingTransactions: refresh,
    });
    let filters: QueryFilters = {
      limit: 10,
      offset: refresh ? 0 : state.page * 10,
      order_by: {
        created_at: 'desc',
      },
    };

    if (state.activeDirection) {
      filters = {
        ...filters,
        where: {
          ...filters.where,
          direction: { _eq: state.activeDirection },
        },
      };
    }

    return handleFetchTransactions(filters)
      .then((res) => {
        handleStateUpdate({
          isFetchingTransactions: false,
          transactions: refresh
            ? res.data.transaction
            : [...state.transactions, ...res.data.transaction],
          totalTransactions: res.data.transaction_aggregate.aggregate.count,
          page: refresh ? 1 : state.page + 1,
        });
      })
      .catch((err: any) => {
        isApolloError(err) &&
          present({
            message: err.graphQLErrors[0].message,
            duration: 3000,
            color: 'danger',
            position: 'bottom',
          });
        handleStateUpdate({
          isFetchingTransactions: false,
        });
      });
  };

  useIonViewWillEnter(() => {
    getTransactions(true);
  });

  useEffect(() => {
    getTransactions(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.activeDirection]);

  const renderTransactions = () => {
    if (state.isFetchingTransactions) {
      return (
        <>
          <IonItem className='transactions__skeleton'>
            <IonSkeletonText
              className='transactions__skeletonAvatar'
              slot='start'
              animated={true}
            ></IonSkeletonText>
            <IonLabel>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
            </IonLabel>
          </IonItem>
          <IonItem className='transactions__skeleton'>
            <IonSkeletonText
              className='transactions__skeletonAvatar'
              slot='start'
              animated={true}
            ></IonSkeletonText>
            <IonLabel>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
            </IonLabel>
          </IonItem>
          <IonItem className='transactions__skeleton'>
            <IonSkeletonText
              className='transactions__skeletonAvatar'
              slot='start'
              animated={true}
            ></IonSkeletonText>
            <IonLabel>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
            </IonLabel>
          </IonItem>
        </>
      );
    }
    if (state.transactions.length === 0) {
      return (
        <IonItem>
          <IonLabel>
            <p>No transactions found.</p>
          </IonLabel>
        </IonItem>
      );
    }
    return state.transactions.map((transaction) => (
      <TransactionItem transaction={transaction} key={transaction.id} />
    ));
  };

  return (
    <IonPage className='transactions'>
      <IonHeader className='transactions__header' translucent={true}>
        <div className='transactions__headerBg'></div>
        <div className='transactions__headerContent'>
          <div className='transactions__headerTop'>
            <div className='transactions__title'>
              <IonText>
                <h1>Transactions</h1>
              </IonText>
            </div>
          </div>
          <div className='transactions__filters'>
            <IonChip
              disabled={state.isFetchingTransactions}
              onClick={() => setActiveDirection('')}
              className={cx('transactions__filter', {
                'transactions__filter--active': !state.activeDirection,
              })}
            >
              ALL
            </IonChip>
            <IonChip
              disabled={state.isFetchingTransactions}
              onClick={() => setActiveDirection(TransactionDirections.DEBIT)}
              className={cx('transactions__filter', {
                'transactions__filter--active':
                  state.activeDirection === TransactionDirections.DEBIT,
              })}
            >
              DEBIT
            </IonChip>
            <IonChip
              disabled={state.isFetchingTransactions}
              onClick={() => setActiveDirection(TransactionDirections.CREDIT)}
              className={cx('transactions__filter', {
                'transactions__filter--active':
                  state.activeDirection === TransactionDirections.CREDIT,
              })}
            >
              CREDIT
            </IonChip>
          </div>
        </div>
      </IonHeader>
      <IonContent className='transactions__content' fullscreen={true}>
        <IonList lines='full'>{renderTransactions()}</IonList>
        <IonInfiniteScroll
          onIonInfinite={async (ev) => {
            if (state.transactions.length >= state.totalTransactions) {
              ev.target.complete();
              return;
            }
            await getTransactions();
            ev.target.complete();
          }}
        >
          <IonInfiniteScrollContent loadingSpinner='crescent'></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
};

export default Transactions;
