import { IonAvatar } from '@ionic/react';
import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { nameToColor } from '../../helpers';

import './Avatar.scss';

interface AvatarProps extends HTMLAttributes<HTMLIonAvatarElement> {
  title?: string;
}

const Avatar: FC<PropsWithChildren<AvatarProps>> = ({
  children,
  title,
  className,
  ...rest
}) => {
  return (
    <IonAvatar
      {...rest}
      style={{
        backgroundColor: title ? nameToColor(title) : '',
      }}
      className={`avatar ${className} ${!children ? 'avatar--noImg' : ''}`}
    >
      {children ? children : title?.charAt(0)}
    </IonAvatar>
  );
};

export default Avatar;
