import { object, string } from 'yup';

export const otpValidationSchema = object({
  char1: string().required(),
  char2: string().required(),
  char3: string().required(),
  char4: string().required(),
});

export const kycValidationSchema = object({
  bvn: string().required('BVN is required'),
  dob: string().required('Date of birth is required'),
});
