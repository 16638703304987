import { apiService } from 'src/helpers';
import {
  GET_BANKS,
  GET_CURRENCIES,
  GET_PAYMENT_SOURCES,
  GET_PROFILE,
} from './queries';
import { Bank, RSProfile } from './types';
import { Currency, PaymentSource, User } from 'src/types';
import { UPDATE_USER, VALIDATE_PIN } from './mutations';

export const fetchProfile = (id: string) => {
  return apiService.query<RSProfile>({
    query: GET_PROFILE,
    variables: { id },
    fetchPolicy: 'network-only',
  });
};

export const updateUser = (id: string, user: Partial<User>) => {
  return apiService.mutate<{ update_user_by_pk: User }>({
    mutation: UPDATE_USER,
    variables: { id, user },
  });
};

export const fetchPaymentSources = () => {
  return apiService.query<{ payment_source: PaymentSource[] }>({
    query: GET_PAYMENT_SOURCES,
  });
};

export const fetchCurrencies = () => {
  return apiService.query<{ currency: Currency[] }>({
    query: GET_CURRENCIES,
  });
};

export const fetchBanks = () => {
  return apiService.query<{
    getBanks: { data: Bank[] };
  }>({
    query: GET_BANKS,
    fetchPolicy: 'network-only',
  });
};

export const validatePin = (pin: string) => {
  return apiService.mutate<{
    validatePin: { data: { access_token: string; expires_in: number } };
  }>({
    mutation: VALIDATE_PIN,
    variables: { pin },
  });
};
