import { FC, useContext } from 'react';
import { useHistory } from 'react-router';
import {
  IonPage,
  IonHeader,
  IonButton,
  IonText,
  IonContent,
  IonSelect,
  IonSelectOption,
  IonFooter,
  IonToolbar,
  IonSpinner,
  useIonRouter,
  useIonToast,
  IonCheckbox,
} from '@ionic/react';

import { currencyFormatter } from 'src/helpers';

import AmountInput from 'src/components/AmountInput';

import { CloseIcon } from 'src/assets/icons';
import AppContext from 'src/AppContext';
import { isApolloError } from '@apollo/client';
import { useFormik } from 'formik';

import './PostTradeRequest.scss';
import { postTradeRequest } from '../../api';
import { adsTabPath } from 'src/data/pageRoutes';
import { AdsView } from 'src/types';
import { postTradeRequestValidationSchema } from '../../validators';

const PostTradeRequest: FC = () => {
  const history = useHistory();
  const router = useIonRouter();
  const [present] = useIonToast();

  const { profile, currencies, paymentSources } = useContext(AppContext);

  const destinationCurrencies = currencies.filter(
    (currency) => currency.is_destination
  );

  const initialValues = {
    destination_currency: 'NGN',
    source_currency: 'USD',
    rate: '',
    amount: '',
    payment_sources: [],
  };

  const onSubmit = async ({
    payment_sources,
    ...values
  }: typeof initialValues) => {
    const data = {
      ...values,
      is_active: true,
      user_id: profile?.id!,
      trade_request_payment_sources: {
        data: payment_sources.map((id: string) => ({
          payment_source_id: id,
        })),
      },
    };
    try {
      await postTradeRequest(data);
      present({
        message: 'Request created successfully',
        duration: 3000,
        color: 'success',
        position: 'bottom',
      });
      router.push(`${adsTabPath}?view=${AdsView.TradeRequests}`, 'back');
    } catch (e: any) {
      isApolloError(e) &&
        present({
          message: e.graphQLErrors[0].message,
          duration: 3000,
          color: 'danger',
          position: 'bottom',
        });
    }
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    isValid,
    isSubmitting,
  } = useFormik<typeof initialValues>({
    initialValues,
    onSubmit,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: postTradeRequestValidationSchema,
  });

  const onBackClick = () => {
    history.goBack();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <IonPage className='postTradeRequest'>
          <IonHeader className='postTradeRequest__header' translucent={true}>
            <div className='postTradeRequest__headerBg'></div>
            <div className='postTradeRequest__headerContent'>
              <IonButton
                fill='clear'
                color={'dark'}
                onClick={onBackClick}
                routerDirection='back'
                className='postTradeRequest__headerContentButton'
              >
                <CloseIcon />
              </IonButton>
              <IonText
                color={'dark'}
                className='postTradeRequest__headerContentTitle'
              >
                <h1>Post a Trade Request</h1>
              </IonText>
            </div>
          </IonHeader>
          <IonContent className='postTradeRequest__content' fullscreen={true}>
            <div className='postTradeRequest__currencies'>
              <div className='postTradeRequest__currency postTradeRequest__currency--holding'>
                <IonText>
                  <h2>You have</h2>
                </IonText>
                <IonSelect
                  legacy={true}
                  placeholder=''
                  onIonBlur={handleBlur}
                  onIonChange={handleChange}
                  name='source_currency'
                  value={values.source_currency}
                >
                  {currencies
                    .filter((currency) => currency.is_source)
                    .map((currency) => (
                      <IonSelectOption
                        key={currency.code}
                        value={currency.code}
                      >
                        {currency.code}
                      </IonSelectOption>
                    ))}
                </IonSelect>
              </div>
              <div className='postTradeRequest__currency postTradeRequest__currency--trade'>
                <IonText>
                  <h2>to Swap for </h2>
                </IonText>
                {destinationCurrencies.length > 1 ? (
                  <IonSelect
                    legacy={true}
                    placeholder=''
                    onIonBlur={handleBlur}
                    onIonChange={handleChange}
                    name='source_currency'
                    value={values.source_currency}
                  >
                    {destinationCurrencies.map((currency) => (
                      <IonSelectOption
                        key={currency.code}
                        value={currency.code}
                      >
                        {currency.code}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                ) : (
                  <IonText color='primary'>
                    <h2>{destinationCurrencies[0].code}</h2>
                  </IonText>
                )}
              </div>
            </div>
            <div className='postTradeRequest__rate'>
              <IonText color={'dark'} className='postTradeRequest__rateLabel'>
                <h3>Rate</h3>
              </IonText>
              <div className='postTradeRequest__rateInput'>
                <IonText
                  className='postTradeRequest__rateCurrency'
                  color='medium'
                >
                  <span>
                    {currencyFormatter({
                      style: 'currency',
                      value: 0,
                      minimumFractionDigits: 0,
                      maximumSignificantDigits: 1,
                      currency: values.destination_currency,
                    })
                      .replace(/\d/g, '')
                      .trim()}
                  </span>
                </IonText>
                <AmountInput
                  color='light'
                  placeholder='0.00'
                  name='rate'
                  className='rate'
                  showLabel={false}
                  showCurrency={false}
                  onValueChange={(val: number) => setFieldValue('rate', val)}
                />
                <IonText
                  className='postTradeRequest__rateCurrency--trade'
                  color='dark'
                >
                  <span>per {values.source_currency}</span>
                </IonText>
              </div>
              {/* <IonText color={'dark'} className='postTradeRequest__rateInfo'>
            <span>*highest rate ₦800</span>
          </IonText> */}
            </div>
            <div className='postTradeRequest__amount'>
              <AmountInput
                label='Amount'
                name='amount'
                showLabel={true}
                placeholder='0.00'
                currency={values.source_currency}
                showCurrency={true}
                onValueChange={(val: number) => setFieldValue('amount', val)}
              />
            </div>
            <div className='postTradeRequest__paymentMethods'>
              <div className='postTradeRequest__paymentMethodsHeader'>
                <IonText
                  color={'dark'}
                  className='postTradeRequest__paymentMethodsTitle'
                >
                  <h3>Payment Methods</h3>
                </IonText>
              </div>
              <IonText
                color={'medium'}
                className='postTradeRequest__paymentMethodsInfo'
              >
                <p>Select up to 5 payment methods</p>
                <div className='postTradeRequest__paymentMethodsList'>
                  {paymentSources
                    .filter((source) =>
                      source.supported_currencies.includes(
                        values.source_currency
                      )
                    )
                    .map((source) => (
                      <IonCheckbox
                        key={source.id}
                        mode='md'
                        labelPlacement='end'
                        justify='start'
                        value={source.id}
                        onIonChange={(e) => {
                          if (e.detail.checked) {
                            setFieldValue('payment_sources', [
                              ...values.payment_sources,
                              source.id,
                            ]);
                          } else {
                            setFieldValue(
                              'payment_sources',
                              values.payment_sources.filter(
                                (id) => id !== source.id
                              )
                            );
                          }
                        }}
                      >
                        {source?.name}
                      </IonCheckbox>
                    ))}
                </div>
              </IonText>
            </div>
          </IonContent>
          <IonFooter className='postTradeRequest__footer'>
            <IonToolbar>
              <IonButton
                type='submit'
                className='postTradeRequest__postBtn'
                expand='full'
                disabled={!isValid || isSubmitting}
              >
                Create Request
                {isSubmitting ? (
                  <IonSpinner name='crescent'></IonSpinner>
                ) : null}
              </IonButton>
            </IonToolbar>
          </IonFooter>
        </IonPage>
      </form>
    </>
  );
};

export default PostTradeRequest;
