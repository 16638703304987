import { gql } from '@apollo/client';

export const GET_AD_BY_PK = gql`
  query getAdByPk($id: uuid!) {
    ad_by_pk(id: $id) {
      limit_max
      limit_min
      destination_currency
      source_currency
      rate

      ad_payment_methods {
        payment_method {
          id
          primary_field
          source_id
          data
          payment_source {
            name
          }
        }
        created_at
        id
      }

      user {
        firstname
      }
    }
  }
`;

export const GET_TRADE_REQUEST_BY_PK = gql`
  query getTradeRequestByPk($id: uuid!, $userId: uuid!) {
    trade_request_by_pk(id: $id) {
      id
      rate
      amount
      is_active
      created_at
      updated_at
      source_currency
      destination_currency
      user {
        firstname
        id
        lastname
      }
      trade_request_payment_sources {
        payment_source {
          name
          id
          is_active
          fields
        }
      }
      negotiations(where: { user_id: { _eq: $userId } }) {
        id
      }
    }
  }
`;

export const GET_CHAT_BY_PK = gql`
  query getChatByPk($id: uuid!) {
    chat_by_pk(id: $id) {
      id
      created_at
      trade {
        id
        customer_receivable_amount
        merchant_receivable_amount
        status
        rate
        user {
          id
          firstname
        }

        ad {
          rate
          destination_currency
          source_currency
          user {
            id
            firstname
          }
        }
      }

      messages {
        id
        body
        byAdmin
        chatId
        created_at
        file_type
        file_url
        user {
          id
          lastname
          firstname
        }
      }
    }
  }
`;

export const GET_NEGOTIATION_BY_PK = gql`
  query getNegotiationByPk($id: uuid!) {
    negotiation_by_pk(id: $id) {
      id
      user {
        id
        firstname
        lastname
      }
      trade_request_id
      trade_request {
        id
        rate
        amount
        is_active
        created_at
        updated_at
        source_currency
        destination_currency
        user {
          id
          firstname
          lastname
        }
      }
      messages {
        id
        body
        chatId
        created_at
        file_type
        file_url
        user {
          id
          lastname
          firstname
        }
      }
    }
  }
`;

export const SUBSCRIBE_TO_CHAT_MESSAGES = gql`
  subscription subscribeToChatMessages($id: uuid!, $from: timestamptz!) {
    message(
      where: { chatId: { _eq: $id }, created_at: { _gt: $from } }
      order_by: { created_at: desc }
      limit: 1
    ) {
      id
      body
      chatId
      byAdmin
      file_url
      file_type
      created_at
      user {
        id
        lastname
        firstname
      }
    }
  }
`;

export const SUBSCRIBE_TO_NEGOTIATION_MESSAGES = gql`
  subscription subscribeToNegotiationMessages($id: uuid!, $from: timestamptz!) {
    message(
      where: { negotiation_id: { _eq: $id }, created_at: { _gt: $from } }
      order_by: { created_at: desc }
      limit: 1
    ) {
      id
      body
      negotiation_id
      byAdmin
      file_url
      file_type
      created_at
      user {
        id
        lastname
        firstname
      }
    }
  }
`;

export const GET_TRADE_BY_PK = gql`
  query getTradeByPk($id: uuid!) {
    trade_by_pk(id: $id) {
      amount_in_escrow
      ad_id
      created_at
      auto_dispute_time
      auto_cancellation_time
      customer_receivable_amount
      merchant_receivable_amount
      id
      status
      rate
      updated_at
      user {
        created_at
        email
        firstname
        id
        lastname
      }

      ad {
        destination_currency
        rate
        source_currency
        id
        user {
          firstname
          id
          lastname
          email
          completed_trades {
            total
          }
        }
      }
      payment_method {
        data
        payment_source {
          fields
          is_active
          name
        }
        primary_field
      }

      chats {
        id
      }
    }
  }
`;

export const SUBSCRIBE_TRADE_BY_PK = gql`
  subscription subscribeTradeByPk($id: uuid!) {
    trade_by_pk(id: $id) {
      amount_in_escrow
      ad_id
      created_at
      auto_dispute_time
      auto_cancellation_time
      customer_receivable_amount
      merchant_receivable_amount
      id
      rate
      status
      updated_at
      user {
        created_at
        email
        firstname
        id
        lastname
      }

      ad {
        destination_currency
        rate
        source_currency
        id
        user {
          firstname
          id
          lastname
          email
          completed_trades {
            total
          }
        }
      }
      payment_method {
        data
        payment_source {
          fields
          is_active
          name
        }
        primary_field
      }

      chats {
        id
      }
    }
  }
`;

export const GET_TRANSACTION_FEE = gql`
  query GetTransactionFee($amount: Int!) {
    getTransactionFee(amount: $amount) {
      fee
    }
  }
`;

export const GET_NEGOTIATIONS = gql`
  query GetNegotiations($tradeRequestId: uuid!) {
    negotiation(where: { trade_request_id: { _eq: $tradeRequestId } }) {
      id
      created_at
      user {
        id
        firstname
        lastname
      }
      trade_request_id
      messages {
        id
        body
        chatId
        created_at
        file_type
        file_url
        user {
          id
          lastname
          firstname
        }
        created_at
      }
    }
  }
`;
