import { object, string } from 'yup';

export const saveBankAccountSchema = object({
  bank_code: string().required('Please select a bank'),
  account_number: string()
    .required('Please enter an account number')
    .min(10, 'Account number must be 10 digits long min')
    .max(10, 'Account number must be 10 digits long max'),
  account_name: string().required('Please verify your bank account '),
});
