import { FC } from 'react';
import { Route } from 'react-router';
import { IonRouterOutlet } from '@ionic/react';

import { transactionDetailsPath } from '../../data/pageRoutes';

import TransactionDetails from './pages/TransactionDetails';

const Transaction: FC = () => {
  return (
    <IonRouterOutlet>
      <Route
        exact
        path={`${transactionDetailsPath}/:id`}
        component={TransactionDetails}
      />
    </IonRouterOutlet>
  );
};

export default Transaction;
