import { formatDistanceToNow } from 'date-fns';
import { startCase, omit } from 'lodash';
import { TradeStatus, Transaction, TransactionDirection } from 'src/types';

export const currencyFormatter = ({
  value,
  style = 'currency',
  currencyDisplay = 'narrowSymbol',
  ...options
}: {
  value: number;
} & Intl.NumberFormatOptions) => {
  try {
    const formatter = new Intl.NumberFormat(['en-NG'], {
      style,
      currencyDisplay,
      ...options,
    });
    return formatter.format(value);
  } catch {
    return `${options.currency} ${new Intl.NumberFormat(['en-NG'], {
      style: 'decimal',
      currencyDisplay,
      ...omit(options, 'currency'),
    }).format(value)}`;
  }
};

export function getCurrencySymbol(currency: string) {
  return currencyFormatter({
    style: 'currency',
    value: 0,
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
    currency,
  })
    .replace(/\d/g, '')
    .trim();
}

export function nairaToKobo(value: number) {
  return value * 100;
}

export function koboToNaira(value: number) {
  return value / 100;
}

export const timeToNow = (time: string) => {
  return formatDistanceToNow(new Date(time), { addSuffix: true });
};

export const formatTradeStatus = (status?: TradeStatus) => {
  if (!status) {
    return '';
  }
  return startCase(status.replace('_', ' ').toLowerCase());
};

export const getTransactionAmount = (transaction: Transaction | null) =>
  transaction
    ? transaction.direction === TransactionDirection.CREDIT
      ? transaction.amount - transaction.fees
      : transaction.amount + transaction.fees
    : 0;
