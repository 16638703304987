import { FC, useContext, useEffect, useState } from 'react';

import { AdsCategory } from '../../types';
import {
  IObject,
  QueryFilters,
  TradeRequest as TradeRequestType,
} from 'src/types';
import {
  IonFab,
  IonFabButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonSkeletonText,
  useIonToast,
} from '@ionic/react';
import AppContext from 'src/AppContext';
import { fetchTradeRequests } from '../../api';
import TradeRequest from '../TradeRequest';
import { nairaToKobo } from 'src/helpers';
import { isApolloError } from '@apollo/client';
import { postTradeRequestPath } from 'src/data/pageRoutes';
import { PlusIcon } from 'src/assets/icons';

import './TradeRequestsList.scss';

interface TradeRequestsListProps {
  activeCategory: AdsCategory;
  filterValues: IObject;
  updateParentState: (newState: IObject) => void;
}

interface TradeRequestsListState {
  isFetchingAds: boolean;
  page: number;
  totalAds: number;
  tradeRequests: TradeRequestType[];
}

const TradeRequestsList: FC<TradeRequestsListProps> = ({
  activeCategory,
  filterValues,
  updateParentState,
}) => {
  const [present] = useIonToast();
  const { profile } = useContext(AppContext);

  const [state, setState] = useState<TradeRequestsListState>({
    isFetchingAds: false,
    page: 1,
    totalAds: 0,
    tradeRequests: [],
  });

  const handleStateUpdate = (newState: Partial<TradeRequestsListState>) =>
    setState((_state) => ({ ..._state, ...newState }));

  const handleFetchAds = (filters: QueryFilters) => {
    return fetchTradeRequests(filters);
  };

  const getTradeRequests = (refresh = false) => {
    handleStateUpdate({ isFetchingAds: refresh });
    updateParentState({ isFetchingData: true });
    let filters: QueryFilters = {
      limit: 10,
      offset: refresh ? 0 : state.page * 10,
      order_by: {
        updated_at: 'desc',
      },
    };

    if (activeCategory === AdsCategory.MY_ADS) {
      filters = {
        ...filters,
        where: {
          ...filters.where,
          user_id: {
            _eq: profile?.id,
          },
        },
      };
    }

    if (activeCategory === AdsCategory.ALL_ADS) {
      filters = {
        ...filters,
        where: {
          ...filters.where,
          is_active: { _eq: true },
        },
      };
    }

    if (filterValues.amount) {
      filters = {
        ...filters,
        where: {
          ...filters.where,
          amount: { _gte: nairaToKobo(+filterValues.amount) },
        },
      };
    }

    if (filterValues.paymentSource) {
      filters = {
        ...filters,
        where: {
          ...filters.where,
          trade_request_payment_sources: {
            payment_source: { id: { _eq: filterValues.paymentSource } },
          },
        },
      };
    }

    if (filterValues.currency) {
      filters = {
        ...filters,
        where: {
          ...filters.where,
          source_currency: { _eq: filterValues.currency },
        },
      };
    }

    return handleFetchAds(filters)
      .then((res) => {
        handleStateUpdate({
          isFetchingAds: false,
          tradeRequests: refresh
            ? res.data.trade_request
            : [...state.tradeRequests, ...res.data.trade_request],
          totalAds: res.data.trade_request_aggregate.aggregate.count,
          page: refresh ? 1 : state.page + 1,
        });
        updateParentState({ isFetchingData: false });
      })
      .catch((err: any) => {
        isApolloError(err) &&
          present({
            message: err.graphQLErrors[0].message,
            duration: 3000,
            color: 'danger',
            position: 'bottom',
          });
        updateParentState({ isFetchingData: false });
        handleStateUpdate({ isFetchingAds: false });
      });
  };

  useEffect(() => {
    getTradeRequests(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory, filterValues]);

  const renderTradeRequests = () => {
    if (state.isFetchingAds) {
      return (
        <>
          <IonItem className='listings__skeleton'>
            <IonSkeletonText
              className='listings__skeletonAvatar'
              slot='start'
              animated={true}
            ></IonSkeletonText>
            <IonLabel>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
            </IonLabel>
          </IonItem>
          <IonItem className='listings__skeleton'>
            <IonSkeletonText
              className='listings__skeletonAvatar'
              slot='start'
              animated={true}
            ></IonSkeletonText>
            <IonLabel>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
            </IonLabel>
          </IonItem>
          <IonItem className='listings__skeleton'>
            <IonSkeletonText
              className='listings__skeletonAvatar'
              slot='start'
              animated={true}
            ></IonSkeletonText>
            <IonLabel>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
            </IonLabel>
          </IonItem>
        </>
      );
    }
    if (!state.tradeRequests.length) {
      return (
        <IonItem className='listings__empty'>
          <IonLabel>
            <p>No requests match your search criteria</p>
          </IonLabel>
        </IonItem>
      );
    }
    return (
      <>
        {state.tradeRequests.map((tradeRequest) => (
          <TradeRequest
            key={tradeRequest.id}
            tradeRequest={tradeRequest}
            refreshList={() => getTradeRequests(true)}
          />
        ))}
      </>
    );
  };

  return (
    <>
      <IonList className='listings__list' lines='full'>
        {renderTradeRequests()}
      </IonList>

      <IonInfiniteScroll
        onIonInfinite={async (ev) => {
          if (state.tradeRequests.length >= state.totalAds) {
            ev.target.complete();
            return;
          }
          await getTradeRequests();
          ev.target.complete();
        }}
      >
        <IonInfiniteScrollContent loadingSpinner='crescent'></IonInfiniteScrollContent>
      </IonInfiniteScroll>

      <IonFab slot='fixed' vertical='bottom' horizontal='end'>
        <IonFabButton
          routerLink={postTradeRequestPath}
          size='small'
          color='medium'
        >
          <PlusIcon />
          <span>Create Request</span>
        </IonFabButton>
      </IonFab>
    </>
  );
};

export default TradeRequestsList;
