import { IonItem, IonLabel, IonInput, IonText } from '@ionic/react';
import cx from 'classnames';
import { FC, HTMLAttributes, useEffect, useState } from 'react';
import { useMaskito } from '@maskito/react';
import { maskitoNumberOptionsGenerator } from '@maskito/kit';

import { currencyFormatter, getCurrencySymbol } from 'src/helpers';

import './AmountInput.scss';
import { isNil } from 'lodash';

interface AmountInputProps extends HTMLAttributes<HTMLIonInputElement> {
  className?: string;
  currency?: string;
  label?: string;
  showLabel?: boolean;
  showCurrency?: boolean;
  name?: string;
  value?: number;
  onValueChange: (value: number) => void;
  disabled?: boolean;
}

const AmountInput: FC<AmountInputProps> = ({
  label,
  currency,
  showCurrency,
  className,
  onValueChange,
  showLabel,
  value,
  ...rest
}) => {
  const [inputValue, setState] = useState(0);
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  const moneyMask = useMaskito({
    options: maskitoNumberOptionsGenerator({
      decimalSeparator: '.',
      thousandSeparator: ',',
      precision: 2,
    }),
  });

  useEffect(() => {
    if (!isNil(value) && value !== inputValue) {
      setState(value!);
    }
  }, [value, inputValue]);

  useEffect(() => {
    if (!isNil(value) && inputRef) {
      inputRef.value = currencyFormatter({
        style: 'decimal',
        value: value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef]);

  return (
    <IonItem className={cx(['amountInput', className])}>
      {showLabel && (
        <IonLabel position='stacked' className='amountInput__label'>
          {label}
        </IonLabel>
      )}

      <IonInput
        type='text'
        inputMode='decimal'
        {...rest}
        onIonInput={(e) => {
          const value = +e.detail.value?.replace(/,/g, '')!;
          setState(value);
          onValueChange(value);
        }}
        ref={(inputRef) => {
          if (inputRef) {
            setTimeout(() => {
              const input = inputRef.querySelector('input');
              setInputRef(input);
              moneyMask(input);
            }, 100);
          }
        }}
      >
        {showCurrency && (
          <IonText slot='start' aria-hidden='true'>
            {getCurrencySymbol(currency as string)} &nbsp;
          </IonText>
        )}
      </IonInput>
    </IonItem>
  );
};

AmountInput.defaultProps = {
  currency: 'NGN',
  showCurrency: true,
  showLabel: true,
};

export default AmountInput;
