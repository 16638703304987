import {
  IonBadge,
  IonButton,
  IonItem,
  IonLabel,
  IonLoading,
  IonPopover,
  IonText,
  useIonRouter,
  useIonToast,
} from '@ionic/react';
import { FC, useContext, useState } from 'react';
import AppContext from 'src/AppContext';
import { currencyFormatter } from 'src/helpers';
import { TradeRequest as TradeRequestType } from 'src/types';

import Avatar from '../../../../components/Avatar';

import './TradeRequest.scss';
import ConfirmModal from '../../modals/ConfirmModal';
import { acceptTradeRequest, updateTradeRequest } from '../../api';
import { isApolloError } from '@apollo/client';
import cx from 'classnames';
import AcceptTradeRequestModal from '../../modals/AcceptTradeRequestModal';
import {
  acceptTradeRequestPath,
  tradeDetailsPath,
  tradeRequestNegotiationsPath,
} from 'src/data/pageRoutes';
import { KebabIcon } from 'src/assets/icons';
import EditTradeRequestModal from '../../modals/EditTradeRequestModal';

interface TradeRequestProps {
  tradeRequest: TradeRequestType;
  refreshList?: () => void;
}

interface TradeRequestState {
  isConfirmModalOpen: boolean;
  isUpdatingRequest: boolean;
  isAcceptModalOpen: boolean;
  isAcceptingRequest: boolean;
  isEditModalOpen: boolean;
}

const TradeRequest: FC<TradeRequestProps> = ({ tradeRequest, refreshList }) => {
  const { profile, authorizeAction } = useContext(AppContext);
  const [present] = useIonToast();
  const router = useIonRouter();

  const sourceCurrency = tradeRequest.source_currency;
  const destinationCurrency = tradeRequest.destination_currency;
  const isOwnRequest = profile?.id === tradeRequest.user.id;

  const [state, setState] = useState<TradeRequestState>({
    isConfirmModalOpen: false,
    isUpdatingRequest: false,
    isAcceptModalOpen: false,
    isAcceptingRequest: false,
    isEditModalOpen: false,
  });

  const handleStateUpdate = (newState: Partial<TradeRequestState>) =>
    setState((_state) => ({ ..._state, ...newState }));

  const onConfirmModalDismiss = (isConfirmed: boolean) => {
    if (!isConfirmed) {
      handleStateUpdate({ isConfirmModalOpen: false });
      return;
    }
    handleStateUpdate({ isConfirmModalOpen: false });
    authorizeAction(async (authorizationToken: string) => {
      handleStateUpdate({ isUpdatingRequest: true });
      try {
        await updateTradeRequest(
          { id: tradeRequest.id, is_active: false },
          authorizationToken
        );
        handleStateUpdate({ isUpdatingRequest: false });
        refreshList && refreshList();
      } catch (e: any) {
        isApolloError(e) &&
          present({
            message: e.graphQLErrors[0].message,
            duration: 3000,
            color: 'danger',
            position: 'bottom',
          });
        handleStateUpdate({
          isUpdatingRequest: false,
          isConfirmModalOpen: false,
        });
      }
    });
  };

  const onAcceptModalDismiss = (
    isAccepted: boolean,
    paymentMethodId?: string
  ) => {
    if (!isAccepted) {
      handleStateUpdate({ isAcceptModalOpen: false });
      return;
    }
    if (paymentMethodId) {
      handleStateUpdate({ isAcceptModalOpen: false });
      authorizeAction(async (authorizationToken: string) => {
        handleStateUpdate({ isAcceptingRequest: true });
        try {
          const res = await acceptTradeRequest(
            {
              trade_request_id: tradeRequest.id,
              payment_method_id: paymentMethodId,
            },
            authorizationToken
          );
          handleStateUpdate({ isAcceptingRequest: false });
          router.push(
            `${tradeDetailsPath}/${res.data?.acceptTradeRequest.trades[0].id}`
          );
        } catch (e: any) {
          isApolloError(e) &&
            present({
              message: e.graphQLErrors[0].message,
              duration: 3000,
              color: 'danger',
              position: 'bottom',
            });
          handleStateUpdate({
            isAcceptingRequest: false,
            isAcceptModalOpen: false,
          });
        }
      });
    }
  };

  const onItemClick = () => {
    if (isOwnRequest) {
      handleStateUpdate({ isConfirmModalOpen: true });
      return;
    }
    router.push(
      `${acceptTradeRequestPath}?request_id=${tradeRequest.id}&source=${sourceCurrency}&destination=${destinationCurrency}`
    );
  };

  const onEditRequestClick = () => {
    handleStateUpdate({ isEditModalOpen: true });
  };

  const onEditRequestModalClose = (
    isUpdated: boolean,
    updates?: {
      amount: number;
      rate: number;
    }
  ) => {
    if (!isUpdated) {
      handleStateUpdate({ isEditModalOpen: false });
      return;
    }
    handleStateUpdate({ isEditModalOpen: false });
    authorizeAction(async (authorizationToken: string) => {
      handleStateUpdate({ isUpdatingRequest: true });
      try {
        await updateTradeRequest(
          { id: tradeRequest.id, ...updates },
          authorizationToken
        );
        handleStateUpdate({ isUpdatingRequest: false });
        refreshList && refreshList();
      } catch (e: any) {
        isApolloError(e) &&
          present({
            message: e.graphQLErrors[0].message,
            duration: 3000,
            color: 'danger',
            position: 'bottom',
          });
        handleStateUpdate({
          isUpdatingRequest: false,
          isConfirmModalOpen: false,
        });
      }
    });
  };

  return (
    <IonItem
      className={cx([
        'tradeRequest',
        { 'tradeRequest--inactive': !tradeRequest.is_active },
      ])}
    >
      <Avatar
        className='tradeRequest__sellerAvatar'
        slot='start'
        title={tradeRequest?.user.firstname}
      ></Avatar>
      <IonLabel>
        <div className='tradeRequest__header'>
          <div className='tradeRequest__seller'>
            <IonText className='tradeRequest__sellerName'>
              <p>{tradeRequest?.user.firstname}</p>
            </IonText>
            <IonText className='tradeRequest__sellerStats' color='medium'>
              <p>
                {tradeRequest.user.completed_trades?.total || 0} Completed Trade
                {tradeRequest.user.completed_trades?.total! !== 1 ? 's' : ''}
              </p>
            </IonText>
          </div>
          {tradeRequest.is_active ? (
            <>
              {isOwnRequest ? (
                <>
                  <IonButton
                    className='popoverBtn'
                    fill='clear'
                    disabled={state.isUpdatingRequest}
                    id={`edit-actions-${tradeRequest.id}`}
                  >
                    <KebabIcon />
                  </IonButton>
                  <IonPopover
                    trigger={`edit-actions-${tradeRequest.id}`}
                    dismissOnSelect={true}
                    showBackdrop={true}
                    className='tradeRequest__popover'
                  >
                    <IonItem
                      button={true}
                      detail={false}
                      lines='none'
                      onClick={onItemClick}
                    >
                      Close Request
                    </IonItem>
                    <IonItem
                      button={true}
                      detail={false}
                      lines='none'
                      onClick={onEditRequestClick}
                    >
                      Edit Request
                    </IonItem>
                    <IonItem
                      button={true}
                      detail={false}
                      lines='none'
                      routerLink={`${tradeRequestNegotiationsPath}/${tradeRequest.id}`}
                    >
                      Negotiations
                    </IonItem>
                  </IonPopover>
                </>
              ) : (
                <IonButton
                  disabled={state.isUpdatingRequest}
                  onClick={onItemClick}
                  fill='clear'
                  className='link'
                >
                  View Request
                </IonButton>
              )}
            </>
          ) : (
            <>
              <IonText>
                <span>Inactive</span>
              </IonText>
            </>
          )}
        </div>
        <div className='tradeRequest__content'>
          <div className='tradeRequest__info'>
            <IonText color='medium' className='tradeRequest__amount'>
              <p>
                <span>
                  {' '}
                  {currencyFormatter({
                    value: tradeRequest.amount,
                    currency: sourceCurrency,
                  })}
                </span>
                <br />
              </p>
            </IonText>
          </div>
          <IonText className='tradeRequest__price'>
            <p>
              <span>
                {currencyFormatter({
                  value: tradeRequest.rate,
                  currency: destinationCurrency,
                })}
              </span>
              <br />
              <span>per {sourceCurrency}</span>
            </p>
          </IonText>
        </div>
        <div className='tradeRequest__footer'>
          <div className='tradeRequest__payments'>
            {tradeRequest.trade_request_payment_sources
              .slice(0, 2)
              .map((paymentSource) => (
                <IonBadge
                  className='tradeRequest__paymentsBadge'
                  key={paymentSource.payment_source.id}
                >
                  {paymentSource.payment_source?.name.toUpperCase()}
                </IonBadge>
              ))}
            {tradeRequest.trade_request_payment_sources.length > 2 && (
              <>
                <IonButton
                  id='more-methods'
                  className='tradeRequest__paymentsTrigger'
                  fill='clear'
                >
                  <IonBadge className='tradeRequest__paymentsBadge'>{`+${
                    tradeRequest.trade_request_payment_sources.length - 2
                  }`}</IonBadge>
                </IonButton>
                <IonPopover
                  className='tradeRequest__paymentsPopover'
                  trigger='more-methods'
                  triggerAction='click'
                >
                  <div className='tradeRequest__payments tradeRequest__payments--more'>
                    {tradeRequest.trade_request_payment_sources
                      .slice(2)
                      .map((paymentSource) => (
                        <IonBadge
                          className='tradeRequest__paymentsBadge'
                          key={paymentSource.payment_source.id}
                        >
                          {paymentSource.payment_source.name.toUpperCase()}
                        </IonBadge>
                      ))}
                  </div>
                </IonPopover>
              </>
            )}
          </div>
          <IonText className='tradeRequest__currency'>
            <p>
              {destinationCurrency} {'>'} {sourceCurrency}
            </p>
          </IonText>
        </div>
      </IonLabel>
      <ConfirmModal
        isOpen={state.isConfirmModalOpen}
        title='Close Trade request?'
        subText='Are you sure you want to close this trade request?'
        onDismiss={onConfirmModalDismiss}
      />
      <AcceptTradeRequestModal
        isOpen={state.isAcceptModalOpen}
        tradeRequest={tradeRequest}
        onDismiss={onAcceptModalDismiss}
      />
      <EditTradeRequestModal
        isOpen={state.isEditModalOpen}
        tradeRequest={tradeRequest}
        onDismiss={onEditRequestModalClose}
      />
      <IonLoading
        isOpen={state.isUpdatingRequest || state.isAcceptingRequest}
        message={
          state.isUpdatingRequest
            ? 'Updating request...'
            : 'Accepting request...'
        }
      />
    </IonItem>
  );
};

export default TradeRequest;
