import { gql } from '@apollo/client';

export const START_TRADE = gql`
  mutation StartTrade($data: StartTradeInput!) {
    startTrade(startTradeInput: $data) {
      data {
        id
      }
    }
  }
`;

export const INSERT_MESSAGE = gql`
  mutation InsertMessage($data: message_insert_input!) {
    insert_message_one(object: $data) {
      body
      file_url
      file_type
    }
  }
`;

export const UPDATE_TRADE = gql`
  mutation UpdateTrade($id: String!, $status: TradeStatus) {
    updateTrade(updateTradeInput: { status: $status, trade_id: $id }) {
      data {
        id
      }
    }
  }
`;

export const START_NEGOTIATION = gql`
  mutation StartNegotiation($data: negotiation_insert_input!) {
    insert_negotiation_one(object: $data) {
      id
    }
  }
`;
