import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
  useIonToast,
} from '@ionic/react';
import { FC, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { LogoMini } from '../../../../assets/img';
import { loginPath, verifyEmailPath } from '../../../../data/pageRoutes';
import { useFormik } from 'formik';

import PasswordInput from '../../components/PasswordInput/PasswordInput';
import { registerValidationSchema } from '../../validators';
import cx from 'classnames';
import './Register.scss';
import { postRegister } from '../../api';
import AppContext from 'src/AppContext';
import { isApolloError } from '@apollo/client';
import { omit } from 'lodash';

const Register: FC = () => {
  const history = useHistory();
  const { setUser } = useContext(AppContext);
  const [present] = useIonToast();
  const queryParams = new URLSearchParams(history.location.search);
  const code = queryParams.get('referral_code');

  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    referral_code: code || '',
  };

  const onSubmit = async (values: typeof initialValues) => {
    try {
      const res = await postRegister(omit(values, 'referral_code'));
      setUser(res.data!.register.data);
      queryParams.delete('referral_code');
      history.push({
        pathname: verifyEmailPath,
        search: queryParams.toString(),
      });
    } catch (e: any) {
      isApolloError(e) &&
        present({
          message: e.graphQLErrors[0].message,
          duration: 3000,
          color: 'danger',
        });
    }
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    touched,
    errors,
    isSubmitting,
  } = useFormik({
    initialValues,
    onSubmit,
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: registerValidationSchema,
  });

  return (
    <IonPage className='register'>
      <IonContent className='ion-padding-horizontal ion-padding-top'>
        <LogoMini className='register__logo' />
        <div className='register__header'>
          <IonText color='primary'>
            <h2>Hey!</h2>
          </IonText>
          <IonText>
            <h2>Let's get to know you</h2>
          </IonText>
        </div>
        <form className='register__form' onSubmit={handleSubmit}>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonItem
                  className={cx({
                    'ion-invalid': Boolean(
                      touched.firstname && errors.firstname
                    ),
                  })}
                >
                  <IonLabel position='stacked'>First name</IonLabel>
                  <IonInput
                    legacy
                    name='firstname'
                    onIonBlur={handleBlur}
                    value={values.firstname}
                    onIonInput={handleChange}
                  ></IonInput>
                  <IonNote slot='error'>
                    {touched.firstname && errors.firstname
                      ? errors.firstname
                      : ''}
                  </IonNote>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem
                  className={cx({
                    'ion-invalid': Boolean(touched.lastname && errors.lastname),
                  })}
                >
                  <IonLabel position='stacked'>Last name</IonLabel>
                  <IonInput
                    legacy
                    name='lastname'
                    onIonBlur={handleBlur}
                    value={values.lastname}
                    onIonInput={handleChange}
                  ></IonInput>
                  <IonNote slot='error'>
                    {touched.lastname && errors.lastname ? errors.lastname : ''}
                  </IonNote>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem
                  className={cx({
                    'ion-invalid': Boolean(touched.email && errors.email),
                  })}
                >
                  <IonLabel position='stacked'>Email</IonLabel>
                  <IonInput
                    legacy
                    name='email'
                    value={values.email}
                    onIonBlur={handleBlur}
                    onIonInput={handleChange}
                  ></IonInput>
                  <IonNote slot='error'>
                    {touched.email && errors.email ? errors.email : ''}
                  </IonNote>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <PasswordInput
                  legacy
                  name='password'
                  label='Password'
                  onIonBlur={handleBlur}
                  value={values.password}
                  onIonInput={handleChange}
                  isValid={!Boolean(touched.password && errors.password)}
                  errorText={
                    touched.password && errors.password ? errors.password : ''
                  }
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position='stacked'>
                    Referral Code (Optional)
                  </IonLabel>
                  <IonInput
                    legacy
                    name='referral_code'
                    onIonBlur={handleBlur}
                    onIonInput={handleChange}
                    value={values.referral_code}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText class='register__terms'>
                  By creating an account, you agree to our{' '}
                  <a
                    href='https://safeswap.notion.site/Terms-of-Use-ad5013c0fd494e5083ff65c2c9b2840a'
                    target='__blank'
                  >
                    Terms of Use
                  </a>{' '}
                  and{' '}
                  <a
                    href='https://safeswap.notion.site/Privacy-Policy-5ea660bae58c4b0f812530526054f6ea'
                    target='__blank'
                  >
                    Privacy Policy
                  </a>
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonButton
            type='submit'
            expand='full'
            className='register__formSubmit'
            disabled={!isValid || isSubmitting}
          >
            Create Account
            {isSubmitting ? <IonSpinner name='crescent'></IonSpinner> : null}
          </IonButton>
          <IonText class='register__login'>
            Have an account already, &nbsp;&nbsp;
            <Link to={loginPath}>click here to to log in</Link>
          </IonText>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default Register;
