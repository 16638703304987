import { IonButton, useIonRouter } from '@ionic/react';
import { FC } from 'react';
import { ArrowLeft } from '../../assets/icons';

import './BackButton.scss';

interface BackButtonProps {
  handleClick?: () => void;
}

const BackButton: FC<BackButtonProps> = ({ handleClick }) => {
  const router = useIonRouter();

  const onBackClick = () => {
    if (handleClick) {
      return handleClick();
    }
    router.goBack();
  };

  return (
    <IonButton
      onClick={onBackClick}
      color='dark'
      className='backButton'
      fill='clear'
    >
      <ArrowLeft />
    </IonButton>
  );
};

export default BackButton;
