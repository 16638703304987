import { apiService } from 'src/helpers';
import {
  RDRegister,
  RSRegister,
  RSVerifyEmail,
  RDLogin,
  RSLogin,
  RSForgotPassword,
  RDForgotPassword,
  RDResetPassword,
  RSResetPassword,
} from './types';
import {
  REGISTER,
  VERIFY_EMAIL,
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from './mutations';

export const postRegister = (data: RDRegister) => {
  return apiService.mutate<RSRegister>({
    mutation: REGISTER,
    variables: {
      authRegisterInput: data,
    },
  });
};

export const postLogin = (data: RDLogin) => {
  return apiService.mutate<RSLogin>({
    mutation: LOGIN,
    variables: {
      authLoginInput: data,
    },
  });
};

export const postVerifyEmail = (code: string) => {
  return apiService.mutate<RSVerifyEmail>({
    mutation: VERIFY_EMAIL,
    variables: {
      authVerifyEmailInput: {
        code,
      },
    },
  });
};

export const postForgotPassword = (data: RDForgotPassword) => {
  return apiService.mutate<RSForgotPassword>({
    mutation: FORGOT_PASSWORD,
    variables: {
      authForgotPasswordInput: data,
    },
  });
};

export const postResetPassword = (data: RDResetPassword) => {
  return apiService.mutate<RSResetPassword>({
    mutation: RESET_PASSWORD,
    variables: {
      authResetPasswordInput: data,
    },
  });
};
