import { apiService } from 'src/helpers';
import {
  RDVerifyBvn,
  RSResendBvnEmail,
  RSSetPin,
  RSVerifyBvn,
  RSVerifyBvnOTP,
} from './types';
import {
  VERIFY_BVN,
  VERIFY_BVN_OTP,
  SET_PIN,
  SEND_BVN_OTP_TO_EMAIL,
} from './mutations';

export const postVerifyBvn = (data: RDVerifyBvn) => {
  return apiService.mutate<RSVerifyBvn>({
    mutation: VERIFY_BVN,
    variables: {
      authVerifyBvnInput: data,
    },
  });
};

export const postVerifyBvnOtp = (otp: string) => {
  return apiService.mutate<RSVerifyBvnOTP>({
    mutation: VERIFY_BVN_OTP,
    variables: {
      authConfirmBvnInput: {
        otp,
      },
    },
  });
};

export const postSetPin = (pin: string) => {
  return apiService.mutate<RSSetPin>({
    mutation: SET_PIN,
    variables: {
      authSetPinInput: {
        pin,
      },
    },
  });
};

export const sendBvnOtpToEmail = () => {
  return apiService.query<RSResendBvnEmail>({
    query: SEND_BVN_OTP_TO_EMAIL,
  });
};
