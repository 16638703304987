import { gql } from '@apollo/client';

export const UPDATE_AD = gql`
  mutation updateAd($id: uuid!, $ad: ad_set_input!) {
    update_ad_by_pk(pk_columns: { id: $id }, _set: $ad) {
      id
    }
  }
`;

export const UPDATE_TRADE_REQUEST = gql`
  mutation updateTradeRequest(
    $id: uuid!
    $trade_request: trade_request_set_input!
  ) {
    update_trade_request_by_pk(pk_columns: { id: $id }, _set: $trade_request) {
      id
    }
  }
`;

export const ACCEPT_TRADE_REQUEST = gql`
  mutation acceptTradeRequest($args: AcceptTradeRequestInput!) {
    acceptTradeRequest(args: $args) {
      id
      trades {
        id
      }
    }
  }
`;
