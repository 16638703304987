import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
  useIonToast,
} from '@ionic/react';
import { FC, useContext, useRef } from 'react';

import { tabsPath } from '../../../../data/pageRoutes';
import { useFormik } from 'formik';
import { isApolloError } from '@apollo/client';
import { postSetPin } from '../../api';
import { otpValidationSchema } from '../../validators';
import { OtpSelector } from 'src/types';

import './SetPin.scss';
import AppContext from 'src/AppContext';
import { useHistory, useLocation } from 'react-router';

const SetPin: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [present] = useIonToast();
  const { refreshProfile } = useContext(AppContext);
  const queryParams = new URLSearchParams(location.search);

  const initialValues = {
    char1: '',
    char2: '',
    char3: '',
    char4: '',
  };

  const inputRef = useRef<HTMLIonInputElement[]>([]);

  const onSubmit = async (values: typeof initialValues) => {
    const code = `${values.char1}${values.char2}${values.char3}${values.char4}`;
    try {
      await postSetPin(code);
      await refreshProfile();
      const redirectTo = queryParams.get('redirect');
      if (redirectTo) {
        queryParams.delete('redirect');
        history.push({ pathname: redirectTo, search: queryParams.toString() });
        return;
      }
      history.push(tabsPath);
    } catch (e: any) {
      isApolloError(e) &&
        present({
          message: e.graphQLErrors[0].message,
          duration: 3000,
          color: 'danger',
        });
    }
  };

  const {
    values,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues,
    onSubmit,
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: otpValidationSchema,
  });

  const handleOnKeyUp = (
    e: React.KeyboardEvent<HTMLIonInputElement>,
    index: number
  ) => {
    setTimeout(() => {
      if (e.code === 'Backspace' && index === 1) {
        return;
      }

      if (e.code === 'Backspace' && index !== 1) {
        inputRef.current[index - 2].setFocus();
        return;
      }
      if (index !== 4) {
        inputRef.current[index].setFocus();
      }
    }, 50);
  };

  const handlePaste = (e: any) => {
    e.preventDefault();
    const paste = e.clipboardData.getData('text/plain');
    const pasteArray = paste.split('');
    const pasteLength = pasteArray.length;
    if (pasteLength !== 4) return;
    for (let i = 0; i < pasteLength; i++) {
      setFieldValue(`char${i + 1}`, pasteArray[i]);
      if (i === 3) {
        inputRef.current[i].setFocus();
      }
    }
  };

  return (
    <IonPage className='pin'>
      <IonContent className='ion-padding-horizontal ion-padding-top'>
        <div className='pin__header'>
          <IonText color='primary'>
            <h2>Secure your Account</h2>
          </IonText>
          <IonText>
            <p>Set up your PIN to secure your transactions</p>
          </IonText>
        </div>
        <form className='pin__form' onSubmit={handleSubmit}>
          <IonGrid>
            <IonRow className='ion-justify-content-center'>
              {Array.from({ length: 4 }).map((_, index) => {
                const pos = index + 1;
                const selector = `char${pos}` as OtpSelector;
                return (
                  <IonCol key={selector}>
                    <IonItem>
                      <IonInput
                        max={9}
                        id={selector}
                        name={selector}
                        legacy={true}
                        maxlength={1}
                        placeholder='●'
                        inputMode='numeric'
                        value={values[selector]}
                        onPaste={handlePaste}
                        onIonBlur={handleBlur}
                        onIonInput={handleChange}
                        onIonChange={handleChange}
                        onKeyUp={(e) => handleOnKeyUp(e, pos)}
                        ref={(el: HTMLIonInputElement) =>
                          (inputRef.current[index] = el)
                        }
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                );
              })}
            </IonRow>
          </IonGrid>
          <IonButton
            type='submit'
            expand='full'
            disabled={!isValid || isSubmitting}
          >
            Set PIN
            {isSubmitting ? <IonSpinner name='crescent'></IonSpinner> : null}
          </IonButton>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default SetPin;
