import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import './AcceptTradeRequest.scss';
import {
  IonBadge,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSkeletonText,
  IonSpinner,
  IonText,
  useIonRouter,
  useIonToast,
} from '@ionic/react';
import { useLocation } from 'react-router';
import { ChatIcon, CloseIcon, CopyIcon } from '../../../../assets/icons';
import Avatar from '../../../../components/Avatar/Avatar';
import { fetchTradeRequest, startNegotiation } from '../../api';
import { currencyFormatter, koboToNaira } from 'src/helpers';
import { useFormik } from 'formik';
import { isApolloError } from '@apollo/client';
import { tradeDetailsPath, tradeRequestChatPath } from 'src/data/pageRoutes';
import { PaymentMethod, TradeRequest } from 'src/types';
import AppContext from 'src/AppContext';
import { acceptTradeRequest } from 'src/modules/tabs/api';
import { fetchPaymentMethods } from 'src/modules/ads/api';
import { object, string } from 'yup';
import { Clipboard } from '@capacitor/clipboard';
import AddPaymentMethod from 'src/modules/ads/modals/AddPaymentMethod';

interface AcceptTradeRequestState {
  tradeRequest: TradeRequest | null;
  paymentMethods: PaymentMethod[];
  isFetchingRequest: boolean;
  isAcceptingRequest: boolean;
  isFetchingFee: boolean;
  isFetchingPaymentMethods: boolean;
  isStartingNegotiation: boolean;
  isAddPaymentMethodModalOpen: boolean;
}

const AcceptTradeRequest: FC = () => {
  const router = useIonRouter();
  const [present] = useIonToast();

  const { authorizeAction, profile } = useContext(AppContext);

  const location = useLocation().search;
  const tradeRequestId = new URLSearchParams(location).get(
    'request_id'
  ) as string;
  const sourceCurrency = new URLSearchParams(location).get('source') as string;
  const destinationCurrency = new URLSearchParams(location).get(
    'destination'
  ) as string;

  const [state, setState] = useState<AcceptTradeRequestState>({
    tradeRequest: null,
    paymentMethods: [],
    isFetchingRequest: true,
    isAcceptingRequest: false,
    isFetchingFee: false,
    isFetchingPaymentMethods: false,
    isStartingNegotiation: false,
    isAddPaymentMethodModalOpen: false,
  });

  const handleStateUpdate = (newState: Partial<AcceptTradeRequestState>) =>
    setState((_state) => ({ ..._state, ...newState }));

  const initialValues = {
    payment_method_id: '',
  };

  const onSubmit = async () => {
    authorizeAction(async (authorizationToken: string) => {
      handleStateUpdate({ isAcceptingRequest: true });
      const data = {
        ...values,
        trade_request_id: tradeRequestId,
      };
      try {
        const res = await acceptTradeRequest(data, authorizationToken);
        present({
          message: 'Trade created successfully',
          duration: 3000,
          color: 'success',
          position: 'bottom',
        });
        handleStateUpdate({ isAcceptingRequest: false });
        router.push(
          `${tradeDetailsPath}/${res.data?.acceptTradeRequest.trades[0].id}`
        );
      } catch (e: any) {
        isApolloError(e) &&
          present({
            message: e.graphQLErrors[0].message,
            duration: 3000,
            color: 'danger',
            position: 'bottom',
          });
        handleStateUpdate({ isAcceptingRequest: false });
      }
    });
  };

  const handleAddPaymentMethodDismiss = (shouldRefresh?: boolean) => {
    if (shouldRefresh) {
      getPaymentMethods();
    }
    handleStateUpdate({ isAddPaymentMethodModalOpen: false });
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    isSubmitting,
  } = useFormik<typeof initialValues>({
    initialValues,
    onSubmit,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: object({
      payment_method_id: string().required('Please select a payment method'),
    }),
  });

  const getTradeRequest = useCallback(async () => {
    handleStateUpdate({ isFetchingRequest: true });
    try {
      const res = await fetchTradeRequest({
        id: tradeRequestId,
        userId: profile?.id!,
      });
      handleStateUpdate({
        tradeRequest: res.data.trade_request_by_pk,
        isFetchingRequest: false,
      });
    } catch (e) {
      handleStateUpdate({
        tradeRequest: null,
        isFetchingRequest: false,
      });
    }
  }, [profile?.id, tradeRequestId]);

  const onBackClick = () => {
    router.goBack();
  };

  const onCopyClick = async (value: string) => {
    await Clipboard.write({
      string: value,
    });
    present({
      message: 'Copied!',
      duration: 2000,
      position: 'top',
      color: 'success',
    });
  };

  const getPaymentMethods = useCallback(async () => {
    try {
      handleStateUpdate({
        isFetchingPaymentMethods: true,
      });
      const res = await fetchPaymentMethods(profile?.id!);
      handleStateUpdate({
        paymentMethods: res.data.payment_method,
        isFetchingPaymentMethods: false,
      });
    } catch (e) {
      handleStateUpdate({ isFetchingPaymentMethods: false });
    }
  }, [profile?.id]);

  useEffect(() => {
    getTradeRequest();
    getPaymentMethods();
  }, [getTradeRequest, getPaymentMethods]);

  const { tradeRequest, isFetchingRequest } = state;

  const acceptablePaymentMethods = useMemo(() => {
    if (!state.tradeRequest) {
      return [];
    }

    if (state.paymentMethods.length === 0) {
      return [];
    }

    return state.paymentMethods
      .filter((method) =>
        method.payment_source?.supported_currencies.includes(
          state.tradeRequest?.source_currency!
        )
      )
      .filter((method) =>
        state.tradeRequest?.trade_request_payment_sources.find(
          (source) => source.payment_source.id === method.payment_source?.id
        )
      );
  }, [state.paymentMethods, state.tradeRequest]);

  const amountDifference = useMemo(() => {
    if (!tradeRequest) {
      return 0;
    }

    const tradeAmount = tradeRequest.amount * tradeRequest.rate;

    return tradeAmount - koboToNaira(profile?.wallets[0].balance.amount!);
  }, [tradeRequest, profile]);

  const onChatClick = async () => {
    if (!tradeRequest) {
      return;
    }
    if (!tradeRequest.negotiations.length) {
      handleStateUpdate({ isStartingNegotiation: true });
      startNegotiation({
        tradeRequestId: tradeRequest.id,
        userId: profile?.id!,
      })
        .then((res) => {
          handleStateUpdate({ isStartingNegotiation: false });
          router.push(
            `${tradeRequestChatPath}/${res.data?.insert_negotiation_one.id}`
          );
        })
        .catch((e) => {
          isApolloError(e) &&
            present({
              message: e.graphQLErrors[0].message,
              duration: 3000,
              color: 'danger',
              position: 'bottom',
            });
        });
      return;
    }
    router.push(`${tradeRequestChatPath}/${tradeRequest.negotiations[0].id}`);
  };

  return (
    <IonPage className='acceptTrade'>
      <IonHeader className='acceptTrade__header' translucent={true}>
        <div className='acceptTrade__headerBg'></div>
        <div className='acceptTrade__headerContent'>
          <IonButton
            fill='clear'
            color={'dark'}
            onClick={onBackClick}
            routerDirection='back'
            className='acceptTrade__headerContentButton'
          >
            <CloseIcon />
          </IonButton>
          <IonText color={'dark'} className='acceptTrade__headerContentTitle'>
            <h1>
              Swap {destinationCurrency} {'>'} {sourceCurrency}
            </h1>
          </IonText>
        </div>
      </IonHeader>
      <IonContent fullscreen={true} className='acceptTrade__content'>
        {isFetchingRequest || !tradeRequest ? (
          <>
            <IonItem className='acceptTrade__skeleton' lines='none'>
              <IonSkeletonText
                className='acceptTrade__skeletonAvatar'
                slot='start'
                animated={true}
              ></IonSkeletonText>
              <IonLabel>
                <IonSkeletonText animated={true}></IonSkeletonText>
              </IonLabel>
            </IonItem>

            <IonItem className='acceptTrade__skeleton--last' lines='none'>
              <IonLabel>
                <IonSkeletonText animated={true}></IonSkeletonText>
                <IonSkeletonText animated={true}></IonSkeletonText>
              </IonLabel>
            </IonItem>
          </>
        ) : (
          <>
            <div className='acceptTrade__merchant'>
              <Avatar
                title={tradeRequest?.user.firstname}
                className='acceptTrade__merchantAvatar'
              />
              <div className='acceptTrade__merchantInfo'>
                <IonText color='dark' className='acceptTrade__merchantName'>
                  <p>{tradeRequest?.user?.firstname}</p>
                </IonText>
                {/* <IonText color='medium' className='acceptTrade__merchantStats'>
              <p>489 Trades | 80.9% Completion Rate</p>
            </IonText> */}
              </div>
            </div>
            <div className='acceptTrade__info'>
              <IonText className='acceptTrade__amount' color='medium'>
                <p>
                  Amount &nbsp;{' '}
                  <span>
                    {' '}
                    {currencyFormatter({
                      value: tradeRequest.amount as number,
                      style: 'currency',
                      currency: tradeRequest?.source_currency,
                    })}{' '}
                  </span>
                </p>
              </IonText>
              <IonText className='acceptTrade__rate' color='dark'>
                <p>
                  {currencyFormatter({
                    value: tradeRequest?.rate as number,
                    style: 'currency',
                    currency: tradeRequest?.destination_currency,
                  })}{' '}
                  <span>per {tradeRequest?.source_currency}</span>
                </p>
              </IonText>
            </div>
          </>
        )}

        <form className='acceptTrade__form' onSubmit={handleSubmit}>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel
                    position='stacked'
                    className='acceptTrade__paymentMethodLabel'
                  >
                    <span>Payment Method</span>
                    <IonButton
                      onClick={() =>
                        handleStateUpdate({ isAddPaymentMethodModalOpen: true })
                      }
                      fill='clear'
                      color={'primary'}
                      className='link'
                    >
                      Add Payment Method
                    </IonButton>
                  </IonLabel>
                  <IonSelect
                    placeholder='Select Payment Methods'
                    onIonChange={handleChange}
                    name='payment_method_id'
                    onIonBlur={handleBlur}
                    value={values.payment_method_id}
                    disabled={isFetchingRequest}
                  >
                    {acceptablePaymentMethods.map((paymentMethod) => {
                      return (
                        <IonSelectOption
                          value={paymentMethod.id}
                          key={paymentMethod.id}
                        >
                          {paymentMethod.payment_source?.name} (
                          {paymentMethod.data[paymentMethod.primary_field]})
                        </IonSelectOption>
                      );
                    })}
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText color='medium'>
                  <h5 className='acceptTrade__paymentsTitle'>
                    Acceptable Payment Methods
                  </h5>
                </IonText>
                <div className='acceptTrade__payments'>
                  {state.tradeRequest?.trade_request_payment_sources.map(
                    (paymentSources) => {
                      return (
                        <IonBadge
                          className='acceptTrade__paymentsBadge'
                          key={paymentSources.payment_source.id}
                        >
                          {paymentSources.payment_source?.name}
                        </IonBadge>
                      );
                    }
                  )}
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
          {amountDifference > 0 && (
            <>
              <IonText color='medium' className='acceptTrade__insufficient'>
                <p>
                  You need an additional{' '}
                  {currencyFormatter({
                    value: amountDifference,
                    style: 'currency',
                    currency: 'NGN',
                  })}
                </p>
              </IonText>
              <IonText color='medium' className='acceptTrade__wallet'>
                <p>Please fund your wallet with the details below</p>
                <p>
                  Account Name:
                  <span>{profile?.wallets[0].virtual_accounts[0].name}</span>
                </p>
                <p>
                  Bank Name:{' '}
                  <span>{profile?.wallets[0].virtual_accounts[0].bank}</span>
                </p>
                <p>
                  Account Number:{' '}
                  <span>
                    {profile?.wallets[0].virtual_accounts[0].number}
                    <IonButton
                      color={'primary'}
                      onClick={() =>
                        onCopyClick(
                          profile?.wallets[0].virtual_accounts[0].number!
                        )
                      }
                      fill='clear'
                    >
                      <CopyIcon />
                    </IonButton>
                  </span>
                </p>
              </IonText>
            </>
          )}
          <IonText></IonText>
          <IonButton
            type='submit'
            expand='full'
            className='acceptTrade__submit'
            disabled={!isValid || isSubmitting || state.isAcceptingRequest}
          >
            <span className='acceptTrade__submitText'>
              Swap{' '}
              {isSubmitting || state.isAcceptingRequest ? (
                <IonSpinner name='crescent'></IonSpinner>
              ) : null}
            </span>
          </IonButton>
        </form>
        <IonButton
          fill='clear'
          onClick={onChatClick}
          color='primary'
          className='acceptTrade__chat link'
        >
          <span className='acceptTrade__chatContent'>
            <ChatIcon />
            Chat with {tradeRequest?.user.firstname}
            {state.isStartingNegotiation && <IonSpinner name='crescent' />}
          </span>
        </IonButton>
      </IonContent>
      <AddPaymentMethod
        isOpen={state.isAddPaymentMethodModalOpen}
        onDismiss={handleAddPaymentMethodDismiss}
        visiblePaymentSources={state.tradeRequest?.trade_request_payment_sources.map(
          (source) => source.payment_source!.id
        )}
      />
    </IonPage>
  );
};

export default AcceptTradeRequest;
