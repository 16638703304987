interface FileUploaderOptions {
  upload_preset: string;
  tags?: string[];
  folder?: string;
  public_id?: string;
}

interface FileUploaderResponse {
  asset_id: string;
  public_id: string;
  version: number;
  version_id: string;
  signature: string;
  width: number;
  height: number;
  format: string;
  resource_type: string;
  created_at: Date;
  tags: string[];
  bytes: number;
  type: string;
  etag: string;
  placeholder: boolean;
  url: string;
  secure_url: string;
  access_mode: string;
  original_filename: string;
  pages: number;
  secure_url_pdf: string;
  url_pdf: string;
  folder: string;
  existing: boolean;
}

/** upload to cloudinary **/
const fileUploader = async (
  file: File,
  options: FileUploaderOptions
): Promise<FileUploaderResponse | any> => {
  const url = 'https://api.cloudinary.com/v1_1/ddrgxotoz/upload';
  const fd = new FormData();
  fd.append('file', file);
  Object.keys(options).forEach((key) => {
    const value = options[key as keyof FileUploaderOptions];
    if (value) {
      fd.append(key, value as string);
    }
  });
  try {
    const res = await fetch(url, {
      method: 'POST',
      body: fd,
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export default fileUploader;
