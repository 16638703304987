import { Drivers, Storage } from '@ionic/storage';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

class AppStorage {
  private storage: Promise<Storage>;

  constructor() {
    this.storage = new Storage({
      name: 'safeswap',
      driverOrder: [
        CordovaSQLiteDriver._driver,
        Drivers.IndexedDB,
        Drivers.LocalStorage,
      ],
    }).create();
  }

  getItem = async (key: string) => {
    return (await this.storage).get(key);
  };

  setItem = async (key: string, value: any) => {
    return (await this.storage).set(key, value);
  };

  removeItem = async (key: string) => {
    return (await this.storage).remove(key);
  };
}

const appStorage = new AppStorage();

export default appStorage;
