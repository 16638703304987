import {
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonSkeletonText,
} from '@ionic/react';
import { FC, useCallback, useContext, useEffect, useState } from 'react';

import './RecentAds.scss';
import { useHistory } from 'react-router';
import { adsTabPath } from 'src/data/pageRoutes';
import TradeAd from '../TradeAd';
import { fetchAds } from '../../api';
import AppContext from 'src/AppContext';
import { Ad } from 'src/types';

interface RecentAdsState {
  ads: Ad[] | null;
  isFetchingAds: boolean;
}

const RecentAds: FC = () => {
  const history = useHistory();
  const { profile } = useContext(AppContext);

  const [state, setState] = useState<RecentAdsState>({
    ads: null,
    isFetchingAds: true,
  });

  const handleStateUpdate = (newState: Partial<RecentAdsState>) =>
    setState({ ...state, ...newState });

  const getAds = useCallback(async () => {
    handleStateUpdate({ isFetchingAds: true });
    try {
      const res = await fetchAds({
        limit: 3,
        order_by: {
          created_at: 'desc',
        },
        where: { user_id: { _neq: profile?.id }, is_active: { _eq: true } },
      });
      handleStateUpdate({
        ads: res.data.ad,
        isFetchingAds: false,
      });
    } catch (e) {
      handleStateUpdate({
        ads: [],
        isFetchingAds: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAds();
  }, [getAds]);

  if (state.isFetchingAds) {
    return (
      <IonList lines='none' className='recentListings recentListings--loading'>
        <IonItem className='recentListings__skeleton'>
          <IonSkeletonText animated />
        </IonItem>
        <IonItem className='recentListings__skeleton'>
          <IonSkeletonText animated />
        </IonItem>
        <IonItem className='recentListings__skeleton'>
          <IonSkeletonText animated />
        </IonItem>
      </IonList>
    );
  }

  if (!state.ads || !state.ads.length) {
    return <></>;
  }

  return (
    <IonList lines='full' className='recentListings'>
      <IonListHeader className='recentListings__header'>
        <IonLabel>Latest Ads</IonLabel>
        <IonButton onClick={() => history.push(adsTabPath)} className='link'>
          See More
        </IonButton>
      </IonListHeader>
      {state.ads.map((ad) => (
        <TradeAd ad={ad} key={ad.id} />
      ))}
    </IonList>
  );
};

export default RecentAds;
