import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Camera, CameraResultType, Photo } from '@capacitor/camera';
import { Filesystem } from '@capacitor/filesystem';

import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonImg,
  IonInput,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonSkeletonText,
  IonSpinner,
  IonText,
  isPlatform,
  useIonRouter,
  useIonToast,
} from '@ionic/react';
import cx from 'classnames';

import Avatar from 'src/components/Avatar/Avatar';

import { RouteComponentProps } from 'react-router';

import { ArrowRight, CloseIcon, PlusIcon } from 'src/assets/icons';
import './TradeRequestChat.scss';
import {
  fetchNegotiation,
  insertMessage,
  subscribeToNegotiationMessages,
} from '../../api';
import AppContext from 'src/AppContext';
import { ChatMessage } from 'src/types';
import { currencyFormatter } from 'src/helpers';
import { useFormik } from 'formik';
import { isApolloError } from '@apollo/client';
import { cloneDeep } from 'lodash';
import { format } from 'date-fns';

import fileUploader from 'src/helpers/fileUploader';
import { Capacitor } from '@capacitor/core';
import { chatValidationSchema } from '../../validators';
import { Negotiation } from '../../types';
import { acceptTradeRequestPath } from 'src/data/pageRoutes';
import EditTradeRequestModal from 'src/modules/tabs/modals/EditTradeRequestModal';
import { updateTradeRequest } from 'src/modules/tabs/api';
import If from 'src/components/If';

interface FetchChatState {
  chat: Negotiation | null;
  isFetchingChat: boolean;
  chatSubscription: any;
  selectedImage: {
    url: string;
    type: string;
  };
  isEditModalOpen: boolean;
  isUpdatingRequest: boolean;
}

interface ChatFormValues {
  body: string;
  file: Photo | null;
  negotiation_id: string;
}

type TradeRequestChatProps = RouteComponentProps<{
  id: string;
}>;

const TradeRequestChat: FC<TradeRequestChatProps> = ({ match }) => {
  const negotiation_id = match.params.id;
  const { user, authorizeAction } = useContext(AppContext);
  const [present] = useIonToast();
  const router = useIonRouter();
  const isHybrid = isPlatform('hybrid');

  const [state, setState] = useState<FetchChatState>({
    chat: null,
    isFetchingChat: true,
    chatSubscription: null,
    selectedImage: {
      url: '',
      type: '',
    },
    isEditModalOpen: false,
    isUpdatingRequest: false,
  });

  const handleStateUpdate = (newState: Partial<FetchChatState>) => {
    setState((_state) => ({ ..._state, ...newState }));
  };

  const handleMessageUpdate = (newMessage: ChatMessage) => {
    setState((_state) => {
      if (_state.chat) {
        return {
          ..._state,
          chat: {
            ..._state.chat,
            messages: [..._state.chat?.messages, newMessage],
          },
        };
      }
      return _state;
    });
  };

  const scrollContentRef = useRef<HTMLIonContentElement>(null);

  const scrollToBottom = () => {
    setTimeout(() => {
      scrollContentRef.current?.scrollToBottom(300);
    }, 100);
  };

  const initialValues: ChatFormValues = {
    file: null,
    body: '',
    negotiation_id,
  };

  const uploadImage = async (photo: Photo) => {
    let file = null;
    const imageType = photo.format;
    if (isHybrid) {
      const fileRes = await Filesystem.readFile({
        path: photo.path!,
      });
      const fileBase64 = `data:image/${imageType};base64,${fileRes.data}`;
      const blob = new Blob([fileBase64], { type: imageType });
      file = new File([blob], `${user?.firstname}-${firstName}${Date.now()}`, {
        type: imageType,
      });
    } else {
      const fileRes = await fetch(photo.webPath!);
      const blob = await fileRes.blob();
      file = new File([blob], `${user?.firstname}-${firstName}${Date.now()}`, {
        type: imageType,
      });
    }
    const uploadRes = await fileUploader(file, {
      upload_preset: 'chatUploads',
      public_id: `${user?.firstname}-${firstName}${Date.now()}`,
    });
    return uploadRes.secure_url;
  };

  const onSubmit = async () => {
    const data = {
      body: values.body,
      negotiation_id: negotiation_id,
      file_type: values.file?.format!,
      file_url: '',
    };

    if (values.file) {
      data.file_url = await uploadImage(values.file);
    }

    try {
      await insertMessage(data);
      resetForm();
    } catch (e: any) {
      if (isApolloError(e)) {
        present({
          message: e.graphQLErrors[0].message,
          duration: 3000,
          color: 'danger',
          position: 'bottom',
        });
        return;
      }
      present({
        message: 'An error occurred',
        duration: 3000,
        color: 'danger',
        position: 'bottom',
      });
    }
  };

  const {
    values,
    isValid,
    resetForm,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormik<ChatFormValues>({
    onSubmit,
    initialValues,
    validateOnMount: true,
    validationSchema: chatValidationSchema,
  });

  const isTradeRequestOwner = user?.id !== state.chat?.user.id;

  const firstName = !isTradeRequestOwner
    ? state.chat?.trade_request.user.firstname
    : state.chat?.user.firstname;

  const takePicture = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 100,
        allowEditing: false,
        resultType: CameraResultType.Uri,
      });
      setFieldValue('file', image);
    } catch (e) {
      console.log(e);
    }
  };

  const onActionClick = () => {
    if (isTradeRequestOwner) {
      handleStateUpdate({ isEditModalOpen: true });
      return;
    }
    router.push(
      `${acceptTradeRequestPath}?request_id=${state.chat?.trade_request.id}&source=${state.chat?.trade_request.source_currency}&destination=${state.chat?.trade_request.destination_currency}`
    );
  };

  const onEditRequestModalClose = (
    isUpdated: boolean,
    updates?: {
      amount: number;
      rate: number;
    }
  ) => {
    if (!isUpdated) {
      handleStateUpdate({ isEditModalOpen: false });
      return;
    }
    authorizeAction(async (authorizationToken: string) => {
      handleStateUpdate({ isUpdatingRequest: true });
      try {
        await updateTradeRequest(
          { id: state.chat?.trade_request_id!, ...updates },
          authorizationToken
        );
        handleStateUpdate({ isUpdatingRequest: false, isEditModalOpen: false });
        getChat();
      } catch (e: any) {
        isApolloError(e) &&
          present({
            message: e.graphQLErrors[0].message,
            duration: 3000,
            color: 'danger',
            position: 'bottom',
          });
        handleStateUpdate({
          isUpdatingRequest: false,
          isEditModalOpen: false,
        });
      }
    });
  };

  const getChat = useCallback(async () => {
    handleStateUpdate({ isFetchingChat: true });
    try {
      const res = await fetchNegotiation(negotiation_id as string);
      handleStateUpdate({
        chat: res.data.negotiation_by_pk,
        isFetchingChat: false,
      });
      scrollToBottom();
    } catch (e) {
      handleStateUpdate({
        chat: null,
        isFetchingChat: false,
      });
    }
  }, [negotiation_id]);

  useEffect(() => {
    const subscribeToNewMessages = () => {
      const subscription = subscribeToNegotiationMessages(
        negotiation_id as string,
        new Date().toISOString()
      ).subscribe(
        (res) => {
          if (!res.data?.message[0]) return;
          const newMessage = cloneDeep(res.data.message[0]);
          handleMessageUpdate(newMessage);
          scrollToBottom();
        },
        (e) => {
          console.log(e);
        }
      );
      handleStateUpdate({ chatSubscription: subscription });
    };

    getChat();
    subscribeToNewMessages();
  }, [negotiation_id, getChat]);

  useEffect(() => {
    return () => {
      state.chatSubscription?.unsubscribe();
    };
  }, [state.chatSubscription]);

  return (
    <IonPage className='tradeRequestChat'>
      <IonHeader className='tradeRequestChat__header' translucent={true}>
        <div className='tradeRequestChat__headerBg'></div>
        <div className='tradeRequestChat__headerContent'>
          <div className='tradeRequestChat__headerTop'>
            <IonButton
              fill='clear'
              routerDirection='back'
              onClick={() => router.goBack()}
              className='tradeRequestChat__headerBtn tradeRequestChat__headerBtn--close'
            >
              <CloseIcon />
            </IonButton>
            {/* <IonButton
              className='tradeRequestChat__headerBtn tradeRequestChat__headerBtn--menu'
              fill='clear'
            >
              <KebabIcon />
            </IonButton> */}
          </div>
          {!state.isFetchingChat ? (
            <>
              <div className='tradeRequestChat__headerRow'>
                <div className='tradeRequestChat__peer'>
                  <Avatar
                    title={firstName}
                    className='tradeRequestChat__peerAvatar'
                  />
                  <IonText color={'dark'}>
                    <p className='tradeRequestChat__peerName'>{firstName}</p>
                  </IonText>
                </div>
                <IonButton
                  fill='clear'
                  onClick={onActionClick}
                  className='link tradeRequestChat__action'
                >
                  {isTradeRequestOwner ? 'Edit' : 'Accept'} Request
                </IonButton>
                {/* <IonText>
                  <p className='tradeRequestChat__tradeStatus'>
                    {formatTradeStatus(state.chat?.trade.status!)}
                  </p>
                </IonText> */}
              </div>
              <div className='tradeRequestChat__headerRow'>
                <div className='tradeRequestChat__tradeDetails'>
                  <span>
                    <IonText color='medium'>
                      <p>Price &nbsp;</p>
                    </IonText>
                    <IonText color='medium'>
                      <p>
                        {' '}
                        - &nbsp;
                        {currencyFormatter({
                          value: state.chat?.trade_request.rate as number,
                          currency:
                            state.chat?.trade_request.destination_currency,
                        })}
                      </p>
                    </IonText>
                  </span>
                  <span>
                    <IonText color='medium'>
                      <p>Amount &nbsp;</p>
                    </IonText>
                    <IonText color='medium'>
                      <p>
                        {' '}
                        - &nbsp;
                        {currencyFormatter({
                          value: state.chat?.trade_request.amount as number,
                          currency: state.chat?.trade_request.source_currency,
                        })}
                      </p>
                    </IonText>
                  </span>
                </div>
                <IonText color='dark'>
                  <p className='tradeRequestChat__tradeAmount'>
                    {currencyFormatter({
                      value:
                        state.chat?.trade_request.rate! *
                        state.chat?.trade_request.amount!,
                      currency: state.chat?.trade_request.destination_currency,
                    })}
                  </p>
                </IonText>
              </div>
            </>
          ) : (
            <>
              <div className='tradeRequestChat__skeleton'>
                <IonSkeletonText
                  className='tradeRequestChat__skeletonAvatar'
                  slot='start'
                  animated={true}
                ></IonSkeletonText>
                <IonLabel>
                  <IonSkeletonText animated={true}></IonSkeletonText>
                </IonLabel>
              </div>

              <div className='tradeRequestChat__skeleton--last'>
                <IonLabel>
                  <IonSkeletonText animated={true}></IonSkeletonText>
                </IonLabel>
              </div>
            </>
          )}
        </div>
      </IonHeader>

      {!state.isFetchingChat && (
        <IonContent
          ref={scrollContentRef}
          fullscreen={true}
          className='tradeRequestChat__content ion-padding-horizontal ion-padding-top'
        >
          <div className='tradeRequestChat__messages'>
            {state.chat?.messages.map((message) => (
              <div
                key={message.id}
                className={cx('tradeRequestChat__message', {
                  'tradeRequestChat__message--mine':
                    user?.id === message.user.id,
                })}
              >
                <div className='tradeRequestChat__messageContent'>
                  {message.file_type && (
                    <IonImg
                      className='tradeRequestChat__messageContentImage'
                      role='button'
                      onClick={() =>
                        handleStateUpdate({
                          selectedImage: {
                            url: message.file_url,
                            type: message.file_type,
                          },
                        })
                      }
                      src={message.file_url}
                      alt={message.file_type}
                    />
                  )}
                  <p className='tradeRequestChat__messageText'>
                    {message.body}
                  </p>
                  <IonText
                    className='tradeRequestChat__messageTime'
                    color='secondary'
                  >
                    <p>{format(new Date(message.created_at), 'hh:mm a')}</p>
                  </IonText>
                </div>
                <span className='tradeRequestChat__messagePin'></span>
              </div>
            ))}
          </div>
        </IonContent>
      )}

      <IonFooter className='tradeRequestChat__footer'>
        <form className='tradeRequestChat__newMessage' onSubmit={handleSubmit}>
          <IonButton
            className='tradeRequestChat__newMessageAttachment'
            color={'tertiary'}
            fill='clear'
            onClick={takePicture}
          >
            <PlusIcon />
          </IonButton>
          <IonInput
            className='tradeRequestChat__newMessageInput'
            placeholder='Type a message...'
            name='body'
            value={values.body}
            onIonInput={handleChange}
          />
          {values.file && (
            <div className='tradeRequestChat__newMessageImageWrapper'>
              <IonImg
                className='tradeRequestChat__newMessageImage'
                src={
                  isHybrid
                    ? Capacitor.convertFileSrc(values.file.path!)
                    : values.file.webPath!
                }
                alt={values.file.format}
              ></IonImg>
              <IonButton
                onClick={() => {
                  setFieldValue('file', null);
                }}
                className='tradeRequestChat__newMessageClose'
              >
                <CloseIcon width={12} />
              </IonButton>
            </div>
          )}

          <IonButton
            className='tradeRequestChat__newMessageSend'
            fill='solid'
            type='submit'
            color={'primary'}
            disabled={!isValid || isSubmitting}
          >
            {isSubmitting ? (
              <IonSpinner name='crescent'></IonSpinner>
            ) : (
              <ArrowRight />
            )}
          </IonButton>
        </form>
      </IonFooter>
      <IonModal
        isOpen={Boolean(state.selectedImage.type)}
        onDidDismiss={() =>
          handleStateUpdate({ selectedImage: { url: '', type: '' } })
        }
        className='tradeRequestChat__fullScreenModal'
      >
        <IonButtons
          slot='end'
          className='tradeRequestChat__fullScreenModal--btn'
        >
          <IonButton
            fill='clear'
            slot='end'
            onClick={() =>
              handleStateUpdate({ selectedImage: { url: '', type: '' } })
            }
            className='tradeRequestChat__fullScreenModal--close'
          >
            <CloseIcon />
          </IonButton>
        </IonButtons>

        <IonImg
          className='tradeRequestChat__fullScreenModal--image'
          src={state.selectedImage.url}
          alt={state.selectedImage.type}
        />
      </IonModal>
      <If condition={Boolean(state.chat?.trade_request)}>
        <EditTradeRequestModal
          isOpen={state.isEditModalOpen}
          tradeRequest={state.chat?.trade_request!}
          onDismiss={onEditRequestModalClose}
        />
      </If>
      <IonLoading
        isOpen={state.isUpdatingRequest}
        message={'Updating request...'}
      />
    </IonPage>
  );
};

export default TradeRequestChat;
