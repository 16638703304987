import { IonRouterOutlet } from '@ionic/react';
import { FC } from 'react';
import { Redirect, Route } from 'react-router';
import ForgotPassword from './pages/ForgotPassword';

import Login from './pages/Login';
import Register from './pages/Register';
import ResetPassword from './pages/ResetPassword';
import {
  authPath,
  forgotPasswordPath,
  loginPath,
  registerPath,
  resetPasswordPath,
  verifyEmailPath,
} from '../../data/pageRoutes';
import ValidateEmail from './pages/ValidateEmail/ValidateEmail';
import AuthRouteGuard from 'src/components/AuthRouteGuard';

const Auth: FC = () => {
  return (
    <IonRouterOutlet>
      <Route
        exact
        path={registerPath}
        render={() => (
          <AuthRouteGuard>
            <Register />
          </AuthRouteGuard>
        )}
      />
      <Route exact path={verifyEmailPath} component={ValidateEmail} />
      <Route
        exact
        path={loginPath}
        render={() => (
          <AuthRouteGuard>
            <Login />
          </AuthRouteGuard>
        )}
      />
      <Route exact path={forgotPasswordPath} component={ForgotPassword} />
      <Route exact path={resetPasswordPath} component={ResetPassword} />
      <Route exact path={authPath}>
        <Redirect to={registerPath} />
      </Route>
    </IonRouterOutlet>
  );
};

export default Auth;
