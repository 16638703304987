import { gql } from '@apollo/client';

export const GET_TRANSACTIONS = gql`
  query getTransactions(
    $limit: Int
    $offset: Int
    $where: transaction_bool_exp
    $order_by: [transaction_order_by!]
  ) {
    transaction(
      limit: $limit
      offset: $offset
      where: $where
      order_by: $order_by
    ) {
      id
      type
      memo
      status
      fees
      amount
      provider
      direction
      created_at
      updated_at
      counterparty
      provider_reference
      wallet {
        currency
      }
    }
    transaction_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ADS = gql`
  query getAds(
    $limit: Int
    $offset: Int
    $where: ad_bool_exp
    $order_by: [ad_order_by!]
  ) {
    ad(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
      created_at
      destination_currency
      id
      is_active
      limit_max
      limit_min
      payment_time
      rate
      source_currency
      updated_at
      user_id

      ad_payment_methods {
        payment_method {
          id
          primary_field
          source_id
          data
          payment_source {
            name
          }
        }
        created_at
        id
      }
      user {
        created_at
        email
        firstname
        id
        lastname
        completed_trades {
          total
        }
      }
    }
    ad_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_TRADE_REQUESTS = gql`
  query getTradeRequests(
    $limit: Int
    $offset: Int
    $where: trade_request_bool_exp
    $order_by: [trade_request_order_by!]
  ) {
    trade_request(
      limit: $limit
      offset: $offset
      where: $where
      order_by: $order_by
    ) {
      id
      rate
      amount
      is_active
      created_at
      updated_at
      source_currency
      destination_currency
      user {
        firstname
        id
        lastname
      }
      trade_request_payment_sources {
        payment_source {
          name
          id
          is_active
          fields
        }
      }
    }
    trade_request_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_TRADES = gql`
  query getTrades(
    $limit: Int
    $offset: Int
    $where: trade_bool_exp
    $order_by: [trade_order_by!]
  ) {
    trade(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
      amount_in_escrow
      ad_id
      rate
      created_at
      customer_receivable_amount
      merchant_receivable_amount
      id
      h_id
      status
      updated_at
      user {
        firstname
        id
        lastname
      }

      ad {
        destination_currency
        rate
        source_currency
        id
        user {
          firstname
          id
          lastname
        }
      }

      chats {
        id
      }
    }

    trade_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const SUB_WALLETS = gql`
  subscription subWallets {
    wallet {
      currency
      created_at
      id
      updated_at
      balance {
        amount
      }
    }
  }
`;

export const SUB_ESCROWS = gql`
  subscription subEscrows {
    escrow {
      id
      currency
      created_at
      updated_at
      balance {
        amount
      }
    }
  }
`;
