import { ApolloQueryResult, FetchPolicy } from '@apollo/client';
import { Bank, RSProfile } from 'src/modules/common/types';
import { IObject, TradeStatus } from './generics';

export interface User {
  id: string;
  email: string;
  lastname: string;
  expiresIn: string;
  firstname: string;
  pin: string;
  accessToken: string;
  username: string;
  completed_trades?: {
    total: number;
  };
}

export interface Wallet {
  currency: string;
  created_at: string;
  id: string;
  updated_at: string;
  balance: Balance;
  free_transfer: number;
  virtual_accounts: VirtualAccount[];
}

export interface Escrow {
  currency: string;
  created_at: string;
  id: string;
  updated_at: string;
  balance: Balance;
}

export interface Balance {
  amount: number;
}

export interface VirtualAccount {
  bank: string;
  code: string;
  created_at: string;
  id: string;
  is_active: boolean;
  max: number;
  min: number;
  name: string;
  number: string;
}

export interface Identity {
  type: 'bvn';
  status: boolean;
}

export interface Profile extends User {
  wallets: Wallet[];
  escrows: Escrow[];
  identities: Identity[];
  email_verified: boolean;
}

export interface AppContextProps {
  user: User | null;
  profile: Profile | null;
  isFetchingProfile: boolean;
  setUser: (user: User | null) => void;
  setResetToken: (token: string) => void;
  resetToken: string | null;
  logout: () => void;
  authorizeAction: (onAuthorization: (token: string) => void) => void;
  paymentSources: PaymentSource[];
  banks: Bank[];
  oneSignal: any;
  pwaInstallPromptEvent: any;
  refreshProfile: () => Promise<
    ApolloQueryResult<RSProfile> | undefined | void
  >;
  currencies: Currency[];
  setOneSignalInstance: (oneSignal: any) => void;
}

export interface AppReducerState {
  user: User | null;
  profile: Profile | null;
  resetToken: string | null;
  isFetchingProfile: boolean;
  authorizationToken: string | null;
  hasInstalledPWA: boolean;
}

export enum AppReducerActionKind {
  SET_USER = 'SET_USER',
  SET_DEFAULT = 'SET_DEFAULT',
  SET_RESET_TOKEN = 'SET_RESET_TOKEN',
  SET_PROFILE = 'SET_PROFILE',
  SET_AUTH_TOKEN = 'SET_AUTH_TOKEN',
  SET_HAS_INSTALLED_PWA = 'SET_HAS_INSTALLED_PWA',
  SET_IS_FETCHING_PROFILE = 'SET_IS_FETCHING_PROFILE',
}

export interface AppReducerAction {
  type: AppReducerActionKind;
  payload?: any;
}

export interface QueryFilters {
  limit?: number;
  offset?: number;
  where?: IObject;
  order_by?: IObject;
  fetchPolicy?: FetchPolicy;
}

export interface PaymentSourceField {
  type: string;
  name: string;
  label: string;
  options: any[];
  isPrimaryField: boolean;
}

export interface PaymentSource {
  id: string;
  name: string;
  is_active: boolean;
  fields: PaymentSourceField[];
  supported_currencies: string[];
}

export interface Currency {
  id: string;
  code: string;
  flag: string;
  name: string;
  created_at: string;
  is_source: boolean;
  updated_at: string;
  is_destination: boolean;
}

export interface PaymentMethod {
  id: string;
  data: IObject;
  source_id: string;
  created_at: string;
  updated_at: string;
  primary_field: string;
  payment_source?: PaymentSource;
}

export interface Aggregate {
  aggregate: {
    count: number;
  };
}

export interface Ad {
  created_at: string;
  destination_currency: string;
  id: string;
  is_active: boolean;
  limit_max: number;
  limit_min: number;
  payment_time: string;
  rate: number;
  source_currency: string;
  updated_at: string;
  user_id: string;
  ad_payment_methods: {
    payment_method: PaymentMethod;
  }[];
  user: User;
}

export interface TradeRequest {
  id: string;
  user: User;
  rate: number;
  amount: number;
  is_active: boolean;
  created_at: string;
  updated_at: string;
  source_currency: string;
  destination_currency: string;
  trade_request_payment_sources: {
    payment_source: PaymentSource;
  }[];
  negotiations: {
    id: string;
  }[];
}

export interface Trade {
  amount_in_escrow: number;
  ad_id: string;
  created_at: string;
  rate: number;
  auto_cancellation_time: null;
  auto_dispute_time: string;
  customer_receivable_amount: number;
  merchant_receivable_amount: number;
  id: string;
  h_id: string;
  status: TradeStatus;
  updated_at: string;
  user: User;
  ad: Ad;
  chats: Chat[];
  payment_method: PaymentMethod;
}

export interface Chat {
  id: string;
  created_at: string;
  messages: ChatMessage[];
  trade: Trade;
}

export interface ChatMessage {
  id: string;
  body: string;
  byAdmin: boolean;
  chatId: string;
  negotiation_id: string;
  created_at: string;
  file_type: string;
  file_url: string;
  user: User;
}

export enum OtpSelector {
  Char1 = 'char1',
  Char2 = 'char2',
  Char3 = 'char3',
  Char4 = 'char4',
}

export interface Transaction {
  id: string;
  memo: string;
  fees: number;
  amount: number;
  provider: string;
  created_at: string;
  updated_at: string;
  counterparty: string;
  type: TransactionType;
  provider_reference: string;
  status: TransactionStatus;
  direction: TransactionDirection;
  wallet: Partial<Wallet>;
  data: {
    bank_code: string;
    account_name: string;
    account_number: string;
  };
}

export enum TransactionDirection {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

export enum TransactionType {
  FUNDING = 'FUNDING',
  LOCK = 'LOCK',
  TRANSFER = 'TRANSFER',
}

export enum TransactionStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
}

export enum AdsView {
  Ads = 'ads',
  TradeRequests = 'tradeRequests',
}
