import {
  IonBadge,
  IonButton,
  IonItem,
  IonLabel,
  IonLoading,
  IonPopover,
  IonText,
  useIonRouter,
  useIonToast,
} from '@ionic/react';
import { FC, useContext, useState } from 'react';
import { useFormik } from 'formik';

import AppContext from 'src/AppContext';
import { currencyFormatter, koboToNaira } from 'src/helpers';
import { Ad } from 'src/types';

import Avatar from '../../../../components/Avatar';
import { createTradePath } from '../../../../data/pageRoutes';
import { KebabIcon } from '../../../../assets/icons';

import './TradeAd.scss';
import ConfirmModal from '../../modals/ConfirmModal';
import { updateAd } from '../../api';
import { isApolloError } from '@apollo/client';
import cx from 'classnames';
import AmountInput from 'src/components/AmountInput';
import { number, object } from 'yup';

interface TradeAdProps {
  ad: Ad;
  refreshList?: () => void;
}

interface TradeAdState {
  isStopAdConfirmModalOpen: boolean;
  isEditRateConfirmModalOpen: boolean;
  isStoppingAd: boolean;
  isUpdatingRate: boolean;
  rate: number;
}

const TradeAd: FC<TradeAdProps> = ({ ad, refreshList }) => {
  const { profile, authorizeAction } = useContext(AppContext);
  const router = useIonRouter();
  const [present] = useIonToast();

  const sourceCurrency = ad.source_currency;
  const destinationCurrency = ad.destination_currency;
  const isOwnAd = profile?.id === ad.user.id;

  const [state, setState] = useState<TradeAdState>({
    isStopAdConfirmModalOpen: false,
    isEditRateConfirmModalOpen: false,
    isUpdatingRate: false,
    isStoppingAd: false,
    rate: ad.rate,
  });

  const handleStateUpdate = (newState: Partial<TradeAdState>) =>
    setState((_state) => ({ ..._state, ...newState }));

  const onStopAdClick = () => {
    handleStateUpdate({ isStopAdConfirmModalOpen: true });
  };

  const onEditRateClick = () => {
    handleStateUpdate({ isEditRateConfirmModalOpen: true });
  };

  const onConfirmStopAdModalDismiss = (isConfirmed: boolean) => {
    if (!isConfirmed) {
      handleStateUpdate({ isStopAdConfirmModalOpen: false });
      return;
    }
    handleStateUpdate({ isStopAdConfirmModalOpen: false });
    authorizeAction(async (authorizationToken: string) => {
      handleStateUpdate({ isStoppingAd: true });
      try {
        await updateAd({ id: ad.id, is_active: false }, authorizationToken);
        handleStateUpdate({ isStoppingAd: false });
        refreshList && refreshList();
      } catch (e: any) {
        isApolloError(e) &&
          present({
            message: e.graphQLErrors[0].message,
            duration: 3000,
            color: 'danger',
            position: 'bottom',
          });
        handleStateUpdate({
          isStoppingAd: false,
          isStopAdConfirmModalOpen: false,
        });
      }
    });
  };

  const onConfirmEditRateModalDismiss = (isConfirmed: boolean) => {
    if (!isConfirmed) {
      handleStateUpdate({ isEditRateConfirmModalOpen: false, rate: ad.rate });
      return;
    }
    handleStateUpdate({ isEditRateConfirmModalOpen: false });
    authorizeAction(async (authorizationToken: string) => {
      handleStateUpdate({ isUpdatingRate: true });
      try {
        await updateAd({ id: ad.id, rate: values.rate }, authorizationToken);
        handleStateUpdate({ isUpdatingRate: false });
        refreshList && refreshList();
      } catch (e: any) {
        isApolloError(e) &&
          present({
            message: e.graphQLErrors[0].message,
            duration: 3000,
            color: 'danger',
            position: 'bottom',
          });
        handleStateUpdate({
          isUpdatingRate: false,
          isEditRateConfirmModalOpen: false,
        });
      }
    });
  };

  const swapAd = () => {
    router.push(
      `${createTradePath}?ad_id=${ad.id}&source=${ad.source_currency}&destination=${ad.destination_currency}`
    );
  };

  const onItemClick = () => {
    if (isOwnAd) {
      return;
    }
    router.push(
      `${createTradePath}?ad_id=${ad.id}&source=${ad.source_currency}&destination=${ad.destination_currency}`
    );
  };

  const rateForm = {
    rate: ad.rate,
  };

  const { values, setFieldValue, isValid } = useFormik({
    initialValues: rateForm,
    validateOnChange: true,
    validationSchema: object({
      rate: number().min(1).required('Rate is required'),
    }),
    onSubmit: () => {},
  });

  return (
    <IonItem
      className={cx(['tradeAd', { 'tradeAd--inactive': !ad.is_active }])}
      detail={false}
      button
      onClick={onItemClick}
    >
      <Avatar
        className='tradeAd__sellerAvatar'
        slot='start'
        title={ad?.user.firstname}
      ></Avatar>
      <IonLabel>
        <div className='tradeAd__header'>
          <div className='tradeAd__seller'>
            <IonText className='tradeAd__sellerName'>
              <p>{ad?.user.firstname}</p>
            </IonText>
            <IonText className='tradeAd__sellerStats' color='medium'>
              <p>
                {ad.user.completed_trades?.total || 0} Completed Trade
                {ad.user.completed_trades?.total! !== 1 ? 's' : ''}
              </p>
            </IonText>
          </div>
          {/* TODO: Edit action */}
          <div>
            {!ad.is_active ? (
              <IonText>
                <span>Inactive</span>
              </IonText>
            ) : isOwnAd ? (
              <>
                <IonButton
                  className='popoverBtn'
                  fill='clear'
                  id={`edit-actions-${ad.id}`}
                >
                  <KebabIcon />
                </IonButton>
                <IonPopover
                  trigger={`edit-actions-${ad.id}`}
                  dismissOnSelect={true}
                  showBackdrop={true}
                  className='tradeAd__popover'
                >
                  <IonItem
                    button={true}
                    detail={false}
                    lines='none'
                    onClick={onStopAdClick}
                  >
                    Stop Ad
                  </IonItem>
                  <IonItem
                    button={true}
                    detail={false}
                    lines='none'
                    onClick={onEditRateClick}
                  >
                    Edit rate
                  </IonItem>
                </IonPopover>
              </>
            ) : (
              <IonButton
                disabled={state.isStoppingAd}
                onClick={swapAd}
                fill='clear'
                className='link'
              >
                Swap
              </IonButton>
            )}
          </div>
        </div>
        <div className='tradeAd__content'>
          <div className='tradeAd__info'>
            <IonText color='medium' className='tradeAd__limits'>
              <p>
                <span>Limits &nbsp;</span>
                <br />
                <span>
                  min :{' '}
                  {currencyFormatter({
                    value: koboToNaira(ad.limit_min),
                    currency: destinationCurrency,
                  })}{' '}
                  <br />
                  max:{' '}
                  {currencyFormatter({
                    value: koboToNaira(ad.limit_max),
                    currency: destinationCurrency,
                  })}{' '}
                </span>
              </p>
            </IonText>
          </div>
          <IonText className='tradeAd__price'>
            <p>
              <span>
                {currencyFormatter({
                  value: ad.rate,
                  currency: destinationCurrency,
                })}
              </span>
              <br />
              <span>per {sourceCurrency}</span>
            </p>
          </IonText>
        </div>
        <div className='tradeAd__footer'>
          <div className='tradeAd__payments'>
            {ad.ad_payment_methods.slice(0, 2).map((paymentMethod) => (
              <IonBadge
                className='tradeAd__paymentsBadge'
                key={paymentMethod.payment_method.id}
              >
                {paymentMethod.payment_method.payment_source?.name.toUpperCase()}
              </IonBadge>
            ))}
            {ad.ad_payment_methods.length > 2 && (
              <>
                <IonButton
                  id='more-methods'
                  className='tradeAd__paymentsTrigger'
                  fill='clear'
                >
                  <IonBadge className='tradeAd__paymentsBadge'>{`+${
                    ad.ad_payment_methods.length - 2
                  }`}</IonBadge>
                </IonButton>
                <IonPopover
                  className='tradeAd__paymentsPopover'
                  trigger='more-methods'
                  triggerAction='click'
                >
                  <div className='tradeAd__payments tradeAd__payments--more'>
                    {ad.ad_payment_methods.slice(2).map((paymentMethod) => (
                      <IonBadge
                        className='tradeAd__paymentsBadge'
                        key={paymentMethod.payment_method.id}
                      >
                        {paymentMethod.payment_method.payment_source?.name.toUpperCase()}
                      </IonBadge>
                    ))}
                  </div>
                </IonPopover>
              </>
            )}
          </div>
          <IonText className='tradeAd__currency'>
            <p>
              {sourceCurrency} {'>'} {destinationCurrency}
            </p>
          </IonText>
        </div>
      </IonLabel>
      <ConfirmModal
        isOpen={state.isStopAdConfirmModalOpen}
        title='Stop Ad'
        subText='Are you sure you want to stop this ad?'
        onDismiss={onConfirmStopAdModalDismiss}
      />
      <ConfirmModal
        isOpen={state.isEditRateConfirmModalOpen}
        title='Edit rate'
        onDismiss={onConfirmEditRateModalDismiss}
        disableConfirmBtn={!isValid}
      >
        <AmountInput
          placeholder='Enter rate'
          showLabel={false}
          showCurrency
          currency='NGN'
          value={values.rate}
          className='tradeAd__rateInput'
          onValueChange={(val: number) => setFieldValue('rate', val)}
        />
      </ConfirmModal>
      <IonLoading isOpen={state.isStoppingAd} message={'Stopping Ad...'} />
    </IonItem>
  );
};

export default TradeAd;
