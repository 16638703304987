import { IObject } from 'src/types';
import { array, object, string } from 'yup';

export const addPaymentMethodValidationSchema = object({
  source_id: string().required('Please select a payment provider'),
  data: object({}).test(
    'data',
    'Please fill in the required fields',
    (value: IObject) => {
      if (!value) return false;
      const keys = Object.keys(value);
      return (
        Boolean(keys.length) &&
        keys.every((key) => !!value[key] && value[key].trim())
      );
    }
  ),
});

export const postAdValidationSchema = object({
  source_currency: string().required('Please select a currency'),
  destination_currency: string().required('Please select a currency'),
  rate: string()
    .test('is-zero', 'rate cannot be zero', (value) => value !== '0')
    .required('Please enter a rate'),
  limit_min: string()
    .test('is-zero', 'limit_min cannot be zero', (value) => value !== '0')
    .required('Please enter a minimum limit'),
  limit_max: string()
    .test('is-zero', 'limit_max cannot be zero', (value) => value !== '0')
    .test(
      'is less than limit_min',
      'limit_max must be more than limit_min',
      (value, context) =>
        value === undefined || +value > context.parent.limit_min
    )
    .required('Please enter a maximum limit'),
  payment_time: string().required('Please enter a payment time'),
  payment_methods: array()
    .min(1, 'Please select atleast one payment method')
    .max(5, 'Maximum number of payment methods exceeded')
    .of(string())
    .required('Please select a payment method'),
});

export const postTradeRequestValidationSchema = object({
  source_currency: string().required('Please select a currency'),
  destination_currency: string().required('Please select a currency'),
  rate: string()
    .test('is-zero', 'rate cannot be zero', (value) => value !== '0')
    .required('Please enter a rate'),
  amount: string()
    .test('is-zero', 'amount cannot be zero', (value) => value !== '0')
    .required('Please enter an amount'),
  payment_sources: array()
    .min(1, 'Please select atleast one payment source')
    .max(5, 'Maximum number of payment sources exceeded')
    .of(string())
    .required('Please select a payment source'),
});
