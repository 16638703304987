import { IonSpinner } from '@ionic/react';
import { FC, Suspense, useContext } from 'react';
import { Redirect, useLocation } from 'react-router';
import AppContext from 'src/AppContext';
import {
  kycPath,
  loginPath,
  setPinPath,
  verifyEmailPath,
} from 'src/data/pageRoutes';

const PrivateRouteGuard: FC<any> = ({ children }) => {
  const { user, profile, isFetchingProfile } = useContext(AppContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  if (!user) {
    if (location.pathname && location.pathname !== '/') {
      queryParams.set('redirect', location.pathname);
    }
    return (
      <Redirect to={{ pathname: loginPath, search: queryParams.toString() }} />
    );
  }

  if (!profile || isFetchingProfile) {
    return <IonSpinner className='app__loader' name='crescent' />;
  }

  if (profile && !profile.email_verified) {
    return <Redirect to={{ pathname: verifyEmailPath }} />;
  }

  const hasSetPin = !!profile?.pin;
  const hasVerifiedBvn = Boolean(
    profile?.identities &&
      profile.identities.find((identity) => identity.type === 'bvn')?.status
  );

  if (!hasVerifiedBvn) {
    return <Redirect to={{ pathname: kycPath }} />;
  }

  if (!hasSetPin) {
    return <Redirect to={{ pathname: setPinPath }} />;
  }

  return <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>;
};

export default PrivateRouteGuard;
