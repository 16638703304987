import { apiService } from 'src/helpers';
import { Transaction } from 'src/types';
import { GET_TRANSACTION_BY_PK } from './queries';

export const fetchTransaction = (id: string) => {
  return apiService.query<{ transaction_by_pk: Transaction }>({
    query: GET_TRANSACTION_BY_PK,
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });
};
