import appStorage from './helpers/storage';
import {
  AppReducerAction,
  AppReducerActionKind,
  AppReducerState,
} from './types';

export const appReducer = (
  state: AppReducerState,
  action: AppReducerAction
) => {
  switch (action.type) {
    case AppReducerActionKind.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case AppReducerActionKind.SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
        isFetchingProfile: false,
      };
    case AppReducerActionKind.SET_IS_FETCHING_PROFILE:
      return {
        ...state,
        isFetchingProfile: action.payload,
      };
    case AppReducerActionKind.SET_RESET_TOKEN:
      return {
        ...state,
        resetToken: action.payload,
      };
    case AppReducerActionKind.SET_AUTH_TOKEN:
      return {
        ...state,
        authorizationToken: action.payload,
      };
    case AppReducerActionKind.SET_DEFAULT:
      return {
        ...action.payload,
      };
    case AppReducerActionKind.SET_HAS_INSTALLED_PWA:
      return {
        ...state,
        hasInstalledPWA: action.payload,
      };
    default:
      return state;
  }
};

export const appReducerInitialState: AppReducerState = {
  user: null,
  profile: null,
  resetToken: null,
  isFetchingProfile: false,
  authorizationToken: null,
  hasInstalledPWA: false,
};

export const appReducerInitializer = async (initialState: AppReducerState) => {
  const user = await appStorage.getItem('user');
  return {
    ...initialState,
    user,
  };
};
