import { apiService } from 'src/helpers';
import {
  GET_AD_BY_PK,
  GET_CHAT_BY_PK,
  GET_NEGOTIATIONS,
  GET_NEGOTIATION_BY_PK,
  GET_TRADE_REQUEST_BY_PK,
  GET_TRANSACTION_FEE,
  SUBSCRIBE_TO_CHAT_MESSAGES,
  SUBSCRIBE_TO_NEGOTIATION_MESSAGES,
  SUBSCRIBE_TRADE_BY_PK,
} from './queries';
import { RDInsertMessage, RDStartTrade } from './types/request';
import {
  INSERT_MESSAGE,
  START_NEGOTIATION,
  START_TRADE,
  UPDATE_TRADE,
} from './mutations';
import { Ad, Chat, ChatMessage, Trade, TradeRequest } from 'src/types';
import { Negotiation } from './types';

export const fetchAd = (id: string) => {
  return apiService.query<{
    ad_by_pk: Ad;
  }>({
    query: GET_AD_BY_PK,
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });
};

export const fetchTradeRequest = ({
  id,
  userId,
}: {
  id: string;
  userId: string;
}) => {
  return apiService.query<{
    trade_request_by_pk: TradeRequest;
  }>({
    query: GET_TRADE_REQUEST_BY_PK,
    variables: {
      id,
      userId,
    },
    fetchPolicy: 'network-only',
  });
};

export const startNegotiation = ({
  tradeRequestId,
  userId,
}: {
  tradeRequestId: string;
  userId: string;
}) => {
  return apiService.mutate<{
    insert_negotiation_one: {
      id: string;
    };
  }>({
    mutation: START_NEGOTIATION,
    variables: {
      data: {
        trade_request_id: tradeRequestId,
        user_id: userId,
      },
    },
  });
};

export const fetchNegotiation = (id: string) => {
  return apiService.query<{ negotiation_by_pk: Negotiation }>({
    query: GET_NEGOTIATION_BY_PK,
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });
};

export const subscribeToNegotiationMessages = (id: string, from: string) => {
  return apiService.subscribe<{ message: ChatMessage[] }>({
    query: SUBSCRIBE_TO_NEGOTIATION_MESSAGES,
    variables: {
      id,
      from,
    },
  });
};

export const fetchChat = (id: string) => {
  return apiService.query<{ chat_by_pk: Chat }>({
    query: GET_CHAT_BY_PK,
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });
};

export const subscribeToChatMessages = (id: string, from: string) => {
  return apiService.subscribe<{ message: ChatMessage[] }>({
    query: SUBSCRIBE_TO_CHAT_MESSAGES,
    variables: {
      id,
      from,
    },
  });
};

export const insertMessage = (data: RDInsertMessage) => {
  return apiService.mutate({
    mutation: INSERT_MESSAGE,
    variables: { data },
  });
};

export const startTrade = (data: RDStartTrade, token: string) => {
  return apiService.mutate<{
    startTrade: {
      data: {
        id: string;
      };
    };
  }>({
    mutation: START_TRADE,
    variables: { data },
    context: {
      headers: {
        'use-pin-token': token,
      },
    },
  });
};

export const fetchTrade = (id: string) => {
  return apiService.subscribe<{ trade_by_pk: Trade }>({
    query: SUBSCRIBE_TRADE_BY_PK,
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });
};

export const updateTrade = (
  { id, status }: { id: string; status: string },
  token: string
) => {
  return apiService.mutate<{
    updateTrade: {
      data: {
        id: string;
      };
    };
  }>({
    mutation: UPDATE_TRADE,
    variables: {
      id,
      status,
    },
    context: {
      headers: {
        'use-pin-token': token,
      },
    },
  });
};

export const fetchTransactionFee = (amount: number) => {
  return apiService.query<{ getTransactionFee: { fee: number } }>({
    query: GET_TRANSACTION_FEE,
    variables: { amount },
    fetchPolicy: 'network-only',
  });
};

export const fetchNegotiations = (tradeRequestId: string) => {
  return apiService.query<{ negotiation: Negotiation[] }>({
    query: GET_NEGOTIATIONS,
    variables: { tradeRequestId },
    fetchPolicy: 'network-only',
  });
};
