import { FC } from 'react';
import { Route, Redirect } from 'react-router';
import { IonRouterOutlet } from '@ionic/react';

import {
  acceptTradeRequestPath,
  createTradePath,
  tradeChatPath,
  tradeDetailsPath,
  tradePath,
  tradeRequestChatPath,
  tradeRequestNegotiationsPath,
} from '../../data/pageRoutes';

import CreateTrade from './pages/CreateTrade/CreateTrade';
import TradeDetails from './pages/TradeDetails';
import TradeChat from './pages/TradeChat/TradeChat';
import AcceptTradeRequest from './pages/AcceptTradeRequest';
import TradeRequestChat from './pages/TradeRequestChat';
import TradeRequestNegotiations from './pages/TradeRequestNegotiations';

const Trade: FC = () => {
  return (
    <IonRouterOutlet>
      <Route exact path={createTradePath} component={CreateTrade} />
      <Route
        exact
        path={acceptTradeRequestPath}
        component={AcceptTradeRequest}
      />
      <Route exact path={`${tradeDetailsPath}/:id`} component={TradeDetails} />
      <Route exact path={`${tradeChatPath}/:id`} component={TradeChat} />
      <Route
        exact
        path={`${tradeRequestChatPath}/:id`}
        component={TradeRequestChat}
      />
      <Route
        exact
        path={`${tradeRequestNegotiationsPath}/:id`}
        component={TradeRequestNegotiations}
      />

      <Route exact path={tradePath}>
        <Redirect to={createTradePath} />
      </Route>
    </IonRouterOutlet>
  );
};

export default Trade;
