import { IonRouterOutlet } from '@ionic/react';
import { FC } from 'react';
import { Redirect, Route } from 'react-router';
import {
  kycPath,
  onboardingPath,
  setPinPath,
  validateKycPath,
} from 'src/data/pageRoutes';
import KYC from './pages/KYC';
import ValidateKYC from './pages/ValidateKYC';
import SetPin from './pages/SetPin';

const Onboarding: FC = () => {
  return (
    <IonRouterOutlet>
      <Route exact path={kycPath} component={KYC} />
      <Route exact path={validateKycPath} component={ValidateKYC} />
      <Route exact path={setPinPath} component={SetPin} />
      <Route exact path={onboardingPath}>
        <Redirect to={kycPath} />
      </Route>
    </IonRouterOutlet>
  );
};

export default Onboarding;
