import { FC, useCallback, useContext, useEffect, useState } from 'react';

import './CreateTrade.scss';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSkeletonText,
  IonSpinner,
  IonText,
  useIonRouter,
  useIonToast,
} from '@ionic/react';
import { useLocation } from 'react-router';
import { CloseIcon } from '../../../../assets/icons';
import Avatar from '../../../../components/Avatar/Avatar';
import AmountInput from '../../../../components/AmountInput/AmountInput';
import { fetchAd, fetchTransactionFee, startTrade } from '../../api';
import { currencyFormatter, koboToNaira, nairaToKobo } from 'src/helpers';
import { useFormik } from 'formik';
import { createTradeValidationSchema } from '../../validators';
import { isApolloError } from '@apollo/client';
import { tradeDetailsPath } from 'src/data/pageRoutes';
import { Ad } from 'src/types';
import AppContext from 'src/AppContext';
import { useDebounce, useUpdateEffect } from 'usehooks-ts';

interface CreateAdState {
  ad: Ad | null;
  isFetchingAd: boolean;
  isCreatingAd: boolean;
  isFetchingFee: boolean;
  fee: number;
}

const CreateTrade: FC = () => {
  const router = useIonRouter();
  const [present] = useIonToast();

  const { authorizeAction } = useContext(AppContext);

  const location = useLocation().search;
  const adId = new URLSearchParams(location).get('ad_id') as string;
  const sourceCurrency = new URLSearchParams(location).get('source') as string;
  const destinationCurrency = new URLSearchParams(location).get(
    'destination'
  ) as string;

  const [state, setState] = useState<CreateAdState>({
    ad: null,
    isFetchingAd: true,
    isCreatingAd: false,
    isFetchingFee: false,
    fee: 0,
  });

  const handleStateUpdate = (newState: Partial<CreateAdState>) =>
    setState((_state) => ({ ..._state, ...newState }));

  const initialValues = {
    amount: '',
    payment_method_id: '',
  };

  const onSubmit = async () => {
    authorizeAction(async (authorizationToken: string) => {
      handleStateUpdate({ isCreatingAd: true });
      const data = {
        ...values,
        ad_id: adId,
      };
      try {
        const res = await startTrade(data, authorizationToken);
        present({
          message: 'Trade created successfully',
          duration: 3000,
          color: 'success',
          position: 'bottom',
        });
        handleStateUpdate({ isCreatingAd: false });
        router.push(`${tradeDetailsPath}/${res.data?.startTrade.data.id}`);
      } catch (e: any) {
        console.log(e);
        isApolloError(e) &&
          present({
            message: e.graphQLErrors[0].message,
            duration: 3000,
            color: 'danger',
            position: 'bottom',
          });
        handleStateUpdate({ isCreatingAd: false });
      }
    });
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    isValid,
    isSubmitting,
  } = useFormik<typeof initialValues>({
    initialValues,
    onSubmit,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: createTradeValidationSchema,
  });

  const getAd = useCallback(async () => {
    handleStateUpdate({ isFetchingAd: true });
    try {
      const res = await fetchAd(adId as string);
      handleStateUpdate({
        ad: res.data.ad_by_pk,
        isFetchingAd: false,
      });
    } catch (e) {
      handleStateUpdate({
        ad: null,
        isFetchingAd: false,
      });
    }
  }, [adId]);

  const getTransactionFee = async () => {
    try {
      handleStateUpdate({
        isFetchingFee: true,
      });
      const res = await fetchTransactionFee(
        nairaToKobo((ad?.rate as number) * +values.amount)
      );
      handleStateUpdate({
        fee: res.data.getTransactionFee.fee,
        isFetchingFee: false,
      });
    } catch (e) {
      handleStateUpdate({ isFetchingFee: false });
    }
  };

  const getFee = useDebounce(values.amount, 500);

  const onBackClick = () => {
    router.goBack();
  };

  useEffect(() => {
    getAd();
  }, [getAd]);

  useUpdateEffect(() => {
    getTransactionFee();
  }, [getFee]);

  const { ad, isFetchingAd } = state;

  return (
    <IonPage className='createTrade'>
      <IonHeader className='createTrade__header' translucent={true}>
        <div className='createTrade__headerBg'></div>
        <div className='createTrade__headerContent'>
          <IonButton
            fill='clear'
            color={'dark'}
            onClick={onBackClick}
            routerDirection='back'
            className='createTrade__headerContentButton'
          >
            <CloseIcon />
          </IonButton>
          <IonText color={'dark'} className='createTrade__headerContentTitle'>
            <h1>
              Swap {sourceCurrency} {'>'} {destinationCurrency}
            </h1>
          </IonText>
        </div>
      </IonHeader>
      <IonContent fullscreen={true} className='createTrade__content'>
        {isFetchingAd || !ad ? (
          <>
            <IonItem className='createTrade__skeleton' lines='none'>
              <IonSkeletonText
                className='createTrade__skeletonAvatar'
                slot='start'
                animated={true}
              ></IonSkeletonText>
              <IonLabel>
                <IonSkeletonText animated={true}></IonSkeletonText>
              </IonLabel>
            </IonItem>

            <IonItem className='createTrade__skeleton--last' lines='none'>
              <IonLabel>
                <IonSkeletonText animated={true}></IonSkeletonText>
                <IonSkeletonText animated={true}></IonSkeletonText>
              </IonLabel>
            </IonItem>
          </>
        ) : (
          <>
            <div className='createTrade__merchant'>
              <Avatar
                title={ad?.user.firstname}
                className='createTrade__merchantAvatar'
              />
              <div className='createTrade__merchantInfo'>
                <IonText color='dark' className='createTrade__merchantName'>
                  <p>{ad?.user?.firstname}</p>
                </IonText>
                {/* <IonText color='medium' className='createTrade__merchantStats'>
              <p>489 Trades | 80.9% Completion Rate</p>
            </IonText> */}
              </div>
            </div>
            <div className='createTrade__info'>
              <IonText className='createTrade__rate' color='dark'>
                <p>
                  {currencyFormatter({
                    value: ad?.rate as number,
                    style: 'currency',
                    currency: ad?.destination_currency,
                  })}{' '}
                  <span>per {ad?.source_currency}</span>
                </p>
              </IonText>
              <IonText className='createTrade__limit' color='medium'>
                <p>
                  Limit &nbsp;{' '}
                  <span>
                    {' '}
                    {currencyFormatter({
                      value: koboToNaira(ad?.limit_min) as number,
                      style: 'currency',
                      currency: ad?.destination_currency,
                    })}{' '}
                    -{' '}
                    {currencyFormatter({
                      value: koboToNaira(ad?.limit_max) as number,
                      style: 'currency',
                      currency: ad?.destination_currency,
                    })}{' '}
                  </span>
                </p>
              </IonText>
            </div>
          </>
        )}

        <form className='createTrade__form' onSubmit={handleSubmit}>
          <IonGrid>
            <IonRow>
              <IonCol>
                <AmountInput
                  className='createTrade__input'
                  placeholder='Enter amount'
                  showLabel={false}
                  showCurrency
                  currency={sourceCurrency}
                  onValueChange={(val: number) => setFieldValue('amount', val)}
                  disabled={isFetchingAd}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position='stacked'>Payment Method</IonLabel>
                  <IonSelect
                    placeholder='Select Payment Methods'
                    onIonChange={handleChange}
                    name='payment_method_id'
                    onIonBlur={handleBlur}
                    value={values.payment_method_id}
                    disabled={isFetchingAd}
                  >
                    {ad?.ad_payment_methods.map((paymentMethod) => {
                      return (
                        <IonSelectOption
                          value={paymentMethod.payment_method.id}
                          key={paymentMethod.payment_method.id}
                        >
                          {paymentMethod.payment_method.payment_source?.name}
                        </IonSelectOption>
                      );
                    })}
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
          {!values.amount || !ad ? (
            <></>
          ) : (
            <>
              <div className='createTrade__calculation'>
                <IonText
                  color='medium'
                  className='createTrade__calculationText'
                >
                  <p>
                    {currencyFormatter({
                      value: ad?.rate as number,
                      style: 'currency',
                      currency: ad?.destination_currency,
                    })}{' '}
                    x {values.amount}{' '}
                  </p>
                </IonText>
                <div className='createTrade__calculationRow'>
                  <IonText
                    color='dark'
                    className='createTrade__calculationSign'
                  >
                    <p>=</p>
                  </IonText>
                  <IonText
                    color='medium'
                    className='createTrade__calculationText'
                  >
                    {currencyFormatter({
                      value: (ad?.rate as number) * +values.amount,
                      style: 'currency',
                      currency: ad?.destination_currency,
                    })}{' '}
                    (merchant pays)
                  </IonText>
                </div>
                {state.isFetchingFee ? (
                  <IonItem className='createTrade__skeleton--fee' lines='none'>
                    <IonLabel>
                      <IonSkeletonText animated={true}></IonSkeletonText>
                    </IonLabel>
                  </IonItem>
                ) : (
                  <div className='createTrade__calculationRow'>
                    <IonText
                      color='dark'
                      className='createTrade__calculationSign'
                    >
                      <p>-</p>
                    </IonText>
                    <IonText
                      color='medium'
                      className='createTrade__calculationText'
                    >
                      {currencyFormatter({
                        value: koboToNaira(state.fee),
                        style: 'currency',
                        currency: ad?.destination_currency,
                      })}{' '}
                      (trade charge)
                    </IonText>
                  </div>
                )}
              </div>
              <div className='createTrade__result'>
                <IonText color='primary' className='createTrade__resultTitle'>
                  <p>You get</p>
                </IonText>
                <IonText color='dark' className='createTrade__resultAmount'>
                  <p>
                    {currencyFormatter({
                      value:
                        (ad?.rate as number) * +values.amount -
                        koboToNaira(+state.fee),
                      style: 'currency',
                      currency: ad?.destination_currency,
                    })}
                  </p>
                </IonText>
              </div>
            </>
          )}
          <IonButton
            type='submit'
            expand='full'
            className='createTrade__submit'
            disabled={
              !isValid ||
              isSubmitting ||
              state.isCreatingAd ||
              state.isFetchingFee ||
              state.fee === 0
            }
          >
            <span className='createTrade__submitText'>
              Swap{' '}
              {isSubmitting || state.isCreatingAd ? (
                <IonSpinner name='crescent'></IonSpinner>
              ) : null}
            </span>
          </IonButton>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default CreateTrade;
