import { gql } from '@apollo/client';

export const GET_PAYMENT_METHODS = gql`
  query GetPaymentMethods($userId: uuid!) {
    payment_method(
      where: {
        payment_source: { is_active: { _eq: true } }
        user_id: { _eq: $userId }
      }
      order_by: { created_at: desc }
    ) {
      created_at
      data
      id
      primary_field
      source_id
      updated_at
      payment_source {
        name
        id
        supported_currencies
      }
    }
  }
`;
