import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  useIonToast,
} from '@ionic/react';
import { FC, useContext, useEffect, useState } from 'react';

import './Withdraw.scss';
import { CloseIcon } from '../../../../assets/icons';
import AmountInput from '../../../../components/AmountInput/AmountInput';
import AddBankAccountModal from '../../modals/AddBankAccountModal/AddBankAccountModal';
import { homePath } from '../../../../data/pageRoutes';
import { fetchBanks, withdraw } from '../../api';
import { isApolloError } from '@apollo/client';
import { BankAccount } from '../../types';
import AppContext from 'src/AppContext';
import { useHistory } from 'react-router';
import { getCurrencySymbol, nairaToKobo } from 'src/helpers';

interface WithdrawState {
  isBankAccountModalOpen: boolean;
  bank_accounts: BankAccount[];
  selectedBankAccount: Omit<BankAccount, 'id' | 'bank_name'>;
  isFetching: boolean;
  amount: number;
  isSubmitting: boolean;
}

const Withdraw: FC = () => {
  const [present] = useIonToast();
  const history = useHistory();

  const { banks, authorizeAction, profile } = useContext(AppContext);

  const [state, setState] = useState<WithdrawState>({
    isBankAccountModalOpen: false,
    selectedBankAccount: {
      bank_code: '',
      account_number: '',
      account_name: '',
      wallet_id: '',
    },
    isSubmitting: false,
    amount: 0,
    bank_accounts: [],
    isFetching: false,
  });

  const onSubmit = async (e: any) => {
    e.preventDefault();
    authorizeAction(async (authorizationToken: string) => {
      handleStateUpdate({ isSubmitting: true });
      const data = {
        ...state.selectedBankAccount,
        amount: nairaToKobo(state.amount),
      };
      try {
        await withdraw(data, authorizationToken);
        handleStateUpdate({ isSubmitting: false });
        present({
          message: 'Withdrawal request is being processed',
          duration: 3000,
          color: 'success',
          position: 'bottom',
        });
        history.push(homePath);
      } catch (e: any) {
        isApolloError(e) &&
          present({
            message: e.graphQLErrors[0].message,
            duration: 3000,
            color: 'danger',
            position: 'bottom',
          });
        handleStateUpdate({ isSubmitting: false });
      }
    });
  };

  const handleStateUpdate = (newState: Partial<WithdrawState>) =>
    setState((_state) => ({ ..._state, ...newState }));

  const handleFetchBanks = () => {
    return fetchBanks();
  };

  const getBanks = () => {
    handleStateUpdate({
      isFetching: true,
    });

    handleFetchBanks()
      .then((res) => {
        handleStateUpdate({
          isFetching: false,
          bank_accounts: res.data.bank_account,
        });
      })
      .catch((err: any) => {
        isApolloError(err) &&
          present({
            message: err.graphQLErrors[0].message,
            duration: 3000,
            color: 'danger',
            position: 'bottom',
          });
        handleStateUpdate({
          isFetching: false,
        });
      });
  };

  const onBankAccountModalDismiss = (bankAccount?: any) => {
    handleStateUpdate({ isBankAccountModalOpen: false });
    if (bankAccount) {
      handleStateUpdate({
        selectedBankAccount: {
          ...bankAccount,
        },
      });
    }
  };

  useEffect(() => {
    getBanks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonPage className='withdraw'>
      <IonHeader className='withdraw__header' translucent={true}>
        <div className='withdraw__headerBg'></div>
        <div className='withdraw__headerContent'>
          <IonButton
            fill='clear'
            color={'dark'}
            routerLink={homePath}
            routerDirection='back'
            className='withdraw__headerContentButton'
          >
            <CloseIcon />
          </IonButton>
          <IonText color={'dark'} className='withdraw__headerContentTitle'>
            <h1>Withdraw</h1>
          </IonText>
        </div>
      </IonHeader>
      <IonContent className='withdraw__content' fullscreen={true}>
        <form className='withdraw__form' onSubmit={onSubmit}>
          <IonGrid>
            <IonRow>
              <IonCol>
                <AmountInput
                  placeholder='Enter amount'
                  label='Amount'
                  showCurrency
                  currency='NGN'
                  onValueChange={(val: number) =>
                    handleStateUpdate({ amount: val })
                  }
                  disabled={state.isFetching}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem className='withdraw__formSelect'>
                  <IonLabel position='stacked'>
                    <span className='withdraw__formSelectLabel'>
                      <span>Bank Account</span>
                      <IonButton
                        onClick={() =>
                          handleStateUpdate({ isBankAccountModalOpen: true })
                        }
                        fill='clear'
                        className='link'
                        disabled={state.isFetching}
                      >
                        Add Bank Account
                      </IonButton>
                    </span>
                  </IonLabel>
                  <IonSelect
                    placeholder='Select Bank Account'
                    aria-label='Bank Account'
                    disabled={state.isFetching || !state.bank_accounts.length}
                    onIonChange={(e) => {
                      const account_number = e.target.value;
                      const { bank_code, account_name, wallet_id } =
                        state.bank_accounts.find(
                          (account) => account.account_number === account_number
                        )!;
                      handleStateUpdate({
                        selectedBankAccount: {
                          bank_code,
                          account_name,
                          account_number,
                          wallet_id,
                        },
                      });
                    }}
                    value={state.selectedBankAccount.account_number}
                  >
                    {state.bank_accounts.map((account) => {
                      return (
                        <IonSelectOption
                          key={account.id}
                          value={account.account_number}
                        >
                          {account.account_name} - {account.bank_name}
                        </IonSelectOption>
                      );
                    })}
                  </IonSelect>
                </IonItem>
                <IonText color='tertiary' className='withdraw__info'>
                  <p>
                    Fee:&nbsp;
                    {profile?.wallets[0].free_transfer === 0
                      ? `${getCurrencySymbol('NGN')} 25.00`
                      : `You have ${
                          profile?.wallets[0].free_transfer
                        } free transfer${
                          profile?.wallets[0].free_transfer! > 1 ? 's' : ''
                        } left`}
                  </p>
                </IonText>
                {state.isFetching && <IonSpinner name='crescent'></IonSpinner>}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton
                  type='submit'
                  expand='full'
                  disabled={
                    state.isFetching ||
                    state.isSubmitting ||
                    state.amount === 0 ||
                    state.selectedBankAccount.bank_code === ''
                  }
                >
                  Withdraw{' '}
                  {state.isSubmitting && (
                    <IonSpinner name='crescent'></IonSpinner>
                  )}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
        <AddBankAccountModal
          isOpen={state.isBankAccountModalOpen}
          onDismiss={onBankAccountModalDismiss}
          banks={banks}
          refresh={getBanks}
        />
      </IonContent>
    </IonPage>
  );
};

export default Withdraw;
