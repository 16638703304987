import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
  useIonToast,
} from '@ionic/react';
import { FC, useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import cx from 'classnames';

import { LogoMini } from '../../../../assets/img';
import { forgotPasswordPath, registerPath } from '../../../../data/pageRoutes';
import { loginValidationSchema } from '../../validators';
import { useFormik } from 'formik';
import PasswordInput from '../../components/PasswordInput/PasswordInput';
import { postLogin } from '../../api';
import AppContext from 'src/AppContext';
import { isApolloError } from '@apollo/client';

import './Login.scss';

const Login: FC = () => {
  const history = useHistory();
  const [present] = useIonToast();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { setUser } = useContext(AppContext);

  const initialValues = {
    email: '',
    password: '',
  };

  const onSubmit = async (values: typeof initialValues) => {
    try {
      const res = await postLogin(values);
      setUser(res.data!.login.data);
      const redirectTo = queryParams.get('redirect');
      queryParams.delete('redirect');
      if (redirectTo) {
        history.push({ pathname: redirectTo, search: queryParams.toString() });
        return;
      }
    } catch (e: any) {
      isApolloError(e) &&
        present({
          message: e.graphQLErrors[0].message,
          duration: 3000,
          color: 'danger',
        });
    }
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    touched,
    errors,
    isSubmitting,
  } = useFormik({
    onSubmit,
    initialValues,
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: loginValidationSchema,
  });

  return (
    <IonPage className='login'>
      <IonContent className='ion-padding-horizontal ion-padding-top'>
        <LogoMini className='login__logo' />
        <div className='login__header'>
          <IonText color='primary'>
            <h2>Welcome</h2>
          </IonText>
          <IonText>
            <p>
              Enter your login details, don’t have an account? <br />
              <Link
                to={{ pathname: registerPath, search: queryParams.toString() }}
              >
                click here to sign up.
              </Link>
            </p>
          </IonText>
        </div>
        <form className='login__form' onSubmit={handleSubmit}>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonItem
                  className={cx({
                    'ion-invalid': Boolean(touched.email && errors.email),
                  })}
                >
                  <IonLabel position='stacked'>Email</IonLabel>
                  <IonInput
                    legacy
                    name='email'
                    value={values.email}
                    onIonBlur={handleBlur}
                    onIonInput={handleChange}
                  ></IonInput>
                  <IonNote slot='error'>
                    {touched.email && errors.email ? errors.email : ''}
                  </IonNote>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <PasswordInput
                  legacy
                  name='password'
                  label='Password'
                  onIonBlur={handleBlur}
                  value={values.password}
                  onIonInput={handleChange}
                  isValid={!Boolean(touched.password && errors.password)}
                  errorText={
                    touched.password && errors.password ? errors.password : ''
                  }
                />
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonButton
            type='submit'
            expand='full'
            className='login__formSubmit'
            disabled={!isValid || isSubmitting}
          >
            Login
            {isSubmitting ? <IonSpinner name='crescent'></IonSpinner> : null}
          </IonButton>
          <IonText class='login__forgot'>
            Can't remember your password? &nbsp;&nbsp;
            <Link to={forgotPasswordPath}>click here to reset</Link>
          </IonText>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default Login;
