import { FC, useContext, useEffect, useState } from 'react';

import './AdsList.scss';
import { fetchAds } from '../../api';
import { Ad, IObject, QueryFilters } from 'src/types';
import { AdsCategory } from '../../types';
import AppContext from 'src/AppContext';
import { nairaToKobo } from 'src/helpers';
import { isApolloError } from '@apollo/client';
import {
  IonButton,
  IonFab,
  IonFabButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonSkeletonText,
  useIonToast,
} from '@ionic/react';
import { postAdPath, postTradeRequestPath } from 'src/data/pageRoutes';
import TradeAd from '../TradeAd';
import { PlusIcon } from 'src/assets/icons';

interface AdsListProps {
  activeCategory: AdsCategory;
  filterValues: IObject;
  updateParentState: (newState: IObject) => void;
}

interface AdsListState {
  isFetchingAds: boolean;
  page: number;
  totalAds: number;
  ads: Ad[];
}

const AdsList: FC<AdsListProps> = ({
  activeCategory,
  filterValues,
  updateParentState,
}) => {
  const [present] = useIonToast();
  const { profile } = useContext(AppContext);

  const [state, setState] = useState<AdsListState>({
    isFetchingAds: false,
    page: 1,
    totalAds: 0,
    ads: [],
  });

  const handleStateUpdate = (newState: Partial<AdsListState>) =>
    setState((_state) => ({ ..._state, ...newState }));

  const handleFetchAds = (filters: QueryFilters) => {
    return fetchAds(filters);
  };

  const getAds = (refresh = false) => {
    handleStateUpdate({ isFetchingAds: refresh });
    updateParentState({ isFetchingData: true });
    let filters: QueryFilters = {
      limit: 10,
      offset: refresh ? 0 : state.page * 10,
      order_by: {
        created_at: 'desc',
      },
    };

    if (activeCategory === AdsCategory.MY_ADS) {
      filters = {
        ...filters,
        where: {
          ...filters.where,
          user_id: {
            _eq: profile?.id,
          },
        },
      };
    }

    if (activeCategory === AdsCategory.ALL_ADS) {
      filters = {
        ...filters,
        where: {
          ...filters.where,
          is_active: { _eq: true },
        },
      };
    }

    if (filterValues.amount) {
      filters = {
        ...filters,
        where: {
          ...filters.where,
          limit_min: { _lte: nairaToKobo(+filterValues.amount) },
          limit_max: { _gte: nairaToKobo(+filterValues.amount) },
        },
      };
    }

    if (filterValues.paymentSource) {
      filters = {
        ...filters,
        where: {
          ...filters.where,
          ad_payment_methods: {
            payment_method: { source_id: { _eq: filterValues.paymentSource } },
          },
        },
      };
    }

    if (filterValues.currency) {
      filters = {
        ...filters,
        where: {
          ...filters.where,
          source_currency: { _eq: filterValues.currency },
        },
      };
    }

    return handleFetchAds(filters)
      .then((res) => {
        handleStateUpdate({
          isFetchingAds: false,
          ads: refresh ? res.data.ad : [...state.ads, ...res.data.ad],
          totalAds: res.data.ad_aggregate.aggregate.count,
          page: refresh ? 1 : state.page + 1,
        });
        updateParentState({ isFetchingData: false });
      })
      .catch((err: any) => {
        isApolloError(err) &&
          present({
            message: err.graphQLErrors[0].message,
            duration: 3000,
            color: 'danger',
            position: 'bottom',
          });
        handleStateUpdate({ isFetchingAds: false });
        updateParentState({ isFetchingData: false });
      });
  };
  const renderAds = () => {
    if (state.isFetchingAds) {
      return (
        <>
          <IonItem className='listings__skeleton'>
            <IonSkeletonText
              className='listings__skeletonAvatar'
              slot='start'
              animated={true}
            ></IonSkeletonText>
            <IonLabel>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
            </IonLabel>
          </IonItem>
          <IonItem className='listings__skeleton'>
            <IonSkeletonText
              className='listings__skeletonAvatar'
              slot='start'
              animated={true}
            ></IonSkeletonText>
            <IonLabel>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
            </IonLabel>
          </IonItem>
          <IonItem className='listings__skeleton'>
            <IonSkeletonText
              className='listings__skeletonAvatar'
              slot='start'
              animated={true}
            ></IonSkeletonText>
            <IonLabel>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
            </IonLabel>
          </IonItem>
        </>
      );
    }
    if (!state.ads.length) {
      return (
        <IonItem className='listings__empty'>
          <IonLabel>
            <p>No ads match your search criteria</p>
            <p>
              Would you like to{' '}
              <IonButton
                routerLink={postTradeRequestPath}
                color={'primary'}
                fill='clear'
                className='link'
              >
                create a trade request
              </IonButton>{' '}
              instead?
            </p>
            <p>Merchants will be able to see your request and respond to it</p>
          </IonLabel>
        </IonItem>
      );
    }
    return (
      <>
        {state.ads.map((ad) => (
          <TradeAd key={ad.id} ad={ad} refreshList={() => getAds(true)} />
        ))}
      </>
    );
  };

  useEffect(() => {
    getAds(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory, filterValues]);

  return (
    <>
      <IonList className='listings__list' lines='full'>
        {renderAds()}
      </IonList>

      <IonInfiniteScroll
        onIonInfinite={async (ev) => {
          if (state.ads.length >= state.totalAds) {
            ev.target.complete();
            return;
          }
          await getAds();
          ev.target.complete();
        }}
      >
        <IonInfiniteScrollContent loadingSpinner='crescent'></IonInfiniteScrollContent>
      </IonInfiniteScroll>

      <IonFab slot='fixed' vertical='bottom' horizontal='end'>
        <IonFabButton routerLink={postAdPath} size='small' color='medium'>
          <PlusIcon />
          <span>Create Ad</span>
        </IonFabButton>
      </IonFab>
    </>
  );
};

export default AdsList;
