import {
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
} from '@ionic/react';
import { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import TransactionItem from '../TransactionItem';

import { fetchTransactions } from '../../api';

import './RecentTransactions.scss';
import { transactionsPath } from 'src/data/pageRoutes';
import { Transaction, Wallet } from 'src/types';

interface RecentTransactionsProps {
  wallets: Wallet[] | null;
}

interface RecentTransactionsState {
  transactions: Transaction[];
  isFetchingTransactions: boolean;
}

const RecentTransactions: FC<RecentTransactionsProps> = ({ wallets }) => {
  const history = useHistory();

  const [state, setState] = useState<RecentTransactionsState>({
    transactions: [],
    isFetchingTransactions: false,
  });

  const handleStateUpdate = (newState: Partial<RecentTransactionsState>) =>
    setState({ ...state, ...newState });

  const getTransactions = useCallback(async () => {
    handleStateUpdate({ isFetchingTransactions: true });
    try {
      const res = await fetchTransactions({
        limit: 3,
        order_by: {
          created_at: 'desc',
        },
      });
      handleStateUpdate({
        transactions: res.data.transaction,
        isFetchingTransactions: false,
      });
    } catch (e) {
      handleStateUpdate({
        transactions: [],
        isFetchingTransactions: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTransactions();
  }, [getTransactions, wallets]);

  const hasRecentTransactions = Boolean(state.transactions.length > 0);

  return (
    <IonList lines='full' className='recentTransactions'>
      <IonListHeader className='recentTransactions__header'>
        <IonLabel>Recent Transactions</IonLabel>
        {hasRecentTransactions && (
          <IonButton
            onClick={() => history.push(transactionsPath)}
            className='link'
          >
            See More
          </IonButton>
        )}
      </IonListHeader>
      {!hasRecentTransactions && !state.isFetchingTransactions ? (
        <IonItem>
          <IonLabel className='recentTransactions__empty'>
            No recent transactions
          </IonLabel>
        </IonItem>
      ) : (
        state.transactions.map((transaction) => (
          <TransactionItem transaction={transaction} key={transaction.id} />
        ))
      )}
    </IonList>
  );
};

export default RecentTransactions;
