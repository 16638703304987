import {
  IonButton,
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonSkeletonText,
  IonText,
  useIonRouter,
  useIonToast,
} from '@ionic/react';
import { FC, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Clipboard } from '@capacitor/clipboard';
import { format } from 'date-fns';

import { fetchTransaction } from '../../api';
import {
  currencyFormatter,
  getTransactionAmount,
  koboToNaira,
} from 'src/helpers';
import { Transaction, TransactionDirection } from 'src/types';
import { transactionsPath } from 'src/data/pageRoutes';

import Avatar from 'src/components/Avatar';

import { ArrowLeft, CopyIcon } from 'src/assets/icons';
import creditImg from '../../../../assets/img/credit.png';
import debitImg from '../../../../assets/img/debit.png';

import './TransactionDetails.scss';

interface FetchTransactionState {
  transaction: Transaction | null;
  isFetchingTransaction: boolean;
}

type TransactionDetailsProps = RouteComponentProps<{
  id: string;
}>;

const TransactionDetails: FC<TransactionDetailsProps> = ({ match }) => {
  const transactionId = match.params.id;

  const router = useIonRouter();
  const [present] = useIonToast();

  const [state, setState] = useState<FetchTransactionState>({
    transaction: null,
    isFetchingTransaction: true,
  });

  const handleStateUpdate = (newState: Partial<FetchTransactionState>) => {
    setState((_state) => ({ ..._state, ...newState }));
  };

  const getTransaction = useCallback(async () => {
    handleStateUpdate({ isFetchingTransaction: true });
    try {
      const res = await fetchTransaction(transactionId as string);
      handleStateUpdate({
        transaction: res.data.transaction_by_pk,
        isFetchingTransaction: false,
      });
    } catch (e) {
      handleStateUpdate({
        transaction: null,
        isFetchingTransaction: false,
      });
    }
  }, [transactionId]);

  const onBackClick = () => {
    router.push(transactionsPath);
  };

  const onCopyClick = async () => {
    await Clipboard.write({
      string: state.transaction?.provider_reference,
    });
    present({
      message: 'Transaction ID copied to clipboard',
      duration: 2000,
      position: 'top',
      color: 'success',
    });
  };

  const computedAmount = getTransactionAmount(state.transaction);

  useEffect(() => {
    getTransaction();
  }, [getTransaction]);

  return (
    <IonPage className='transaction'>
      <IonHeader className='transaction__header'>
        <div className='transaction__headerContent'>
          <IonButton
            fill='clear'
            color={'dark'}
            className='transaction__headerContentButton'
            onClick={onBackClick}
          >
            <ArrowLeft />
          </IonButton>

          <div className='transaction__headerContentInfo'>
            {state.isFetchingTransaction ? (
              <div className='transaction__skeleton'>
                <IonSkeletonText
                  className='transaction__skeletonAvatar'
                  slot='start'
                  animated={true}
                ></IonSkeletonText>
                <div>
                  <IonSkeletonText
                    animated={true}
                    className='transaction__skeletonAmount'
                  ></IonSkeletonText>
                  <IonSkeletonText
                    animated={true}
                    className='transaction__skeletonName'
                  ></IonSkeletonText>
                </div>
              </div>
            ) : (
              <>
                <Avatar slot='start'>
                  <img
                    src={
                      state.transaction?.direction ===
                      TransactionDirection.CREDIT
                        ? creditImg
                        : debitImg
                    }
                    alt='transaction direction'
                  />
                </Avatar>

                <IonText className='transaction__headerContentAmount'>
                  <p>
                    {currencyFormatter({
                      value: koboToNaira(computedAmount),
                      currency: state.transaction?.wallet.currency,
                    })}
                  </p>
                </IonText>
                <IonText className='transaction__headerContentName'>
                  <p>{state.transaction?.counterparty}</p>
                </IonText>
              </>
            )}
          </div>
        </div>
      </IonHeader>
      {state.isFetchingTransaction ? (
        <></>
      ) : (
        <IonContent className='transaction__content' fullscreen>
          <IonList>
            <p>Amount</p>
            <p>
              {currencyFormatter({
                value: koboToNaira(state.transaction?.amount as number),
                currency: state.transaction?.wallet.currency,
              })}
            </p>
          </IonList>
          <IonList>
            <p>Fee</p>
            <p>
              {currencyFormatter({
                value: koboToNaira(state.transaction?.fees!),
                currency: state.transaction?.wallet.currency,
              })}
            </p>
          </IonList>

          <IonList>
            <p>Date</p>
            <p>
              {format(
                new Date(state.transaction?.created_at as string),
                'dd MMM yyyy hh:mm a'
              )}
            </p>
          </IonList>

          {state.transaction?.data ? (
            <IonList>
              <p>Account number</p>
              <p>{state.transaction?.data.account_number}</p>
            </IonList>
          ) : (
            <></>
          )}

          <IonList>
            <p>Transaction ID</p>
            <p>
              <span>{state.transaction?.provider_reference}</span>
              <IonButton onClick={onCopyClick} color={'primary'} fill='clear'>
                <CopyIcon />
              </IonButton>
            </p>
          </IonList>

          <IonList>
            <p>Type</p>
            <p>{state.transaction?.type}</p>
          </IonList>

          <div className='transaction__contentMemo'>
            <IonText>
              <h4>TRANSACTION MEMO</h4>
            </IonText>
            <IonText>
              <p>{state.transaction?.memo}</p>
            </IonText>
          </div>
        </IonContent>
      )}
      {/* {state.transaction?.direction === TransactionDirection.DEBIT && (
        <IonFooter className='transaction__footer'>
          <IonButton expand='full' fill='outline'>
            Report Transaction
          </IonButton>
          <IonButton expand='full'>Download Reciept</IonButton>
        </IonFooter>
      )} */}
    </IonPage>
  );
};

export default TransactionDetails;
