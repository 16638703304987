import { gql } from '@apollo/client';

export const GET_BANK_ACCOUNTS = gql`
  query getBankAccounts {
    bank_account {
      bank_name
      bank_code
      account_number
      account_name
      wallet_id
      id
    }
  }
`;
