import { useCountdown } from 'usehooks-ts';
import { format, intervalToDuration } from 'date-fns';
import { FC, useEffect, useState } from 'react';

interface CountdownProps {
  endDate: string;
  onCountdownEnd: () => void;
}

const Countdown: FC<CountdownProps> = ({ endDate, onCountdownEnd }) => {
  const [secondsUntilEnd, setSecondsUntilEnd] = useState(() =>
    Math.floor((new Date(endDate).getTime() - new Date().getTime()) / 1000)
  );

  const [count, { startCountdown, stopCountdown, resetCountdown }] =
    useCountdown({
      countStart: secondsUntilEnd,
      intervalMs: 1000,
      countStop: 0,
    });

  useEffect(() => {
    const updatedSeconds = Math.floor(
      (new Date(endDate).getTime() - new Date().getTime()) / 1000
    );
    setSecondsUntilEnd(updatedSeconds);
    resetCountdown();
    startCountdown();
    return () => stopCountdown();
  }, [endDate, resetCountdown, startCountdown, stopCountdown]);

  useEffect(() => {
    if (count === 0) {
      stopCountdown();
      onCountdownEnd();
    }
  }, [count, stopCountdown, onCountdownEnd]);

  const duration = intervalToDuration({ start: 0, end: count * 1000 });
  const formattedTime = format(
    new Date(0, 0, 0, 0, duration.minutes, duration.seconds),
    'mm:ss'
  );

  return <>{formattedTime}</>;
};

export default Countdown;
