import { gql } from '@apollo/client';

export const GET_PROFILE = gql`
  query GetProfile($id: uuid!) {
    user_by_pk(id: $id) {
      created_at
      id
      firstname
      email
      lastname
      username
      pin
      email_verified
      wallets {
        currency
        created_at
        id
        updated_at
        free_transfer
        virtual_accounts {
          bank
          code
          created_at
          id
          integration_reference
          is_active
          max
          min
          name
          number
        }
        balance {
          amount
        }
      }
      escrows {
        id
        currency
        created_at
        updated_at
        balance {
          amount
        }
      }
      identities {
        type
        status
      }
    }
  }
`;

export const GET_PAYMENT_SOURCES = gql`
  query GetPaymentSources {
    payment_source {
      name
      is_active
      id
      fields
      supported_currencies
    }
  }
`;

export const GET_CURRENCIES = gql`
  query GetCurrencies {
    currency {
      code
      created_at
      flag
      id
      is_destination
      is_source
      name
      updated_at
    }
  }
`;

export const GET_BANKS = gql`
  query getBanks {
    getBanks {
      data {
        bank_code
        name
      }
    }
  }
`;
