import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonSkeletonText,
  IonSpinner,
  IonText,
  IonToolbar,
  useIonRouter,
  useIonToast,
  useIonViewDidEnter,
} from '@ionic/react';
import { FC, useContext, useState } from 'react';

import './PostAd.scss';
import { CloseIcon } from '../../../../assets/icons';
import { useHistory } from 'react-router';
import AddPaymentMethod from '../../modals/AddPaymentMethod/AddPaymentMethod';
import { AdsView, PaymentMethod } from 'src/types';
import { fetchPaymentMethods, postAd } from '../../api';
import { useFormik } from 'formik';
import { currencyFormatter, nairaToKobo } from 'src/helpers';
import { postAdValidationSchema } from '../../validators';
import AppContext from 'src/AppContext';
import { isApolloError } from '@apollo/client';
import { adsTabPath } from 'src/data/pageRoutes';
import AmountInput from '../../../../components/AmountInput';

interface PostAdState {
  isAddModalOpen: boolean;
  isFetchingPaymentMethods: boolean;
  paymentMethods: PaymentMethod[];
}

const PostAd: FC = () => {
  const history = useHistory();
  const router = useIonRouter();
  const [present] = useIonToast();
  const { profile, currencies } = useContext(AppContext);

  const onBackClick = () => {
    history.goBack();
  };

  const [state, setState] = useState<PostAdState>({
    isAddModalOpen: false,
    isFetchingPaymentMethods: false,
    paymentMethods: [],
  });

  const handleStateUpdate = (newState: Partial<PostAdState>) =>
    setState((_state) => ({ ..._state, ...newState }));

  const getPaymentMethods = async () => {
    try {
      handleStateUpdate({
        isFetchingPaymentMethods: true,
      });
      const res = await fetchPaymentMethods(profile?.id!);
      handleStateUpdate({
        paymentMethods: res.data.payment_method,
        isFetchingPaymentMethods: false,
      });
    } catch (e) {
      handleStateUpdate({ isFetchingPaymentMethods: false });
    }
  };

  useIonViewDidEnter(() => {
    getPaymentMethods();
  });

  const initialValues = {
    destination_currency: 'NGN',
    source_currency: 'USD',
    rate: '',
    limit_min: '',
    limit_max: '',
    payment_time: '15',
    payment_methods: [],
  };

  const onSubmit = async ({
    payment_methods,
    limit_max,
    limit_min,
    ...values
  }: typeof initialValues) => {
    const data = {
      ...values,
      limit_max: `${nairaToKobo(+limit_max)}`,
      limit_min: `${nairaToKobo(+limit_min)}`,
      is_active: true,
      user_id: profile?.id!,
      ad_payment_methods: {
        data: payment_methods.map((id: string) => ({
          payment_method_id: id,
        })),
      },
    };
    try {
      await postAd(data);
      present({
        message: 'Ad created successfully',
        duration: 3000,
        color: 'success',
        position: 'bottom',
      });
      router.push(`${adsTabPath}?view=${AdsView.Ads}`, 'back');
    } catch (e: any) {
      isApolloError(e) &&
        present({
          message: e.graphQLErrors[0].message,
          duration: 3000,
          color: 'danger',
          position: 'bottom',
        });
    }
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    isValid,
    isSubmitting,
  } = useFormik<typeof initialValues>({
    initialValues,
    onSubmit,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: postAdValidationSchema,
  });

  const destinationCurrencies = currencies.filter(
    (currency) => currency.is_destination
  );

  const renderPaymentMethods = () => {
    if (state.isFetchingPaymentMethods) {
      return (
        <div className='postListing__paymentMethodsLoader'>
          <IonSkeletonText animated={true}></IonSkeletonText>
          <IonSkeletonText animated={true}></IonSkeletonText>
          <IonSkeletonText animated={true}></IonSkeletonText>
        </div>
      );
    }
    if (!state.paymentMethods.length) {
      return (
        <IonText color={'tertiary'}>
          <p className='postListing__paymentMethodsEmpty'>
            You have not added any payment methods.
          </p>
        </IonText>
      );
    }
    return (
      <IonText color={'medium'} className='postListing__paymentMethodsInfo'>
        <p>Select up to 5 payment methods</p>
        <div className='postListing__paymentMethodsList'>
          {state.paymentMethods
            .filter((method) =>
              method.payment_source?.supported_currencies.includes(
                values.source_currency
              )
            )
            .map((method) => (
              <IonCheckbox
                key={method.id}
                mode='md'
                labelPlacement='end'
                justify='start'
                value={method.id}
                onIonChange={(e) => {
                  if (e.detail.checked) {
                    setFieldValue('payment_methods', [
                      ...values.payment_methods,
                      method.id,
                    ]);
                  } else {
                    setFieldValue(
                      'payment_methods',
                      values.payment_methods.filter((id) => id !== method.id)
                    );
                  }
                }}
              >
                {method.payment_source?.name} (
                {method.data[method.primary_field]})
              </IonCheckbox>
            ))}
        </div>
      </IonText>
    );
  };

  const handleAddPaymentMethodDismiss = (shouldRefresh?: boolean) => {
    if (shouldRefresh) {
      getPaymentMethods();
    }
    handleStateUpdate({ isAddModalOpen: false });
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <IonPage className='postListing'>
          <IonHeader className='postListing__header' translucent={true}>
            <div className='postListing__headerBg'></div>
            <div className='postListing__headerContent'>
              <IonButton
                fill='clear'
                color={'dark'}
                onClick={onBackClick}
                routerDirection='back'
                className='postListing__headerContentButton'
              >
                <CloseIcon />
              </IonButton>
              <IonText
                color={'dark'}
                className='postListing__headerContentTitle'
              >
                <h1>Post an Ad</h1>
              </IonText>
            </div>
          </IonHeader>
          <IonContent className='postListing__content' fullscreen={true}>
            <div className='postListing__currencies'>
              <div className='postListing__currency postListing__currency--holding'>
                <IonText>
                  <h2>You have</h2>
                </IonText>
                {destinationCurrencies.length > 1 ? (
                  <IonSelect
                    legacy={true}
                    placeholder=''
                    onIonBlur={handleBlur}
                    onIonChange={handleChange}
                    name='source_currency'
                    value={values.source_currency}
                  >
                    {destinationCurrencies.map((currency) => (
                      <IonSelectOption
                        key={currency.code}
                        value={currency.code}
                      >
                        {currency.code}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                ) : (
                  <IonText color='primary'>
                    <h2>{destinationCurrencies[0].code}</h2>
                  </IonText>
                )}
              </div>
              <div className='postListing__currency postListing__currency--trade'>
                <IonText>
                  <h2>to Swap for </h2>
                </IonText>
                <IonSelect
                  legacy={true}
                  placeholder=''
                  onIonBlur={handleBlur}
                  onIonChange={handleChange}
                  name='source_currency'
                  value={values.source_currency}
                >
                  {currencies
                    .filter((currency) => currency.is_source)
                    .map((currency) => (
                      <IonSelectOption
                        key={currency.code}
                        value={currency.code}
                      >
                        {currency.code}
                      </IonSelectOption>
                    ))}
                </IonSelect>
              </div>
            </div>
            <div className='postListing__rate'>
              <IonText color={'dark'} className='postListing__rateLabel'>
                <h3>Rate</h3>
              </IonText>
              <div className='postListing__rateInput'>
                <IonText className='postListing__rateCurrency' color='medium'>
                  <span>
                    {currencyFormatter({
                      style: 'currency',
                      value: 0,
                      minimumFractionDigits: 0,
                      maximumSignificantDigits: 1,
                      currency: values.destination_currency,
                    })
                      .replace(/\d/g, '')
                      .trim()}
                  </span>
                </IonText>
                <AmountInput
                  color='light'
                  placeholder='0.00'
                  name='rate'
                  className='rate'
                  showLabel={false}
                  showCurrency={false}
                  onValueChange={(val: number) => setFieldValue('rate', val)}
                />
                <IonText
                  className='postListing__rateCurrency--trade'
                  color='dark'
                >
                  <span>per {values.source_currency}</span>
                </IonText>
              </div>
              {/* <IonText color={'dark'} className='postListing__rateInfo'>
              <span>*highest rate ₦800</span>
            </IonText> */}
            </div>
            <div className='postListing__limits'>
              <IonText color={'dark'} className='postListing__limitsLabel'>
                <h3>Limits</h3>
              </IonText>
              <div className='postListing__limitsInput'>
                <AmountInput
                  color='light'
                  placeholder='Min'
                  name='limit_min'
                  className='limit limit--min'
                  showLabel={false}
                  showCurrency={true}
                  onValueChange={(val: number) =>
                    setFieldValue('limit_min', val)
                  }
                />
                <IonText
                  className='postListing__limitsSeparator'
                  color='medium'
                >
                  <span>~</span>
                </IonText>
                <AmountInput
                  color='light'
                  placeholder='Max'
                  name='limit_max'
                  className='limit limit--max'
                  showLabel={false}
                  showCurrency={true}
                  onValueChange={(val: number) =>
                    setFieldValue('limit_max', val)
                  }
                />
              </div>
            </div>
            <div className='postListing__paymentMethods'>
              <div className='postListing__paymentMethodsHeader'>
                <IonText
                  color={'dark'}
                  className='postListing__paymentMethodsTitle'
                >
                  <h3>Payment Methods</h3>
                </IonText>
                <IonButton
                  onClick={() => handleStateUpdate({ isAddModalOpen: true })}
                  fill='clear'
                  color={'primary'}
                  className='link'
                >
                  Add Payment Method
                </IonButton>
              </div>
              {renderPaymentMethods()}
            </div>
          </IonContent>
          <IonFooter className='postListing__footer'>
            <IonToolbar>
              <IonButton
                type='submit'
                className='postListing__postBtn'
                expand='full'
                disabled={!isValid || isSubmitting}
              >
                Create Ad
                {isSubmitting ? (
                  <IonSpinner name='crescent'></IonSpinner>
                ) : null}
              </IonButton>
            </IonToolbar>
          </IonFooter>
        </IonPage>
      </form>
      <AddPaymentMethod
        isOpen={state.isAddModalOpen}
        onDismiss={handleAddPaymentMethodDismiss}
      />
    </>
  );
};

export default PostAd;
