import { IonItem, IonLabel, IonText, useIonRouter } from '@ionic/react';
import { FC } from 'react';
import Avatar from '../../../../components/Avatar';
import './TransactionItem.scss';
import creditImg from './../../../../assets/img/credit.png';
import debitImg from './../../../../assets/img/debit.png';
import {
  currencyFormatter,
  getTransactionAmount,
  koboToNaira,
  timeToNow,
} from 'src/helpers';
import { transactionDetailsPath } from 'src/data/pageRoutes';
import { Transaction, TransactionDirection } from 'src/types';

interface TransactionItemProps {
  transaction: Transaction;
}

const TransactionItem: FC<TransactionItemProps> = ({ transaction }) => {
  const router = useIonRouter();

  const onItemClick = () => {
    router.push(`${transactionDetailsPath}/${transaction?.id}`);
  };

  const amount = getTransactionAmount(transaction);

  return (
    <IonItem
      className='transactionItem'
      detail={false}
      button
      onClick={onItemClick}
    >
      <Avatar slot='start'>
        <img
          src={
            transaction?.direction === TransactionDirection.CREDIT
              ? creditImg
              : debitImg
          }
          alt='transaction direction'
        />
      </Avatar>
      <IonLabel>
        <div className='transactionItem__header'>
          <IonText color='tertiary'>
            <span>{transaction?.type}</span>
          </IonText>
        </div>
        <div className='transactionItem__content'>
          <IonText>
            <span className='transactionItem__title'>
              {transaction?.counterparty}
            </span>
          </IonText>
          <IonText>
            <span className='transactionItem__amount'>
              {currencyFormatter({
                value: koboToNaira(amount),
                currency: transaction?.wallet.currency,
              })}
            </span>
          </IonText>
        </div>
        <div className='transactionItem__footer'>
          <IonText color='medium'>
            <span className='transactionItem__time'>
              {timeToNow(transaction?.created_at!)}
            </span>
          </IonText>
        </div>
      </IonLabel>
    </IonItem>
  );
};

export default TransactionItem;
