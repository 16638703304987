import { object, string } from 'yup';

export const registerValidationSchema = object({
  firstname: string().required('First name is required'),
  lastname: string().required('Last name is required'),
  email: string()
    .email('Please enter a valid email address')
    .required('Email is required'),
  password: string()
    .min(8, 'Your password must have at least 8 characters')
    .matches(/[A-Z]/, 'Must contain at least one uppercase')
    .matches(/[a-z]/, 'Must contain at least one lowercase')
    .matches(/[0-9]/, 'Must contain at least one digit')
    .matches(
      /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/,
      'Must contain at least one special case character'
    )
    .matches(/^[\s\S]{8,}$/, 'Must contain at least 8 characters')
    .required('You must enter a password'),
  referrer_code: string().optional(),
});

export const loginValidationSchema = object({
  email: string()
    .email('Please enter a valid email address')
    .required('Email is required'),
  password: string().required('Password is required'),
});

export const forgotPasswordValidationSchema = object({
  email: string()
    .email('Please enter a valid email address')
    .required('Email is required'),
});

export const resetPasswordValidationSchema = object({
  code: string().required('Code is required'),
  password: string()
    .min(8, 'Your password must have at least 8 characters')
    .matches(/[A-Z]/, 'Must contain at least one uppercase')
    .matches(/[a-z]/, 'Must contain at least one lowercase')
    .matches(/[0-9]/, 'Must contain at least one digit')
    .matches(
      /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/,
      'Must contain at least one special case character'
    )
    .matches(/^[\s\S]{8,}$/, 'Must contain at least 8 characters')
    .required('You must enter a password'),
});

export const otpValidationSchema = object({
  char1: string().required(),
  char2: string().required(),
  char3: string().required(),
  char4: string().required(),
});
