import { gql } from '@apollo/client';

export const ADD_PAYMENT_METHOD = gql`
  mutation AddPaymentMethod($input: payment_method_insert_input!) {
    insert_payment_method_one(object: $input) {
      id
      source_id
      data
    }
  }
`;

export const UPDATE_PAYMENT_METHOD = gql`
  mutation UpdatePaymentMethod($id: uuid!, $set: payment_method_set_input) {
    update_payment_method_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
    }
  }
`;

export const POST_AD = gql`
  mutation PostAd($input: ad_insert_input!) {
    insert_ad_one(object: $input) {
      id
    }
  }
`;

export const POST_TRADE_REQUEST = gql`
  mutation PostTradeRequest($input: trade_request_insert_input!) {
    insert_trade_request_one(object: $input) {
      id
    }
  }
`;
