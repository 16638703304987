import { gql } from '@apollo/client';

export const VALIDATE_PIN = gql`
  mutation validatePin($pin: String!) {
    validatePin(validatePinInput: { pin: $pin }) {
      data {
        access_token
        expires_in
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: uuid!, $user: user_set_input!) {
    update_user_by_pk(pk_columns: { id: $id }, _set: $user) {
      id
      email
      firstname
      lastname
      email_verified
    }
  }
`;
