import { gql } from '@apollo/client';

export const REGISTER = gql`
  mutation Register($authRegisterInput: AuthRegisterInput!) {
    register(authRegisterInput: $authRegisterInput) {
      data {
        id
        email
        lastname
        firstname
        expiresIn
        accessToken
      }
    }
  }
`;

export const LOGIN = gql`
  mutation Login($authLoginInput: AuthLoginInput!) {
    login(authLoginInput: $authLoginInput) {
      data {
        id
        email
        lastname
        firstname
        expiresIn
        accessToken
      }
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation VerifyEmail($authVerifyEmailInput: AuthVerifyEmailInput!) {
    confirmEmail(authVerifyEmailInput: $authVerifyEmailInput) {
      message
      status
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($authForgotPasswordInput: AuthForgotPasswordInput!) {
    forgotPassword(authForgotPasswordInput: $authForgotPasswordInput) {
      data {
        resetToken
      }
      message
      status
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($authResetPasswordInput: AuthResetPasswordInput!) {
    resetPassword(authResetPasswordInput: $authResetPasswordInput) {
      message
      status
    }
  }
`;
