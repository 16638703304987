import { FC, useContext, useEffect, useMemo } from 'react';

import './AddPaymentMethod.scss';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  useIonToast,
} from '@ionic/react';
import { CloseIcon } from '../../../../assets/icons';
import AppContext from 'src/AppContext';
import { IObject, PaymentMethod, PaymentSourceField } from 'src/types';
import { useFormik } from 'formik';
import { addPaymentMethodValidationSchema } from '../../validators';
import { addPaymentMethod, updatePaymentMethod } from '../../api';
import { isApolloError } from '@apollo/client';

interface AddPaymentMethodProps {
  isOpen: boolean;
  paymentMethod?: PaymentMethod | null;
  onDismiss: (shouldRefresh?: boolean) => void;
  visiblePaymentSources?: string[];
}

interface PaymentMethodFormValues {
  source_id: string;
  data: IObject;
}

const AddPaymentMethod: FC<AddPaymentMethodProps> = ({
  isOpen,
  onDismiss,
  paymentMethod,
  visiblePaymentSources,
}) => {
  const { paymentSources, profile } = useContext(AppContext);
  const [present] = useIonToast();

  const updateMethod = async (data: any) => {
    try {
      await updatePaymentMethod({ id: paymentMethod?.id!, data });
    } catch (e: any) {
      isApolloError(e) &&
        present({
          message: e.graphQLErrors[0].message,
          duration: 3000,
          color: 'danger',
          position: 'bottom',
        });
    }
  };

  const onSubmit = async (values: PaymentMethodFormValues) => {
    const data = {
      ...values,
      user_id: profile?.id!,
      primary_field: selectedSource?.fields.find(
        (field) => field.isPrimaryField
      )?.name!,
    };
    if (paymentMethod) {
      await updateMethod(data.data);
      present({
        message: 'Payment method updated successfully',
        duration: 3000,
        color: 'success',
        position: 'bottom',
      });
      dismissModal(true);
      return;
    }
    try {
      await addPaymentMethod(data);
      present({
        message: 'Payment method added successfully',
        duration: 3000,
        color: 'success',
        position: 'bottom',
      });
      dismissModal(true);
    } catch (e: any) {
      isApolloError(e) &&
        present({
          message: e.graphQLErrors[0].message,
          duration: 3000,
          color: 'danger',
          position: 'bottom',
        });
    }
  };

  const initialValues = {
    source_id: '',
    data: {},
  };

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    isValid,
    setValues,
  } = useFormik<PaymentMethodFormValues>({
    onSubmit,
    initialValues,
    validationSchema: addPaymentMethodValidationSchema,
  });

  useEffect(() => {
    setFieldValue('data', {});
  }, [values.source_id, setFieldValue]);

  useEffect(() => {
    if (paymentMethod) {
      const { source_id, data } = paymentMethod;

      setFieldValue('source_id', source_id);
      setTimeout(() => {
        setFieldValue('data', data);
      }, 500);
    }
  }, [paymentMethod, setFieldValue]);

  const renderField = (field: PaymentSourceField) => {
    switch (field.type) {
      case 'select':
        return (
          <IonItem>
            <IonLabel position='stacked'>{field.label}</IonLabel>
            <IonSelect
              onIonBlur={handleBlur}
              placeholder={field.label}
              onIonChange={handleChange}
              name={`data.${field.name}`}
              value={values.data[field.name]}
            >
              {field.options?.map((option) => (
                <IonSelectOption key={option.value} value={option.value}>
                  {option.label}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        );
      case 'text':
        return (
          <IonItem>
            <IonLabel position='stacked'>{field.label}</IonLabel>
            <IonInput
              type='text'
              onIonBlur={handleBlur}
              placeholder={field.label}
              onIonInput={handleChange}
              onIonChange={handleChange}
              name={`data.${field.name}`}
              value={values.data[field.name]}
            ></IonInput>
          </IonItem>
        );
      default:
        return null;
    }
  };

  const selectedSource = useMemo(
    () => paymentSources.find((source) => source.id === values.source_id),
    [paymentSources, values.source_id]
  );

  const displayedPaymentSources = useMemo(
    () =>
      visiblePaymentSources
        ? paymentSources.filter((source) =>
            visiblePaymentSources?.includes(source.id)
          )
        : paymentSources,
    [paymentSources, visiblePaymentSources]
  );

  const dismissModal = (shouldRefresh?: boolean) => {
    setValues(initialValues);
    onDismiss(shouldRefresh);
  };

  return (
    <IonModal
      isOpen={isOpen}
      className='addPaymentMethod'
      onDidDismiss={() => dismissModal()}
    >
      <div className='addPaymentMethod__header'>
        <IonButton
          className='addPaymentMethod__close'
          fill='clear'
          onClick={() => dismissModal()}
          color={'dark'}
        >
          <CloseIcon />
        </IonButton>
        <IonText color={'dark'} className='addPaymentMethod__title'>
          <h4>{paymentMethod ? 'Edit' : 'Add'} Payment Method</h4>
        </IonText>
      </div>
      <form className='addBankAccount__form' onSubmit={handleSubmit}>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position='stacked'>Payment Provider</IonLabel>
                <IonSelect
                  name='source_id'
                  value={values.source_id}
                  onIonBlur={handleBlur}
                  onIonChange={handleChange}
                  placeholder='Select Payment Provider'
                >
                  {displayedPaymentSources.map((paymentSource) => (
                    <IonSelectOption
                      key={paymentSource.id}
                      value={paymentSource.id}
                    >
                      {paymentSource.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>
          {selectedSource?.fields.map((field) => (
            <IonRow key={field.name}>
              <IonCol>{renderField(field)}</IonCol>
            </IonRow>
          ))}
          <IonRow>
            <IonCol></IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                type='submit'
                expand='full'
                className='addPaymentMethod__submit'
                disabled={!isValid || isSubmitting}
              >
                Save
                {isSubmitting ? (
                  <IonSpinner name='crescent'></IonSpinner>
                ) : null}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </form>
    </IonModal>
  );
};

export default AddPaymentMethod;
