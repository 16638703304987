import {
  IonButton,
  IonContent,
  IonPage,
  IonText,
  useIonToast,
} from '@ionic/react';
import { FC, useContext } from 'react';

import './AddMoney.scss';
import { homePath } from '../../../../data/pageRoutes';
import { CloseIcon, CopyIcon } from '../../../../assets/icons';

import { Clipboard } from '@capacitor/clipboard';

import AppContext from 'src/AppContext';

const AddMoney: FC = () => {
  const { profile } = useContext(AppContext);
  const [present] = useIonToast();
  const account = profile?.wallets[0].virtual_accounts[0];

  const onCopyClick = async () => {
    await Clipboard.write({
      string: account?.number,
    });
    present({
      message: 'Account number copied to clipboard',
      duration: 2000,
      position: 'top',
      color: 'success',
    });
  };

  return (
    <IonPage className='addMoney'>
      <IonContent className='addMoney__content' fullscreen={true}>
        <div className='addMoney__header'>
          <IonButton
            fill='clear'
            color={'dark'}
            routerLink={homePath}
            routerDirection='back'
            className='addMoney__headerButton'
          >
            <CloseIcon />
          </IonButton>
        </div>
        <IonText className='addMoney__headerTitle'>
          <h1>Add Money</h1>
        </IonText>
        <div className='addMoney__body'>
          <IonText className='addMoney__bodyDescription' color={'dark'}>
            <p>Fund your Trading Account with the account details below.</p>
          </IonText>
          <div className='addMoney__accountDetails'>
            <div className='addMoney__accountDetailsItem'>
              <IonText
                className='addMoney__accountDetailsItemLabel'
                color='medium'
              >
                <p>Account Name</p>
              </IonText>
              <IonText
                className='addMoney__accountDetailsItemValue'
                color={'dark'}
              >
                <p>{account?.name}</p>
              </IonText>
              <div className='addMoney__accountDetailsItem'>
                <IonText
                  className='addMoney__accountDetailsItemLabel'
                  color='medium'
                >
                  <p>Account Number</p>
                </IonText>
                <IonText
                  className='addMoney__accountDetailsItemValue'
                  color={'dark'}
                >
                  <p>
                    <span>{account?.number}</span>
                    <IonButton onClick={onCopyClick} fill='clear'>
                      <CopyIcon />
                    </IonButton>
                  </p>
                </IonText>
              </div>
              <div className='addMoney__accountDetailsItem'>
                <IonText
                  className='addMoney__accountDetailsItemLabel'
                  color='medium'
                >
                  <p>BANK</p>
                </IonText>
                <IonText
                  className='addMoney__accountDetailsItemValue'
                  color={'dark'}
                >
                  <p>{account?.bank}</p>
                </IonText>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AddMoney;
