import { apiService } from 'src/helpers';
import { ADD_BANK_ACCOUNT, VERIFY_BANK, WITHDRAW } from './mutations';
import { GET_BANK_ACCOUNTS } from './queries';
import { BankAccount } from './types/generics';
import {
  RDInsertBankAccount,
  RDVerifyBank,
  RDWithdraw,
} from './types/requests';

export const fetchBanks = () => {
  return apiService.query<{
    bank_account: BankAccount[];
  }>({
    query: GET_BANK_ACCOUNTS,
    fetchPolicy: 'network-only',
  });
};

export const verifyBank = (data: RDVerifyBank) => {
  return apiService.mutate({
    mutation: VERIFY_BANK,
    variables: { data },
  });
};

export const addBankAccount = (data: RDInsertBankAccount) => {
  return apiService.mutate({
    mutation: ADD_BANK_ACCOUNT,
    variables: { data },
  });
};

export const withdraw = (data: RDWithdraw, token: string) => {
  return apiService.mutate({
    mutation: WITHDRAW,
    variables: { data },
    context: {
      headers: {
        'use-pin-token': token,
      },
    },
  });
};
