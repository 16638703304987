import { FC, PropsWithChildren } from 'react';

export type HSL = [h: number, s: number, l: number];

export type WithData<T> = {
  data: T;
};

export interface IObject<T = any> {
  [x: string]: T;
}

export type FunctionalComponent<T = any> = FC<PropsWithChildren<T>>;

export interface APIResponse<T> {
  data: T;
  message: string;
}

export enum TradeStatus {
  UNPAID = 'UNPAID',
  PAID = 'PAID',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  IN_DISPUTE = 'IN_DISPUTE',
}

export enum NotificationType {
  AD = 'AD',
  CHAT = 'CHAT',
  TRADE = 'TRADE',
  SYSTEM = 'SYSTEM',
  TRANSACTION = 'TRANSACTION',
  TRADE_REQUEST = 'TRADE_REQUEST',
}

export interface NotificationActionType {
  id: string;
  type: NotificationType;
}
