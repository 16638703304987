import {
  IonButton,
  IonItem,
  IonLabel,
  IonText,
  useIonRouter,
} from '@ionic/react';
import { FC, useContext } from 'react';
import AppContext from 'src/AppContext';
import { tradeChatPath, tradeDetailsPath } from 'src/data/pageRoutes';
import { Trade } from 'src/types';
import {
  currencyFormatter,
  formatTradeStatus,
  koboToNaira,
  timeToNow,
} from 'src/helpers';
import { ChatIcon } from '../../../../assets/icons';

import './TradeItem.scss';

interface Props {
  trade: Trade;
}

const TradeItem: FC<Props> = (props) => {
  const { trade } = props;
  const { user } = useContext(AppContext);
  const router = useIonRouter();

  const onChatClick = (
    e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    router.push(`${tradeChatPath}/${trade.chats[0].id}`);
  };

  const onItemClick = () => {
    router.push(`${tradeDetailsPath}/${trade.id}`);
  };

  return (
    <IonItem className='tradeItem' detail={false} button onClick={onItemClick}>
      <IonLabel>
        <div className='tradeItem__header'>
          <IonText>
            <span className='tradeItem__currency'>
              {trade.ad.source_currency} {'>'} {trade.ad.destination_currency}{' '}
              &nbsp;| &nbsp;#{trade.h_id}
            </span>
          </IonText>
          <IonText color='tertiary'>
            <span className='tradeItem__status'>
              {formatTradeStatus(trade?.status)}
            </span>
          </IonText>
        </div>
        <div className='tradeItem__content'>
          <div className='tradeItem__rate'>
            <IonText color='medium'>
              <span>Price &nbsp;</span>
              <span>
                - &nbsp;
                {currencyFormatter({
                  value: trade?.rate,
                  currency: trade.ad.destination_currency,
                })}
              </span>
            </IonText>
            <IonText color='medium'>
              <span>Quantity &nbsp;</span>
              <span>
                - &nbsp;
                {currencyFormatter({
                  value: trade.merchant_receivable_amount,
                  currency: trade.ad.source_currency,
                })}
              </span>
            </IonText>
          </div>
          <IonText>
            <span className='tradeItem__amount'>
              {currencyFormatter({
                value: koboToNaira(trade.amount_in_escrow),
                currency: trade.ad.destination_currency,
              })}
            </span>
          </IonText>
        </div>
        <div className='tradeItem__footer'>
          <IonButton
            fill='clear'
            className='link tradeItem__chat'
            onClick={onChatClick}
          >
            <ChatIcon />

            <span>
              {user?.id === trade.ad.user.id
                ? trade.user.firstname
                : trade.ad.user.firstname}
            </span>
          </IonButton>
          <IonText color='medium'>
            <span className='tradeItem__time'>
              {timeToNow(trade?.updated_at!)}
            </span>
          </IonText>
        </div>
      </IonLabel>
    </IonItem>
  );
};

export default TradeItem;
