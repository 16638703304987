import {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonLabel,
  IonPage,
  IonSkeletonText,
  IonSpinner,
  IonText,
  IonToolbar,
  useIonRouter,
  useIonToast,
} from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import {
  ChatIcon,
  CircleFilled,
  CloseIcon,
  CopyIcon,
  LockIcon,
} from 'src/assets/icons';
import Avatar from 'src/components/Avatar/Avatar';

import cx from 'classnames';
import { tradeChatPath, tradesPath } from 'src/data/pageRoutes';
import { fetchTrade, updateTrade } from '../../api';
import { Trade, TradeStatus } from 'src/types';
import AppContext from 'src/AppContext';
import { isApolloError } from '@apollo/client';
import { capitalize } from 'lodash';
import { currencyFormatter, formatTradeStatus } from 'src/helpers';
import If from 'src/components/If';
import ConfirmTradeAction from '../../modals/ConfirmTradeAction';
import Countdown from 'src/components/Countdown';

import { Clipboard } from '@capacitor/clipboard';

import './TradeDetails.scss';
import { isAfter, isEqual } from 'date-fns';

interface TradeState {
  isConfirmModalOpen: boolean;
  isFetchingTrade: boolean;
  trade: Trade | null;
  tradeSubscription: any;
  isUpdatingTrade: boolean;
  updatingStatus: TradeUpdateStatus;
}

type TradeUpdateStatus =
  | TradeStatus.CANCELLED
  | TradeStatus.PAID
  | TradeStatus.COMPLETED
  | TradeStatus.IN_DISPUTE
  | null;

type TradeDetailsProps = RouteComponentProps<{
  id: string;
}>;

const TradeDetails: FC<TradeDetailsProps> = ({ match }) => {
  const router = useIonRouter();
  const [present] = useIonToast();
  const { id: tradeId } = match.params;
  const { user, authorizeAction } = useContext(AppContext);

  const [state, setState] = useState<TradeState>({
    isConfirmModalOpen: false,
    isFetchingTrade: true,
    trade: null,
    tradeSubscription: null,
    isUpdatingTrade: false,
    updatingStatus: null,
  });

  const handleStateUpdate = (newState: Partial<TradeState>) =>
    setState((_state) => ({ ..._state, ...newState }));

  const onCloseClick = () => {
    router.push(tradesPath);
  };

  const tradeStatuses = [
    {
      label: 'Order placed',
      value: TradeStatus.UNPAID,
    },
    {
      label: 'Payment Made',
      value: TradeStatus.PAID,
    },
    {
      label: 'Merchant Confirming Payment',
      value: TradeStatus.PAID,
    },
    {
      label: 'Funds Release',
      value: TradeStatus.COMPLETED,
    },
  ];

  const doneStatuses = useMemo(() => {
    const statusIndex = tradeStatuses.findIndex(
      (status) => status.value === state.trade?.status
    );

    return tradeStatuses.slice(0, statusIndex + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.trade?.status]);

  const hasPassedStatus = (status: TradeStatus) => {
    return doneStatuses.some((doneStatus) => doneStatus.value === status);
  };

  const counterParty = useMemo(() => {
    return user?.id === state.trade?.ad.user.id
      ? state.trade?.user
      : state.trade?.ad.user;
  }, [state.trade, user?.id]);

  const isMerchant = user?.id === state.trade?.ad.user.id;
  const isActiveTrade =
    !!state.trade &&
    ![TradeStatus.COMPLETED, TradeStatus.CANCELLED].includes(
      state.trade.status
    );

  const paymentDetails = useMemo(() => {
    if (!state.trade) {
      return [];
    }
    const keys = Object.keys(state.trade.payment_method.data);
    return keys.map((key) => ({
      label: key,
      value: state.trade?.payment_method.data![key],
    }));
  }, [state.trade]);

  const onCopyClick = async (value: string) => {
    await Clipboard.write({
      string: value,
    });
    present({
      message: 'Copied!',
      duration: 2000,
      position: 'top',
      color: 'success',
    });
  };

  const renderPaymentDetails = () => {
    if (state.trade?.status === TradeStatus.UNPAID) {
      return (
        <div className='trade__payment'>
          <IonText className='trade__paymentMessage'>
            <p>
              {isMerchant ? 'You will receive' : 'Send'}{' '}
              <IonText color={'primary'}>
                <span>
                  {currencyFormatter({
                    value: state.trade?.merchant_receivable_amount as number,
                    currency: state.trade?.ad.source_currency,
                  })}
                </span>
              </IonText>{' '}
              {isMerchant ? 'from' : 'to'} the payment details below
            </p>
          </IonText>
          <IonText className='trade__paymentMethod' color={'medium'}>
            <p>{state.trade?.payment_method.payment_source?.name}</p>
          </IonText>
          {paymentDetails.map((detail, index) => (
            <div
              className='trade__paymentDetails'
              key={`${detail.label}-${index}`}
            >
              <IonText>
                <h6>{detail.label.split('_').join(' ')}</h6>
              </IonText>
              <IonText>
                <p>
                  {detail.value}
                  <IonButton
                    color={'primary'}
                    onClick={() => onCopyClick(detail.value)}
                    fill='clear'
                  >
                    <CopyIcon />
                  </IonButton>
                </p>
              </IonText>
            </div>
          ))}
        </div>
      );
    }

    if (state.trade?.status === TradeStatus.PAID) {
      return (
        <>
          <IonText
            color={'medium'}
            className='trade__paymentMessage trade__paymentMessage--paid'
          >
            <p>
              {isMerchant
                ? 'Customer has made payment. Please confirm payment to release funds'
                : `
                  Please wait while merchant confirms payment and funds will
                  be released immediately to your payout wallet
                `}
            </p>
          </IonText>
        </>
      );
    }
  };

  const onUpdateTrade = (status: TradeStatus) => {
    const trade = { ...state.trade! };
    handleStateUpdate({ isConfirmModalOpen: false });
    authorizeAction(async (authorizationToken: string) => {
      handleStateUpdate({ isUpdatingTrade: true });
      try {
        await updateTrade({ id: trade.id, status }, authorizationToken);
      } catch (e: any) {
        console.log(e);
        isApolloError(e) &&
          present({
            message: e.graphQLErrors[0].message,
            duration: 3000,
            color: 'danger',
            position: 'bottom',
          });
        handleStateUpdate({ isUpdatingTrade: false, updatingStatus: null });
      }
    });
  };

  const openConfirmModal = (status: TradeUpdateStatus) => {
    handleStateUpdate({ isConfirmModalOpen: true, updatingStatus: status });
  };

  const renderActions = () => {
    if (state.trade?.status === TradeStatus.UNPAID) {
      return (
        <IonToolbar>
          <div
            className={cx([
              'trade__actions',
              {
                'trade__actions--full': isMerchant,
              },
            ])}
          >
            <If condition={!isMerchant}>
              <IonButton
                onClick={() => openConfirmModal(TradeStatus.CANCELLED)}
                expand='full'
                color={'medium'}
                fill={'solid'}
                disabled={state.isUpdatingTrade}
              >
                Cancel
                {state.isUpdatingTrade &&
                  state.updatingStatus === TradeStatus.CANCELLED && (
                    <IonSpinner name='crescent' />
                  )}
              </IonButton>
            </If>
            <IonButton
              onClick={() => openConfirmModal(TradeStatus.PAID)}
              expand='full'
              color={'primary'}
              fill={'solid'}
              disabled={isMerchant || state.isUpdatingTrade}
            >
              {isMerchant ? 'Awaiting Payment' : 'Confirm Payment'}
              {state.isUpdatingTrade &&
                state.updatingStatus === TradeStatus.PAID && (
                  <IonSpinner name='crescent' />
                )}
            </IonButton>
          </div>
        </IonToolbar>
      );
    }
    if (state.trade?.status === TradeStatus.PAID) {
      return (
        <IonToolbar>
          <div
            className={cx([
              'trade__actions',
              { 'trade__actions--full': !isMerchant },
            ])}
          >
            {isMerchant ? (
              <>
                <IonButton
                  expand='full'
                  fill={'solid'}
                  color={'medium'}
                  disabled={state.isUpdatingTrade || !canRaiseDispute}
                  onClick={() => openConfirmModal(TradeStatus.IN_DISPUTE)}
                >
                  Raise Dispute
                  {state.isUpdatingTrade &&
                    state.updatingStatus === TradeStatus.IN_DISPUTE && (
                      <IonSpinner name='crescent' />
                    )}
                </IonButton>
                <IonButton
                  onClick={() => openConfirmModal(TradeStatus.COMPLETED)}
                  expand='full'
                  color={'primary'}
                  fill={'solid'}
                  disabled={state.isUpdatingTrade}
                >
                  Confirm Payment
                  {state.isUpdatingTrade &&
                    state.updatingStatus === TradeStatus.COMPLETED && (
                      <IonSpinner name='crescent' />
                    )}
                </IonButton>
              </>
            ) : (
              <IonButton
                expand='full'
                fill={'solid'}
                color={'medium'}
                disabled={state.isUpdatingTrade || !canRaiseDispute}
                onClick={() => openConfirmModal(TradeStatus.IN_DISPUTE)}
              >
                Raise Dispute
                {state.isUpdatingTrade &&
                  state.updatingStatus === TradeStatus.IN_DISPUTE && (
                    <IonSpinner name='crescent' />
                  )}
              </IonButton>
            )}
          </div>
        </IonToolbar>
      );
    }
    if (state.trade?.status === TradeStatus.IN_DISPUTE) {
      if (!isMerchant) {
        return (
          <IonToolbar>
            <div className='trade__actions trade__actions--full'>
              <IonButton
                expand='full'
                fill={'solid'}
                color={'medium'}
                disabled={state.isUpdatingTrade}
                onClick={() => openConfirmModal(TradeStatus.CANCELLED)}
              >
                Cancel Payment
                {state.isUpdatingTrade &&
                  state.updatingStatus === TradeStatus.CANCELLED && (
                    <IonSpinner name='crescent' />
                  )}
              </IonButton>
            </div>
          </IonToolbar>
        );
      }
      return (
        <IonToolbar>
          <div className='trade__actions trade__actions--full'>
            <IonButton
              expand='full'
              fill={'solid'}
              color={'medium'}
              disabled={state.isUpdatingTrade}
              onClick={() => openConfirmModal(TradeStatus.COMPLETED)}
            >
              Confirm Payment
              {state.isUpdatingTrade &&
                state.updatingStatus === TradeStatus.COMPLETED && (
                  <IonSpinner name='crescent' />
                )}
            </IonButton>
          </div>
        </IonToolbar>
      );
    }
  };

  const onConfirmDismiss = (isConfirmed: boolean) => {
    if (!isConfirmed) {
      handleStateUpdate({ isConfirmModalOpen: false, updatingStatus: null });
      return;
    }
    onUpdateTrade(state.updatingStatus!);
  };

  const canRaiseDispute = useMemo(() => {
    if (!state.trade) {
      return false;
    }
    if (state.trade.status !== TradeStatus.PAID) {
      return false;
    }
    return (
      isAfter(new Date(), new Date(state.trade.auto_dispute_time!)) ||
      isEqual(new Date(), new Date(state.trade.auto_dispute_time!))
    );
  }, [state.trade]);

  const onCountdownEnd = useCallback(() => {
    if (state.trade?.status === TradeStatus.UNPAID) {
      handleStateUpdate({
        trade: { ...state.trade, status: TradeStatus.CANCELLED },
      });
    }
  }, [state.trade]);

  const getTrade = useCallback(async () => {
    try {
      const subscription = fetchTrade(tradeId).subscribe((res) => {
        handleStateUpdate({
          isFetchingTrade: false,
          trade: res.data?.trade_by_pk,
        });
      });
      handleStateUpdate({
        isFetchingTrade: true,
        tradeSubscription: subscription,
      });
    } catch (error) {
      console.log(error);
      handleStateUpdate({ isFetchingTrade: false });
    }
  }, [tradeId]);

  useEffect(() => {
    getTrade();
  }, [tradeId, getTrade]);

  useEffect(
    () => {
      return () => {
        state.tradeSubscription?.unsubscribe();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <IonPage className='trade'>
      <IonHeader className='trade__header' translucent={true}>
        <div className='trade__headerBg'></div>
        <div className='trade__headerContent'>
          <IonButton
            fill='clear'
            color={'dark'}
            onClick={onCloseClick}
            className='trade__headerContentButton'
          >
            <CloseIcon />
          </IonButton>
          <div className='trade__headerContentTitleContainer'>
            <IonText color={'dark'} className='trade__headerContentTitle'>
              <h1>Swap Order</h1>
            </IonText>
            <IonButton
              fill='clear'
              disabled={state.isFetchingTrade}
              color={'dark'}
              routerLink={`${tradeChatPath}/${state.trade?.chats[0]?.id}`}
              className='trade__chatButton'
            >
              <ChatIcon />
            </IonButton>
          </div>
        </div>
      </IonHeader>
      <IonContent fullscreen={true} className='trade__content'>
        {state.isFetchingTrade && !state.trade ? (
          <>
            <div className='trade__skeleton'>
              <IonSkeletonText
                className='trade__skeletonAvatar'
                slot='start'
                animated={true}
              ></IonSkeletonText>
              <IonLabel>
                <IonSkeletonText animated={true}></IonSkeletonText>
              </IonLabel>
            </div>

            <div className='trade__skeleton--last'>
              <IonLabel>
                <IonSkeletonText animated={true}></IonSkeletonText>
              </IonLabel>
            </div>
          </>
        ) : (
          <>
            <div className='trade__top'>
              <div className='trade__peer'>
                <Avatar
                  title={counterParty?.firstname}
                  className='trade__peerAvatar'
                />
                <div className='trade__peerInfo'>
                  <IonText color='dark' className='trade__peerName'>
                    <p>{capitalize(counterParty?.firstname)}</p>
                  </IonText>
                  <IonText color='medium' className='trade__peerStats'>
                    <p>
                      {state.trade?.ad.user.completed_trades?.total || 0}{' '}
                      Completed Trade
                      {state.trade?.ad.user.completed_trades?.total! !== 1
                        ? 's'
                        : ''}
                    </p>
                  </IonText>
                </div>
              </div>
              <IonText className='trade__status' color={'primary'}>
                <p>{formatTradeStatus(state.trade?.status)}</p>
              </IonText>
            </div>
            <If condition={isActiveTrade}>
              <If
                condition={
                  [TradeStatus.UNPAID, TradeStatus.PAID].includes(
                    state.trade?.status!
                  ) && !canRaiseDispute
                }
              >
                <>
                  <div className='trade__timer'>
                    <IonText color='medium' className='trade__timerText'>
                      <p>
                        {state.trade?.status === TradeStatus.UNPAID
                          ? 'Time left to pay'
                          : 'Time left to confirm payment'}
                      </p>
                    </IonText>
                    <IonText color='primary' className='trade__timerTime'>
                      <p>
                        <Countdown
                          endDate={
                            state.trade?.status === TradeStatus.UNPAID
                              ? state.trade.auto_cancellation_time!
                              : state.trade?.auto_dispute_time!
                          }
                          onCountdownEnd={onCountdownEnd}
                        />
                      </p>
                    </IonText>
                  </div>
                  <IonText className='trade__timerMessage' color='medium'>
                    <p>
                      {state.trade?.status === TradeStatus.UNPAID
                        ? 'Trade will be cancelled if payment is not made in time'
                        : `You'll be able to raise a dispute if payment is not confirmed in time`}
                    </p>
                  </IonText>
                </>
              </If>
            </If>
            <div className='trade__acknowledgement'>
              <IonText className='trade__acknowledgementText' color='tertiary'>
                <p>
                  Funds are held in escrow to ensure security of the transaction
                </p>
              </IonText>
              <div className='trade__acknowledgementStamp'>
                <IonText color={'success'}>
                  <LockIcon />
                </IonText>
                <IonText color='medium'>
                  <p>Secured by</p>
                </IonText>
                <IonText color='primary'>
                  <p>Safeswap</p>
                </IonText>
              </div>
            </div>
            <div className='trade__timeline'>
              {tradeStatuses.map((status, index) => {
                return (
                  <Fragment key={`${status.value}-${index}`}>
                    <div className='trade__timelineItem'>
                      <IonText
                        className={cx([
                          'trade__timelineDot',
                          `trade__timelineDot--${status.label
                            .split(' ')
                            .join('_')
                            .toLowerCase()}`,
                          {
                            'trade__timelineDot--active':
                              status.value === state.trade?.status,
                            'trade__timelineDot--done': hasPassedStatus(
                              status.value
                            ),
                          },
                        ])}
                      >
                        <CircleFilled />
                        <span></span>
                      </IonText>
                      <IonText className='trade__timelineText' color={'medium'}>
                        <p>{status.label}</p>
                      </IonText>
                    </div>
                    <If condition={index !== tradeStatuses.length - 1}>
                      <span className='trade__timelineSpacer'></span>
                    </If>
                  </Fragment>
                );
              })}
            </div>
            {isActiveTrade ? (
              <>{renderPaymentDetails()}</>
            ) : (
              <>
                <div className='trade__completedText'>
                  <IonText color={'medium'}>
                    <p>
                      {state.trade?.status === TradeStatus.CANCELLED
                        ? 'Trade has been cancelled'
                        : 'Trade is completed and funds have been released.'}
                    </p>
                  </IonText>
                </div>
              </>
            )}
          </>
        )}
      </IonContent>
      {isActiveTrade && (
        <IonFooter className='trade__footer'>{renderActions()}</IonFooter>
      )}
      <ConfirmTradeAction
        confirmStatus={state.updatingStatus}
        isOpen={state.isConfirmModalOpen}
        onDismiss={onConfirmDismiss}
      />
    </IonPage>
  );
};

export default TradeDetails;
