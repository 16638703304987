import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonItem,
  IonPage,
  IonPopover,
  IonText,
  useIonRouter,
  useIonToast,
} from '@ionic/react';
import { FC, useContext, useEffect, useState } from 'react';
import { Share } from '@capacitor/share';

import { KebabIcon, PlusIcon, WhatsappIcon } from '../../../../assets/icons';
// import TradeItem from '../../components/TradeItem';

import {
  addMoneyPath,
  paymentMethodsPath,
  postAdPath,
  postTradeRequestPath,
  withdrawPath,
} from '../../../../data/pageRoutes';

import AppContext from 'src/AppContext';

import { currencyFormatter, koboToNaira } from 'src/helpers';
import RecentTransactions from '../../components/RecentTransactions';
import RecentAds from '../../components/RecentAds';

import { subscribeEscrows, subscribeWallets } from '../../api';

import { Escrow, Wallet } from 'src/types';

import './Home.scss';
import { WHATSAPP_GROUP_LINK } from 'src/data/env';
import SetUsernameModal from '../../modals/SetUsernameModal';
import { Clipboard } from '@capacitor/clipboard';

declare global {
  interface Window {
    fcWidget: any;
  }
}
interface HomeState {
  wallets: Wallet[] | null;
  escrows: Escrow[] | null;
  subscriptions: any[];
  isUsernameModalOpen: boolean;
}

const Home: FC = () => {
  const { profile, user, logout, refreshProfile } = useContext(AppContext);
  const [present] = useIonToast();
  const router = useIonRouter();

  const [state, setState] = useState<HomeState>({
    wallets: null,
    escrows: null,
    subscriptions: [],
    isUsernameModalOpen: false,
  });

  const handleStateUpdate = (newState: Partial<HomeState>) =>
    setState((_state) => ({ ..._state, ...newState }));

  const subscribeToWallets = () => {
    if (
      state.subscriptions.find((sub) => sub.type === 'wallet')?.subscription
    ) {
      return;
    }
    const subscription = subscribeWallets().subscribe((wallets) => {
      handleStateUpdate({ wallets: wallets.data?.wallet });
      refreshProfile();
    });
    handleStateUpdate({
      subscriptions: [...state.subscriptions, { subscription, type: 'wallet' }],
    });
  };

  const subscribeToEscrows = () => {
    if (
      state.subscriptions.find((sub) => sub.type === 'escrows')?.subscription
    ) {
      return;
    }
    const subscription = subscribeEscrows().subscribe((escrows) => {
      handleStateUpdate({ escrows: escrows.data?.escrow });
    });
    handleStateUpdate({
      subscriptions: [
        ...state.subscriptions,
        { subscription, type: 'escrows' },
      ],
    });
  };

  useEffect(() => {
    subscribeToWallets();
    subscribeToEscrows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLogout = () => {
    logout();
  };

  // const onHelpClick = () => {
  //   if (window.fcWidget) {
  //     // To set user name
  //     window.fcWidget.user.setFirstName(profile?.firstname);

  //     // To set user email
  //     window.fcWidget.user.setEmail(profile?.email);
  //     window.fcWidget.open();
  //   }
  // };

  const onShareLinkClick = async (username: string | null) => {
    if (username) {
      const shareLink = `https://link.safeswap.app/${username}`;
      const canShare = await Share.canShare();
      if (!canShare.value) {
        await Clipboard.write({
          string: shareLink,
        });
        present({
          message: 'Link Copied!',
          duration: 2000,
          position: 'top',
          color: 'success',
        });
        return;
      }
      try {
        await Share.share({
          title: 'Safeswap',
          text: 'Check out my Safeswap profile',
          url: shareLink,
          dialogTitle: 'Share your Safeswap profile',
        });
      } catch (e) {
        console.error(e);
      }
    } else {
      handleStateUpdate({ isUsernameModalOpen: true });
    }
  };

  const onUsernameModalDismiss = async (isUpdated: boolean) => {
    if (isUpdated) {
      const res = await refreshProfile();
      setTimeout(
        () => onShareLinkClick(res?.data.user_by_pk.username || null),
        500
      );
      handleStateUpdate({ isUsernameModalOpen: false });
      return;
    }
    handleStateUpdate({ isUsernameModalOpen: false });
  };

  return (
    <IonPage className='home'>
      <IonHeader className='home__header' translucent={true}>
        <div className='home__headerBg'></div>
        <div className='home__headerContent'>
          <div className='home__headerTop'>
            <div className='home__title'>
              <IonText>
                <h1>Hi,</h1>
              </IonText>
              <IonText>
                <h1>{user?.firstname}</h1>
              </IonText>
            </div>
            <IonButton
              className='home__menuBtn'
              fill='clear'
              id='logout-popover-button'
            >
              <KebabIcon />
            </IonButton>
            <IonPopover
              className='home__menuPopover'
              trigger='logout-popover-button'
              dismissOnSelect={true}
            >
              <IonItem
                onClick={() => router.push(paymentMethodsPath)}
                button={true}
                detail={false}
                lines='none'
              >
                Payment Methods
              </IonItem>
              <IonItem
                onClick={() => onShareLinkClick(profile?.username || null)}
                button={true}
                detail={false}
                lines='none'
              >
                Share link
              </IonItem>
              {/* <IonItem
                onClick={onHelpClick}
                button={true}
                detail={false}
                lines='none'
              >
                Contact Support
              </IonItem> */}
              <IonItem
                onClick={onLogout}
                button={true}
                detail={false}
                lines='none'
              >
                Log out
              </IonItem>
            </IonPopover>
          </div>
          <div className='home__wallets'>
            <div className='home__wallet'>
              <IonText>
                <p className='home__walletName'>WALLET</p>
              </IonText>
              <IonText>
                <p className='home__walletBalance'>
                  {currencyFormatter({
                    value: state.wallets
                      ? koboToNaira(state.wallets[0].balance.amount)
                      : koboToNaira(
                          profile?.wallets[0].balance.amount as number
                        ) || 0,
                    currency: 'NGN',
                    currencyDisplay: 'narrowSymbol',
                    notation: 'compact',
                    maximumSignificantDigits: 3,
                    minimumFractionDigits: 2,
                  })}
                </p>
              </IonText>
              <IonText>
                <p className='home__walletBalanceFull'>
                  {currencyFormatter({
                    value: state.wallets
                      ? koboToNaira(state.wallets[0].balance.amount)
                      : koboToNaira(
                          profile?.wallets[0].balance.amount as number
                        ) || 0,
                    currency: 'NGN',
                    currencyDisplay: 'narrowSymbol',
                    notation: 'standard',
                    minimumFractionDigits: 2,
                  })}
                </p>
              </IonText>
            </div>
            <div className='home__wallet'>
              <IonText>
                <p className='home__walletName'>ESCROW</p>
              </IonText>
              <IonText>
                <p className='home__walletBalance'>
                  {currencyFormatter({
                    value: state.escrows
                      ? koboToNaira(state.escrows[0].balance.amount)
                      : koboToNaira(
                          profile?.escrows[0].balance.amount as number
                        ) || 0,
                    currency: 'NGN',
                    currencyDisplay: 'narrowSymbol',
                    notation: 'compact',
                    maximumSignificantDigits: 3,
                    minimumFractionDigits: 2,
                  })}
                </p>
              </IonText>
              <IonText>
                <p className='home__walletBalanceFull'>
                  {currencyFormatter({
                    value: state.escrows
                      ? koboToNaira(state.escrows[0].balance.amount)
                      : koboToNaira(
                          profile?.escrows[0].balance.amount as number
                        ) || 0,
                    currency: 'NGN',
                    currencyDisplay: 'narrowSymbol',
                    notation: 'standard',
                    minimumFractionDigits: 2,
                  })}
                </p>
              </IonText>
            </div>
          </div>
          <div className='home__headerActions'>
            <div className='home__headerAction'>
              <IonButton routerLink={addMoneyPath} fill='clear'>
                Add Money
              </IonButton>
            </div>
            <div className='home__headerAction'>
              <IonButton
                fill='clear'
                routerLink={withdrawPath}
                routerDirection='forward'
              >
                Withdraw
              </IonButton>
            </div>
          </div>
        </div>
      </IonHeader>
      <IonContent className='home__content' fullscreen={true}>
        {/* <IonList lines='full' className='home__ongoingTrades'>
          <IonListHeader className='home__ongoingTradesHeader'>
            <IonLabel>Ongoing Trade</IonLabel>
            <IonButton className='link'>See More</IonButton>
          </IonListHeader>
          <TradeItem />
        </IonList> */}
        <div className='home__community'>
          <WhatsappIcon className='home__communityIcon' />
          <div className='home__communityContent'>
            <div>
              <IonText>
                <h2>Join our WhatsApp Channel</h2>
              </IonText>
              <IonText>
                <p>
                  Don’t miss out on great deals and exciting community updates
                </p>
              </IonText>
            </div>
            <IonButton
              fill='clear'
              target='__blank'
              className='link'
              href={WHATSAPP_GROUP_LINK}
            >
              Join
            </IonButton>
          </div>
        </div>
        <RecentAds />
        <RecentTransactions wallets={state.wallets} />

        <IonFab
          slot='fixed'
          vertical='bottom'
          horizontal='end'
          id='actions-popover-button'
        >
          <IonFabButton size='small' color='medium'>
            <PlusIcon />
            <span>Quick Actions</span>
          </IonFabButton>
        </IonFab>
      </IonContent>
      <IonPopover
        side='top'
        className='home__actionsPopover'
        dismissOnSelect={true}
        trigger='actions-popover-button'
      >
        <IonItem
          lines='none'
          button={true}
          detail={false}
          routerLink={postAdPath}
        >
          Create Ad
        </IonItem>
        <IonItem
          lines='none'
          button={true}
          detail={false}
          routerLink={postTradeRequestPath}
        >
          Create Trade request
        </IonItem>
      </IonPopover>
      <SetUsernameModal
        profile={profile}
        isOpen={state.isUsernameModalOpen}
        onDismiss={onUsernameModalDismiss}
      />
    </IonPage>
  );
};

export default Home;
