import {
  IonPage,
  IonHeader,
  IonButton,
  IonText,
  IonContent,
  useIonRouter,
  IonList,
  IonItem,
  IonLabel,
  useIonToast,
  IonSkeletonText,
  useIonViewWillEnter,
} from '@ionic/react';
import { FC, useCallback, useContext, useState } from 'react';
import { CloseIcon } from 'src/assets/icons';

import './TradeRequestNegotiations.scss';
import { RouteComponentProps } from 'react-router';
import Avatar from 'src/components/Avatar';
import { isApolloError } from '@apollo/client';
import { fetchNegotiations } from '../../api';
import { Negotiation } from '../../types';
import { tradeRequestChatPath } from 'src/data/pageRoutes';
import AppContext from 'src/AppContext';

type TradeRequestNegotiationsProps = RouteComponentProps<{ id: string }>;

interface TradeRequestNegotiationsState {
  isFetchingNegotiations: boolean;
  negotiations: Negotiation[];
}

const TradeRequestNegotiations: FC<TradeRequestNegotiationsProps> = ({
  match,
}) => {
  const router = useIonRouter();
  const [present] = useIonToast();
  const { profile } = useContext(AppContext);

  const [state, setState] = useState<TradeRequestNegotiationsState>({
    isFetchingNegotiations: false,
    negotiations: [],
  });

  const handleStateUpdate = (
    newState: Partial<TradeRequestNegotiationsState>
  ) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const tradeRequestId = match.params.id;
  const getRequestNegotiations = useCallback(async () => {
    handleStateUpdate({ isFetchingNegotiations: true });
    try {
      const res = await fetchNegotiations(tradeRequestId);
      handleStateUpdate({
        negotiations: res.data.negotiation,
        isFetchingNegotiations: false,
      });
    } catch (error: any) {
      isApolloError(error) &&
        present({
          message: error.graphQLErrors[0].message,
          duration: 3000,
          color: 'danger',
        });
      handleStateUpdate({ isFetchingNegotiations: false });
    }
  }, [tradeRequestId, present]);

  useIonViewWillEnter(() => {
    getRequestNegotiations();
  }, [tradeRequestId, getRequestNegotiations]);

  const renderNegotiationMessage = (negotiation: Negotiation) => {
    if (!negotiation.messages.length) {
      return <></>;
    }
    const lastMessage = negotiation.messages[negotiation.messages.length - 1];
    const isSelfMessage = lastMessage.user.id === profile?.id;
    if (lastMessage.file_type) {
      <IonText color={'tertiary'}>
        <p>
          {isSelfMessage ? 'You' : lastMessage.user.firstname} sent an image
        </p>
      </IonText>;
    }
    return (
      <IonText color={'tertiary'}>
        <p>
          {isSelfMessage ? 'You' : lastMessage.user.firstname}:{' '}
          {lastMessage.body}
        </p>
      </IonText>
    );
  };

  const renderNegotiations = () => {
    if (state.isFetchingNegotiations) {
      return (
        <>
          <IonItem className='requestNegotiations__skeleton'>
            <IonSkeletonText
              className='requestNegotiations__skeletonAvatar'
              slot='start'
              animated={true}
            ></IonSkeletonText>
            <IonLabel>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
            </IonLabel>
          </IonItem>
          <IonItem className='requestNegotiations__skeleton'>
            <IonSkeletonText
              className='requestNegotiations__skeletonAvatar'
              slot='start'
              animated={true}
            ></IonSkeletonText>
            <IonLabel>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
            </IonLabel>
          </IonItem>
          <IonItem className='requestNegotiations__skeleton'>
            <IonSkeletonText
              className='requestNegotiations__skeletonAvatar'
              slot='start'
              animated={true}
            ></IonSkeletonText>
            <IonLabel>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
            </IonLabel>
          </IonItem>
        </>
      );
    }
    if (state.negotiations.length === 0) {
      return (
        <IonItem>
          <IonLabel>
            <h4>You do not have any negotiations for this trade request</h4>
          </IonLabel>
        </IonItem>
      );
    }
    return state.negotiations.map((negotiation) => (
      <IonItem
        button
        detail={false}
        key={negotiation.id}
        className='requestNegotiations__item'
        routerLink={`${tradeRequestChatPath}/${negotiation.id}`}
      >
        <Avatar
          className='requestNegotiations__itemAvatar'
          title={negotiation.user.firstname}
        />
        <IonLabel>
          <h4>{negotiation.user.firstname}</h4>
          {renderNegotiationMessage(negotiation)}
        </IonLabel>
      </IonItem>
    ));
  };

  return (
    <IonPage className='requestNegotiations'>
      <IonHeader className='requestNegotiations__header' translucent={true}>
        <div className='requestNegotiations__headerBg'></div>
        <div className='requestNegotiations__headerContent'>
          <IonButton
            fill='clear'
            color={'dark'}
            onClick={() => router.goBack()}
            routerDirection='back'
            className='requestNegotiations__headerContentButton'
          >
            <CloseIcon />
          </IonButton>
          <IonText
            color={'dark'}
            className='requestNegotiations__headerContentTitle'
          >
            <h1>Request Negotiations</h1>
          </IonText>
        </div>
      </IonHeader>
      <IonContent fullscreen={true} className='requestNegotiations__content'>
        <IonList className='requestNegotiations__list'>
          {renderNegotiations()}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default TradeRequestNegotiations;
