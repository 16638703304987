import { apiService } from 'src/helpers';
import {
  Ad,
  Aggregate,
  Escrow,
  QueryFilters,
  Trade,
  TradeRequest,
  Transaction,
  Wallet,
} from 'src/types';
import {
  GET_ADS,
  GET_TRADES,
  GET_TRADE_REQUESTS,
  GET_TRANSACTIONS,
  SUB_ESCROWS,
  SUB_WALLETS,
} from './queries';
import {
  ACCEPT_TRADE_REQUEST,
  UPDATE_AD,
  UPDATE_TRADE_REQUEST,
} from './mutations';

import { omit } from 'lodash';
import { AdListRes, TradeRequestListRes } from './types';

export const subscribeWallets = () => {
  return apiService.subscribe<{ wallet: Wallet[] }>({
    query: SUB_WALLETS,
  });
};

export const subscribeEscrows = () => {
  return apiService.subscribe<{ escrow: Escrow[] }>({
    query: SUB_ESCROWS,
  });
};

export const fetchTransactions = (filters: QueryFilters) => {
  return apiService.query<{
    transaction: Transaction[];
    transaction_aggregate: Aggregate;
  }>({
    query: GET_TRANSACTIONS,
    variables: {
      ...filters,
    },
    fetchPolicy: 'network-only',
  });
};

export const fetchAds = (filters: QueryFilters) => {
  return apiService.query<AdListRes>({
    query: GET_ADS,
    variables: {
      ...filters,
    },
    fetchPolicy: 'network-only',
  });
};

export const fetchTradeRequests = (filters: QueryFilters) => {
  return apiService.query<TradeRequestListRes>({
    query: GET_TRADE_REQUESTS,
    variables: {
      ...filters,
    },
    fetchPolicy: 'network-only',
  });
};

export const fetchTrades = (filters: QueryFilters) => {
  return apiService.query<{ trade: Trade[]; trade_aggregate: Aggregate }>({
    query: GET_TRADES,
    variables: {
      ...filters,
    },
    fetchPolicy: 'network-only',
  });
};

export const updateAd = (ad: Partial<Ad>, authorizationToken: string) => {
  return apiService.mutate<{ update_ad_by_pk: { id: number } }>({
    mutation: UPDATE_AD,
    variables: {
      id: ad.id,
      ad: {
        ...omit(ad, 'id'),
      },
    },
    context: {
      headers: {
        'use-pin-token': authorizationToken,
      },
    },
  });
};

export const updateTradeRequest = (
  request: Partial<TradeRequest>,
  authorizationToken: string
) => {
  return apiService.mutate<{ update_trade_request_by_pk: { id: number } }>({
    mutation: UPDATE_TRADE_REQUEST,
    variables: {
      id: request.id,
      trade_request: {
        ...omit(request, 'id'),
      },
    },
    context: {
      headers: {
        'use-pin-token': authorizationToken,
      },
    },
  });
};

export const acceptTradeRequest = (
  data: {
    payment_method_id: string;
    trade_request_id: string;
  },
  authorizationToken: string
) => {
  return apiService.mutate<{
    acceptTradeRequest: { id: string; trades: Trade[] };
  }>({
    mutation: ACCEPT_TRADE_REQUEST,
    variables: {
      args: data,
    },
    context: {
      headers: {
        'use-pin-token': authorizationToken,
      },
    },
  });
};
