import { FC, useState } from 'react';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';
import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
} from '@ionic/react';

import cx from 'classnames';
import './PasswordInput.scss';

const PasswordInput: FC<any> = ({
  label,
  errorText,
  isValid,
  ...restProps
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <IonItem
      className={cx([
        'password',
        {
          'ion-invalid': !isValid,
        },
      ])}
    >
      <IonLabel position='stacked'>{label}</IonLabel>
      <IonButton fill='clear' onClick={() => setShowPassword(!showPassword)}>
        <IonIcon
          color='dark'
          slot='icon-only'
          icon={showPassword ? eyeOffOutline : eyeOutline}
        />
      </IonButton>
      <IonInput
        legacy={true}
        {...restProps}
        type={showPassword ? 'text' : 'password'}
      ></IonInput>
      <IonNote slot='error'>{errorText}</IonNote>
    </IonItem>
  );
};

export default PasswordInput;
