import { IonRouterOutlet } from '@ionic/react';
import { FC } from 'react';
import { Redirect, Route } from 'react-router';
import AddMoney from './pages/AddMoney';
import Withdraw from './pages/Withdraw';
import { addMoneyPath, walletPath, withdrawPath } from '../../data/pageRoutes';

const Wallet: FC = () => {
  return (
    <IonRouterOutlet>
      <Route exact path={addMoneyPath} component={AddMoney} />
      <Route exact path={withdrawPath} component={Withdraw} />
      <Route exact path={walletPath}>
        <Redirect to={addMoneyPath} />
      </Route>
    </IonRouterOutlet>
  );
};

export default Wallet;
