import { gql } from '@apollo/client';

export const VERIFY_BVN = gql`
  mutation verifyBvn($authVerifyBvnInput: AuthVerifyBvnInput!) {
    verifyBvn(authVerifyBvnInput: $authVerifyBvnInput) {
      data {
        phone
      }
      message
      status
    }
  }
`;

export const VERIFY_BVN_OTP = gql`
  mutation verifyBvnOtp($authConfirmBvnInput: AuthConfirmBvnInput!) {
    confirmBvn(authConfirmBvnInput: $authConfirmBvnInput) {
      message
      status
    }
  }
`;

export const SET_PIN = gql`
  mutation setPin($authSetPinInput: AuthSetPinInput!) {
    setPin(authSetPinInput: $authSetPinInput) {
      message
      status
    }
  }
`;

export const SEND_BVN_OTP_TO_EMAIL = gql`
  query sendBvnOtpToEmail {
    resendBVNEmail {
      message
      status
    }
  }
`;
