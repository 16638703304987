import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
  useIonRouter,
  useIonToast,
} from '@ionic/react';
import { FC, useContext, useRef } from 'react';
import { format } from 'date-fns';

import AppContext from 'src/AppContext';
import { validateKycPath } from '../../../../data/pageRoutes';
import { useFormik } from 'formik';
import { isApolloError } from '@apollo/client';
import { postVerifyBvn } from '../../api';
import { kycValidationSchema } from '../../validators';

import './KYC.scss';
import BackButton from 'src/components/BackButton';
import { useHistory } from 'react-router';

const KYC: FC = () => {
  const router = useIonRouter();
  const [present] = useIonToast();
  const { refreshProfile, logout } = useContext(AppContext);
  const dobModal = useRef<HTMLIonModalElement>(null);
  const datetime = useRef<null | HTMLIonDatetimeElement>(null);

  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);

  const onDateTimeConfirm = () => {
    datetime.current?.confirm();
    dobModal.current?.dismiss();
  };

  const onDateTimeCancel = () => {
    datetime.current?.cancel();
    dobModal.current?.dismiss();
  };

  const initialValues = {
    bvn: '',
    dob: null,
  };

  const onSubmit = async ({ bvn, dob }: typeof initialValues) => {
    try {
      const res = await postVerifyBvn({ bvn, dob: dob! });
      await refreshProfile();
      router.push(
        `${validateKycPath}?phone=${
          res.data!.verifyBvn.data.phone
        }&${queryParams.toString()}`
      );
    } catch (e: any) {
      isApolloError(e) &&
        present({
          message: e.graphQLErrors[0].message,
          duration: 3000,
          color: 'danger',
        });
    }
  };

  const {
    values,
    isValid,
    handleBlur,
    setFieldValue,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues,
    onSubmit,
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: kycValidationSchema,
  });

  return (
    <IonPage className='kyc'>
      <IonContent className='ion-padding-horizontal ion-padding-top'>
        <BackButton handleClick={() => logout()} />
        <div className='kyc__header'>
          <IonText color='primary'>
            <h2>Verify your identity</h2>
          </IonText>
          <IonText>
            <p>We just want to be sure it's you.</p>
          </IonText>
        </div>
        <form className='kyc__form' onSubmit={handleSubmit}>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position='stacked'>
                    Bank Verification Number (BVN)
                  </IonLabel>
                  <IonInput
                    name='bvn'
                    type='text'
                    placeholder=''
                    value={values.bvn}
                    inputMode='numeric'
                    onIonBlur={handleBlur}
                    onIonInput={handleChange}
                    onIonChange={handleChange}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem id='open-date'>
                  <IonLabel position='stacked'>Date of Birth</IonLabel>
                  <IonInput
                    readonly
                    placeholder=''
                    value={values.dob}
                  ></IonInput>
                  <IonModal
                    id='dob'
                    ref={dobModal}
                    trigger='open-date'
                    keepContentsMounted={true}
                  >
                    <IonDatetime
                      id='datetime'
                      ref={datetime}
                      value={values.dob}
                      presentation='date'
                      onIonChange={(e) => {
                        setFieldValue(
                          'dob',
                          format(
                            new Date(e.target.value as string),
                            'yyyy-MM-dd'
                          )
                        );
                      }}
                    >
                      <IonButtons slot='buttons'>
                        <IonButton color={'danger'} onClick={onDateTimeCancel}>
                          Cancel
                        </IonButton>
                        <IonButton
                          color={'primary'}
                          onClick={onDateTimeConfirm}
                        >
                          Ok
                        </IonButton>
                      </IonButtons>
                    </IonDatetime>
                  </IonModal>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonButton
            type='submit'
            expand='full'
            disabled={!isValid || isSubmitting}
          >
            Verify
            {isSubmitting ? <IonSpinner name='crescent'></IonSpinner> : null}
          </IonButton>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default KYC;
