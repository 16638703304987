import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonPopover,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonText,
  useIonViewWillEnter,
} from '@ionic/react';
import { FC, useContext, useState } from 'react';
import { useHistory } from 'react-router';

import { useFormik } from 'formik';
import { useDebounce } from 'usehooks-ts';
import { chevronDownOutline } from 'ionicons/icons';

import { CloseIcon } from '../../../../assets/icons';

import { AdsView } from 'src/types';

import { AdsCategory } from '../../types';

import AppContext from 'src/AppContext';

import { adsTabPath } from '../../../../data/pageRoutes';

import './Ads.scss';
import { useUpdatedEffect } from 'src/hooks/useUpdatedEffect';
import AdsList from '../../components/AdsList';
import TradeRequestsList from '../../components/TradeRequestsList';

interface AdsState {
  activeCategory: AdsCategory;
  activeView: AdsView;
  isFetchingData: boolean;
}

const Ads: FC = () => {
  const history = useHistory();

  const { paymentSources, currencies } = useContext(AppContext);
  const [state, setState] = useState<AdsState>({
    activeCategory: AdsCategory.ALL_ADS,
    activeView: AdsView.Ads,
    isFetchingData: false,
  });
  const {
    values: filterValues,
    setFieldValue: setFiltersFieldValue,
    handleBlur,
    handleChange,
  } = useFormik({
    initialValues: {
      amount: '',
      paymentSource: '',
      currency: '',
    },
    onSubmit: () => {},
  });

  const debouncedFilters = useDebounce(filterValues, 500);

  const setAdCategory = (category: AdsCategory) => {
    handleStateUpdate({ activeCategory: category });
  };

  const handleStateUpdate = (newState: Partial<AdsState>) =>
    setState((_state) => ({ ..._state, ...newState }));

  useIonViewWillEnter(() => {
    const queryParams = new URLSearchParams(history.location.search);
    const view = queryParams.get('view') as AdsView;
    handleStateUpdate({ activeView: view || AdsView.Ads });
    // getAds(true, view || AdsView.Ads);
  });

  useUpdatedEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.activeCategory, debouncedFilters]);

  const selectedCurrency = () => {
    if (filterValues.currency === '') {
      return '🌍 All';
    }
    const currency = currencies.find(
      (currency) => currency.code === filterValues.currency
    );
    return `${currency?.flag} ${currency?.code}`;
  };

  return (
    <IonPage className='listings'>
      <IonHeader className='listings__header' translucent={true}>
        <div className='listings__headerBg'></div>
        <div className='listings__headerContent'>
          <div className='listings__headerTop'>
            <div className='listings__title'>
              <IonText>
                <h1>
                  {state.activeView === AdsView.Ads ? 'Ads' : 'Trade Requests'}
                </h1>
              </IonText>
            </div>
            <IonButton
              color={'medium'}
              fill={'clear'}
              id='popover-button'
              className='listings__categoryFilter'
            >
              <span className='listings__selectedCategory'>
                <IonText color={'medium'}>
                  {state.activeCategory === AdsCategory.ALL_ADS
                    ? `All ${
                        state.activeView === AdsView.Ads ? 'Ads' : 'Requests'
                      }`
                    : `My ${
                        state.activeView === AdsView.Ads ? 'Ads' : 'Requests'
                      }`}
                </IonText>
                <IonIcon icon={chevronDownOutline} />
              </span>
            </IonButton>
            <IonPopover
              alignment='end'
              dismissOnSelect={true}
              trigger='popover-button'
            >
              <IonList lines='none'>
                <IonItem
                  onClick={() => setAdCategory(AdsCategory.ALL_ADS)}
                  button={true}
                  detail={false}
                >
                  All {state.activeView === AdsView.Ads ? 'Ads' : 'Requests'}
                </IonItem>
                <IonItem
                  onClick={() => setAdCategory(AdsCategory.MY_ADS)}
                  button={true}
                  detail={false}
                >
                  My {state.activeView === AdsView.Ads ? 'Ads' : 'Requests'}
                </IonItem>
              </IonList>
            </IonPopover>
            {/* <IonText color={'medium'} className='ads__currency'>
              <p>NGN</p>
            </IonText> */}
          </div>
          <div className='listings__types'>
            <IonSegment
              value={state.activeView}
              onIonChange={(ev) => {
                handleStateUpdate({ activeView: ev.detail.value as AdsView });
                const params = new URLSearchParams({ view: ev.detail.value! });
                history.replace({
                  pathname: adsTabPath,
                  search: params.toString(),
                });
              }}
            >
              <IonSegmentButton
                disabled={state.isFetchingData}
                value={AdsView.Ads}
              >
                <IonLabel>Ads</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton
                disabled={state.isFetchingData}
                value={AdsView.TradeRequests}
              >
                <IonLabel>Trade requests</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </div>
          <div className='listings__filters'>
            <div className='listings__filtersInput'>
              <IonInput
                type='number'
                name='amount'
                legacy={true}
                inputMode='numeric'
                placeholder='AMOUNT'
                onIonBlur={handleBlur}
                onIonInput={handleChange}
                onIonChange={handleChange}
                value={filterValues.amount}
              />
              {Boolean(filterValues.amount) && (
                <IonButton
                  onClick={() => setFiltersFieldValue('amount', '')}
                  fill='clear'
                >
                  <CloseIcon />
                </IonButton>
              )}
            </div>
            <IonSelect
              name='paymentSource'
              value={filterValues.paymentSource}
              onIonBlur={handleBlur}
              onIonChange={(e) => {
                if (!Boolean(e.target.value)) {
                  return;
                }
                if (e.target.value === 'ALL') {
                  setFiltersFieldValue('paymentSource', '');
                } else {
                  handleChange(e);
                }
              }}
              legacy={true}
              placeholder='PAYMENT METHOD'
            >
              <IonSelectOption value={'ALL'}>All</IonSelectOption>
              {paymentSources?.map((paymentSource) => (
                <IonSelectOption
                  key={paymentSource.id}
                  value={paymentSource.id}
                >
                  {paymentSource.name}
                </IonSelectOption>
              ))}
            </IonSelect>
            <IonSelect
              name='currency'
              value={filterValues.currency}
              onIonBlur={handleBlur}
              onIonChange={(e) => {
                if (!Boolean(e.target.value)) {
                  return;
                }
                if (e.target.value === 'ALL') {
                  setFiltersFieldValue('currency', '');
                } else {
                  handleChange(e);
                }
              }}
              placeholder='CURRENCY'
              legacy={true}
              selectedText={selectedCurrency()}
            >
              <IonSelectOption value={'ALL'}>
                🌍 &nbsp;&nbsp; All
              </IonSelectOption>
              {currencies
                .filter((currency) => currency.is_source)
                .map((currency) => (
                  <IonSelectOption key={currency.code} value={currency.code}>
                    {currency.flag} &nbsp;&nbsp; {currency.code}
                  </IonSelectOption>
                ))}
            </IonSelect>
          </div>
        </div>
      </IonHeader>
      <IonContent className='listings__content' fullscreen={true}>
        {state.activeView === AdsView.Ads ? (
          <AdsList
            activeCategory={state.activeCategory}
            filterValues={debouncedFilters}
            updateParentState={handleStateUpdate}
          />
        ) : (
          <TradeRequestsList
            activeCategory={state.activeCategory}
            filterValues={debouncedFilters}
            updateParentState={handleStateUpdate}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Ads;
