import { IonReactRouter } from '@ionic/react-router';
import { FC } from 'react';
import { Route, Redirect } from 'react-router';
import {
  authPath,
  tabsPath,
  walletPath,
  adsPath,
  tradePath,
  onboardingPath,
  transactionPath,
  registerPath,
  settingsPath,
} from './data/pageRoutes';
import Ads from './modules/ads';
import Auth from './modules/auth';
import Tabs from './modules/tabs';
import Trade from './modules/trade';
import Wallet from './modules/wallet';
import Onboarding from './modules/onboarding';
import PrivateRouteGuard from './components/PrivateRouteGuard';
import Transaction from './modules/transaction';
import AppOneSignal from './AppOneSignal';
import OnboardingRouteGuard from './components/OnboardingRouteGuard';
import Settings from './modules/settings';

const AppRouter: FC = () => {
  return (
    <>
      <IonReactRouter>
        <AppOneSignal />
        <Route
          path={'/register'}
          render={(props) => (
            <Redirect
              to={{
                pathname: registerPath,
                search: props.location.search,
              }}
            />
          )}
        />
        <Route path={authPath} render={() => <Auth />} />
        <Route
          path={tabsPath}
          render={() => (
            <PrivateRouteGuard>
              <Tabs />
            </PrivateRouteGuard>
          )}
        />
        <Route
          path={walletPath}
          render={() => (
            <PrivateRouteGuard>
              <Wallet />
            </PrivateRouteGuard>
          )}
        />
        <Route
          path={adsPath}
          render={() => (
            <PrivateRouteGuard>
              <Ads />
            </PrivateRouteGuard>
          )}
        />
        <Route
          path={`${tradePath}/*`}
          render={() => (
            <PrivateRouteGuard>
              <Trade />
            </PrivateRouteGuard>
          )}
        />
        <Route
          path={`${settingsPath}/*`}
          render={() => (
            <PrivateRouteGuard>
              <Settings />
            </PrivateRouteGuard>
          )}
        />
        <Route
          path={`${transactionPath}/*`}
          render={() => (
            <PrivateRouteGuard>
              <Transaction />
            </PrivateRouteGuard>
          )}
        />
        <Route
          path={`${onboardingPath}/*`}
          render={() => (
            <OnboardingRouteGuard>
              <Onboarding />
            </OnboardingRouteGuard>
          )}
        />
        <Route exact path='/'>
          <Redirect to={tabsPath} />
        </Route>
      </IonReactRouter>
    </>
  );
};

export default AppRouter;
