import { apiService } from 'src/helpers';
import {
  ADD_PAYMENT_METHOD,
  POST_AD,
  POST_TRADE_REQUEST,
  UPDATE_PAYMENT_METHOD,
} from './mutations';
import { RDAddPaymentMethod, RDPostAd, RDPostTradeRequest } from './types';
import { IObject, PaymentMethod } from 'src/types';
import { GET_PAYMENT_METHODS } from './queries';

export const addPaymentMethod = (data: RDAddPaymentMethod) => {
  return apiService.mutate<{ insert_payment_method_one: PaymentMethod }>({
    mutation: ADD_PAYMENT_METHOD,
    variables: { input: data },
  });
};

export const updatePaymentMethod = ({
  id,
  data,
}: {
  id: string;
  data: IObject;
}) => {
  return apiService.mutate<{ update_payment_method_by_pk: any }>({
    mutation: UPDATE_PAYMENT_METHOD,
    variables: {
      id,
      set: { data },
    },
  });
};

export const fetchPaymentMethods = (userId: string) => {
  return apiService.query<{ payment_method: PaymentMethod[] }>({
    query: GET_PAYMENT_METHODS,
    variables: { userId },
    fetchPolicy: 'network-only',
  });
};

export const postAd = (data: RDPostAd) => {
  return apiService.mutate<{ insert_ad_one: any }>({
    mutation: POST_AD,
    variables: { input: data },
  });
};

export const postTradeRequest = (data: RDPostTradeRequest) => {
  return apiService.mutate<{ insert_trade_request_one: any }>({
    mutation: POST_TRADE_REQUEST,
    variables: { input: data },
  });
};
