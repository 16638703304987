import { FC, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonTitle,
  IonSearchbar,
  IonToolbar,
} from '@ionic/react';
import type { CheckboxCustomEvent } from '@ionic/react';

interface Item {
  label: string;
  value: string;
}

interface AutoCompleteProps {
  items: Item[];
  value: string | string[];
  title?: string;
  multiple?: boolean;
  onSelectionCancel: () => void;
  onSelectionChange: (item: string | string[]) => void;
}

const AutoComplete: FC<AutoCompleteProps> = ({
  items,
  value,
  title,
  onSelectionCancel,
  onSelectionChange,
  multiple,
}) => {
  const [filteredItems, setFilteredItems] = useState<Item[]>([...items]);
  const [workingSelectedValues, setWorkingSelectedValues] = useState<
    string | string[]
  >(multiple ? [...(value as string[])] : value);

  const isChecked = (value: string) => {
    if (multiple) {
      return (
        (workingSelectedValues as string[]).find((item) => item === value) !==
        undefined
      );
    } else {
      return workingSelectedValues === value;
    }
  };

  const cancelChanges = () => {
    onSelectionCancel();
  };

  const confirmChanges = () => {
    onSelectionChange(workingSelectedValues);
  };

  const searchbarInput = (ev: any) => {
    filterList(ev.target.value);
  };

  /**
   * Update the rendered view with
   * the provided search query. If no
   * query is provided, all data
   * will be rendered.
   */
  const filterList = (searchQuery: string | null | undefined) => {
    /**
     * If no search query is defined,
     * return all options.
     */
    if (searchQuery === undefined || searchQuery === null) {
      setFilteredItems([...items]);
    } else {
      /**
       * Otherwise, normalize the search
       * query and check to see which items
       * contain the search query as a substring.
       */
      const normalizedQuery = searchQuery.toLowerCase();
      setFilteredItems(
        items.filter((item) => {
          return new RegExp(normalizedQuery.trim(), 'i').test(item.label);
        })
      );
    }
  };

  const checkboxChange = (ev: CheckboxCustomEvent) => {
    const { checked, value } = ev.detail;

    if (checked) {
      setWorkingSelectedValues(
        multiple ? [...(workingSelectedValues as string[]), value] : value
      );
    } else {
      setWorkingSelectedValues(
        multiple
          ? (workingSelectedValues as string[]).filter(
              (item: string) => item !== value
            )
          : ''
      );
    }
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton onClick={cancelChanges}>Cancel</IonButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={confirmChanges}>Done</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar onIonInput={searchbarInput}></IonSearchbar>
        </IonToolbar>
      </IonHeader>

      <IonContent color='light' class='ion-padding'>
        <IonList id='modal-list' inset={true}>
          {filteredItems.map((item, index) => (
            <IonItem key={`${item.value}-${item.label}-${index}`}>
              <IonCheckbox
                value={item.value}
                checked={isChecked(item.value)}
                onIonChange={checkboxChange}
              >
                {item.label}
              </IonCheckbox>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </>
  );
};

AutoComplete.defaultProps = {
  multiple: false,
};

export default AutoComplete;
