import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
  useIonRouter,
  useIonToast,
  useIonViewWillEnter,
} from '@ionic/react';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import BackButton from '../../../../components/BackButton';

import './ValidateKYC.scss';
import { setPinPath } from '../../../../data/pageRoutes';
import { useLocation } from 'react-router';
import { useFormik } from 'formik';
import { isApolloError } from '@apollo/client';
import { postVerifyBvnOtp, sendBvnOtpToEmail } from '../../api';
import { otpValidationSchema } from '../../validators';
import { CAN_RESEND_KEY } from 'src/data/constants';
import { OtpSelector } from 'src/types';
import AppContext from 'src/AppContext';
import appStorage from 'src/helpers/storage';
import { useCountdown } from 'usehooks-ts';
import If from 'src/components/If';

const ValidateKYC: FC = () => {
  const router = useIonRouter();
  const location = useLocation();
  const { refreshProfile } = useContext(AppContext);
  const queryParams = new URLSearchParams(location.search);

  const [present] = useIonToast();

  const [phone, setPhone] = useState<string>('');

  const [canResend, setCanResend] = useState<boolean>(false);
  const [isSendingOtpToEmail, setIsSendingOtpToEmail] =
    useState<boolean>(false);

  const [count, { startCountdown, stopCountdown }] = useCountdown({
    countStart: 60,
    intervalMs: 1000,
    countStop: 0,
  });

  const initialValues = {
    char1: '',
    char2: '',
    char3: '',
    char4: '',
  };

  const inputRef = useRef<HTMLIonInputElement[]>([]);

  const getCanResend = useCallback(async () => {
    const canResendStore = await appStorage.getItem(CAN_RESEND_KEY);
    if (canResendStore) {
      const canResend = JSON.parse(canResendStore);
      setCanResend(canResend);
      if (!canResend) {
        startCountdown();
      }
    } else {
      startCountdown();
      appStorage.setItem(CAN_RESEND_KEY, JSON.stringify(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (count === 0) {
      setCanResend(true);
      appStorage.setItem(CAN_RESEND_KEY, JSON.stringify(true));
      stopCountdown();
    }
  }, [count, stopCountdown]);

  useIonViewWillEnter(() => {
    stopCountdown();
    const phone = queryParams.get('phone');
    setPhone(phone!);
    getCanResend();
  });

  const onSubmit = async (values: typeof initialValues) => {
    const code = `${values.char1}${values.char2}${values.char3}${values.char4}`;
    try {
      await postVerifyBvnOtp(code);
      await refreshProfile();
      queryParams.delete('phone');
      appStorage.setItem(CAN_RESEND_KEY, JSON.stringify(false));
      router.push(`${setPinPath}?${queryParams.toString()}`);
    } catch (e: any) {
      isApolloError(e) &&
        present({
          message: e.graphQLErrors[0].message,
          duration: 3000,
          color: 'danger',
        });
    }
  };

  const {
    values,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues,
    onSubmit,
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: otpValidationSchema,
  });

  const handleOnKeyUp = (
    e: React.KeyboardEvent<HTMLIonInputElement>,
    index: number
  ) => {
    setTimeout(() => {
      if (e.code === 'Backspace' && index === 1) {
        return;
      }

      if (e.code === 'Backspace' && index !== 1) {
        inputRef.current[index - 2].setFocus();
        return;
      }
      if (index !== 4) {
        inputRef.current[index].setFocus();
      }
    }, 50);
  };

  const handlePaste = (e: any) => {
    e.preventDefault();
    const paste = e.clipboardData.getData('text/plain');
    const pasteArray = paste.split('');
    const pasteLength = pasteArray.length;
    if (pasteLength > 4) return;
    for (let i = 0; i < pasteLength; i++) {
      setFieldValue(`char${i + 1}`, pasteArray[i]);
      if (i === 3) {
        inputRef.current[i].setFocus();
      }
    }
  };

  const onSendToEmailClick = async () => {
    setIsSendingOtpToEmail(true);
    console.log('sending otp to email');

    try {
      const res = await sendBvnOtpToEmail();
      setIsSendingOtpToEmail(false);
      present({
        message: res.data.resendBVNEmail.message || 'OTP sent to email',
        duration: 3000,
        color: 'success',
      });
    } catch (e: any) {
      setIsSendingOtpToEmail(false);
      isApolloError(e) &&
        present({
          message: e.graphQLErrors[0].message,
          duration: 3000,
          color: 'danger',
        });
    }
  };

  return (
    <IonPage className='validateKyc'>
      <IonContent className='ion-padding-horizontal ion-padding-top'>
        <BackButton />
        <div className='validateKyc__header'>
          <IonText color='primary'>
            <h2>Enter Validation PIN</h2>
          </IonText>
          <IonText>
            <p>
              A pin has been sent to the number associated with your BVN ending
              with {phone}
            </p>
          </IonText>
        </div>
        <form className='validateKyc__form' onSubmit={handleSubmit}>
          <IonGrid>
            <IonRow className='ion-justify-content-center'>
              {Array.from({ length: 4 }).map((_, index) => {
                const pos = index + 1;
                const selector = `char${pos}` as OtpSelector;
                return (
                  <IonCol key={selector}>
                    <IonItem>
                      <IonInput
                        max={9}
                        id={selector}
                        name={selector}
                        legacy={true}
                        maxlength={1}
                        placeholder='●'
                        inputMode='numeric'
                        value={values[selector]}
                        onPaste={handlePaste}
                        onIonBlur={handleBlur}
                        onIonInput={handleChange}
                        onIonChange={handleChange}
                        onKeyUp={(e) => handleOnKeyUp(e, pos)}
                        ref={(el: HTMLIonInputElement) =>
                          (inputRef.current[index] = el)
                        }
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                );
              })}
            </IonRow>
          </IonGrid>
          <If condition={canResend}>
            <IonText className='validateKyc__resend'>
              <p>
                Didn't receive a PIN?{' '}
                <IonButton
                  fill='clear'
                  className='link'
                  onClick={onSendToEmailClick}
                  disabled={isSendingOtpToEmail}
                >
                  Send to email
                </IonButton>
                {isSendingOtpToEmail ? <IonSpinner name='crescent' /> : null}
              </p>
            </IonText>
          </If>
          <IonButton
            type='submit'
            expand='full'
            disabled={!isValid || isSubmitting}
          >
            Verify
            {isSubmitting ? <IonSpinner name='crescent'></IonSpinner> : null}
          </IonButton>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default ValidateKYC;
