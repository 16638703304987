import { isApolloError } from '@apollo/client';
import {
  IonChip,
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonSkeletonText,
  IonText,
  useIonToast,
  useIonViewWillEnter,
} from '@ionic/react';
import { FC, useEffect, useState } from 'react';
import { QueryFilters, Trade, TradeStatus } from 'src/types';
import { fetchTrades } from '../../api';
import TradeItem from '../../components/TradeItem';
import If from 'src/components/If';
import cx from 'classnames';

import './Trades.scss';

enum TradeView {
  ONGOING = 'ongoing',
  COMPLETED = 'completed',
}

interface TradesState {
  page: number;
  totalTrades: number;
  trades: Trade[];
  activeStatus: TradeStatus | '';
  activeView: TradeView;
  isFetchingTrades: boolean;
}

const Trades: FC = () => {
  const [present] = useIonToast();

  const [state, setState] = useState<TradesState>({
    page: 1,
    totalTrades: 0,
    trades: [],
    activeStatus: '',
    activeView: TradeView.ONGOING,
    isFetchingTrades: true,
  });

  const handleStateUpdate = (newState: Partial<TradesState>) =>
    setState({ ...state, ...newState });

  const setActiveStatus = (status: TradeStatus | '') => {
    handleStateUpdate({ activeStatus: status });
  };

  const handleFetchTrades = (filters: QueryFilters) => {
    return fetchTrades(filters);
  };

  const getTrades = (refresh = false) => {
    handleStateUpdate({
      isFetchingTrades: refresh,
    });
    let filters: QueryFilters = {
      limit: 10,
      offset: refresh ? 0 : state.page * 10,
      order_by: {
        updated_at: 'desc',
      },
    };

    if (state.activeStatus) {
      filters = {
        ...filters,
        where: {
          ...filters.where,
          status: { _eq: state.activeStatus },
        },
      };
    } else {
      filters = {
        ...filters,
        where: {
          ...filters.where,
          status: { _nin: [TradeStatus.COMPLETED, TradeStatus.CANCELLED] },
        },
      };
    }

    if (state.activeView === TradeView.COMPLETED) {
      filters = {
        ...filters,
        where: {
          ...filters.where,
          status: { _in: [TradeStatus.COMPLETED, TradeStatus.CANCELLED] },
        },
      };
    }

    return handleFetchTrades(filters)
      .then((res) => {
        handleStateUpdate({
          isFetchingTrades: false,
          trades: refresh
            ? res.data.trade
            : [...state.trades, ...res.data.trade],
          totalTrades: res.data.trade_aggregate.aggregate.count,
          page: refresh ? 1 : state.page + 1,
        });
      })
      .catch((err: any) => {
        isApolloError(err) &&
          present({
            message: err.graphQLErrors[0].message,
            duration: 3000,
            color: 'danger',
            position: 'bottom',
          });
        handleStateUpdate({
          isFetchingTrades: false,
        });
      });
  };

  useIonViewWillEnter(() => {
    getTrades(true);
  });

  useEffect(() => {
    getTrades(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.activeStatus, state.activeView]);

  const renderTrades = () => {
    if (state.isFetchingTrades) {
      return (
        <>
          <IonItem className='trades__skeleton'>
            <IonLabel>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
            </IonLabel>
          </IonItem>
          <IonItem className='trades__skeleton'>
            <IonLabel>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
            </IonLabel>
          </IonItem>
          <IonItem className='trades__skeleton'>
            <IonLabel>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
            </IonLabel>
          </IonItem>
        </>
      );
    }
    if (
      state.trades.length === 0 &&
      !state.isFetchingTrades &&
      state.activeView === TradeView.ONGOING
    ) {
      return (
        <IonItem>
          <IonLabel>
            <p>
              No &nbsp;
              {state.activeStatus.toLocaleLowerCase()}&nbsp; trades
            </p>
          </IonLabel>
        </IonItem>
      );
    }

    if (
      state.trades.length === 0 &&
      !state.isFetchingTrades &&
      state.activeView === TradeView.COMPLETED
    ) {
      return (
        <IonItem>
          <IonLabel>
            <p>No completed trades</p>
          </IonLabel>
        </IonItem>
      );
    }

    return state.trades.map((trade) => (
      <TradeItem trade={trade} key={trade.id} />
    ));
  };
  return (
    <IonPage className='trades'>
      <IonHeader className='trades__header' translucent={true}>
        <div className='trades__headerBg'></div>
        <div className='trades__headerContent'>
          <div className='trades__headerTop'>
            <div className='trades__title'>
              <IonText>
                <h1>Trades</h1>
              </IonText>
            </div>
          </div>
          <div className='trades__status'>
            <IonSegment
              value={state.activeView}
              onIonChange={(ev) =>
                handleStateUpdate({ activeView: ev.detail.value as TradeView })
              }
            >
              <IonSegmentButton
                disabled={state.isFetchingTrades}
                value={TradeView.ONGOING}
              >
                <IonLabel>Ongoing</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton
                disabled={state.isFetchingTrades}
                value={TradeView.COMPLETED}
              >
                <IonLabel>Completed</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </div>
          <If condition={state.activeView === TradeView.ONGOING}>
            <div className='trades__filters'>
              <IonChip
                disabled={state.isFetchingTrades}
                className={cx('trades__filter', {
                  'trades__filter--active': !state.activeStatus,
                })}
                onClick={() => setActiveStatus('')}
              >
                ALL
              </IonChip>
              <IonChip
                disabled={state.isFetchingTrades}
                className={cx('trades__filter', {
                  'trades__filter--active':
                    state.activeStatus === TradeStatus.UNPAID,
                })}
                onClick={() => setActiveStatus(TradeStatus.UNPAID)}
              >
                UNPAID
              </IonChip>
              <IonChip
                disabled={state.isFetchingTrades}
                className={cx('trades__filter', {
                  'trades__filter--active':
                    state.activeStatus === TradeStatus.PAID,
                })}
                onClick={() => setActiveStatus(TradeStatus.PAID)}
              >
                PAID
              </IonChip>
              <IonChip
                disabled={state.isFetchingTrades}
                className={cx('trades__filter', {
                  'trades__filter--active':
                    state.activeStatus === TradeStatus.IN_DISPUTE,
                })}
                onClick={() => setActiveStatus(TradeStatus.IN_DISPUTE)}
              >
                IN DISPUTE
              </IonChip>
            </div>
          </If>
        </div>
      </IonHeader>
      <IonContent className='trades__content' fullscreen={true}>
        <IonList lines='full'>{renderTrades()}</IonList>
        <IonInfiniteScroll
          onIonInfinite={async (ev) => {
            if (state.trades.length >= state.totalTrades) {
              ev.target.complete();
              return;
            }
            await getTrades();
            ev.target.complete();
          }}
        >
          <IonInfiniteScrollContent loadingSpinner='crescent'></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
};

export default Trades;
