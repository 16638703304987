import { createContext } from 'react';
import { AppContextProps } from './types';

const AppContext = createContext<AppContextProps>({
  user: null,
  profile: null,
  isFetchingProfile: false,
  setUser: () => {},
  resetToken: null,
  logout: () => {},
  paymentSources: [],
  setResetToken: () => {},
  banks: [],
  currencies: [],
  oneSignal: null,
  refreshProfile: async () => {},
  authorizeAction: () => {},
  pwaInstallPromptEvent: null,
  setOneSignalInstance: (oneSignal: any) => {},
});

export default AppContext;
