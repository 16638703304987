import { FC, useCallback, useContext, useEffect } from 'react';
import OneSignalInit from './helpers/oneSignal';
import { NOTIFICATION_ACTIONS } from './data/constants';
import { NotificationActionType, User } from './types';
import { NotificationClickEvent } from 'onesignal-cordova-plugin';
import { homePath } from './data/pageRoutes';
import AppContext from './AppContext';
import { useIonRouter } from '@ionic/react';

const AppOneSignal: FC = () => {
  const { setOneSignalInstance, user } = useContext(AppContext);
  const router = useIonRouter();
  const onNotificationOpened = (event: NotificationClickEvent) => {
    const data = event.notification.additionalData as NotificationActionType;
    console.log('onNotificationOpened', data);

    const path = `${NOTIFICATION_ACTIONS[data.type].route || homePath}${
      data.id ? `/${data.id}` : ''
    }`;
    router.push(path);
  };
  const setupOneSignal = useCallback(async (user: User) => {
    const oneSignalInstance = await OneSignalInit(onNotificationOpened);
    if (oneSignalInstance) {
      oneSignalInstance.login(user?.id!);
    }
    setOneSignalInstance(oneSignalInstance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      setupOneSignal(user);
    }
  }, [setupOneSignal, user]);
  return <></>;
};

export default AppOneSignal;
