import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
  useIonRouter,
  useIonToast,
} from '@ionic/react';
import { FC, useContext } from 'react';
import BackButton from '../../../../components/BackButton';

import cx from 'classnames';

import './ForgotPassword.scss';
import { resetPasswordPath } from '../../../../data/pageRoutes';
import { useFormik } from 'formik';
import { forgotPasswordValidationSchema } from '../../validators';
import { postForgotPassword } from '../../api';
import { isApolloError } from '@apollo/client';
import AppContext from 'src/AppContext';

const ForgotPassword: FC = () => {
  const router = useIonRouter();
  const [present] = useIonToast();

  const { setResetToken } = useContext(AppContext);

  const initialValues = {
    email: '',
  };

  const onSubmit = async (values: typeof initialValues) => {
    try {
      const res = await postForgotPassword(values);
      setResetToken(res.data!.forgotPassword.data.resetToken);
      router.push(resetPasswordPath);
    } catch (e: any) {
      isApolloError(e) &&
        present({
          message: e.graphQLErrors[0].message,
          duration: 3000,
          color: 'danger',
        });
    }
  };

  const {
    errors,
    values,
    isValid,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    onSubmit,
    initialValues,
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: forgotPasswordValidationSchema,
  });

  return (
    <IonPage className='forgotPassword'>
      <IonContent className='ion-padding-horizontal ion-padding-top'>
        <BackButton />
        <div className='forgotPassword__header'>
          <IonText color='primary'>
            <h2>Forgot Password?</h2>
          </IonText>
          <IonText>
            <p>Enter your email and a reset pin will be send to you.</p>
          </IonText>
        </div>
        <form className='forgotPassword__form' onSubmit={handleSubmit}>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonItem
                  className={cx({
                    'ion-invalid': Boolean(touched.email && errors.email),
                  })}
                >
                  <IonLabel position='stacked'>Email</IonLabel>
                  <IonInput
                    name='email'
                    value={values.email}
                    onIonBlur={handleBlur}
                    onIonInput={handleChange}
                    onIonChange={handleChange}
                  ></IonInput>
                  <IonNote slot='error'>
                    {touched.email && errors.email ? errors.email : ''}
                  </IonNote>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonButton
            type='submit'
            expand='full'
            disabled={!isValid || isSubmitting}
          >
            Submit
            {isSubmitting ? <IonSpinner name='crescent'></IonSpinner> : null}
          </IonButton>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;
