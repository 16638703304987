import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
  useIonRouter,
  useIonToast,
} from '@ionic/react';
import { FC, useContext } from 'react';
import { useFormik } from 'formik';

import './ResetPassword.scss';
import { resetPasswordValidationSchema } from '../../validators';
import { loginPath } from '../../../../data/pageRoutes';

import BackButton from '../../../../components/BackButton';
import PasswordInput from '../../components/PasswordInput';
import { postResetPassword } from '../../api';
import { isApolloError } from '@apollo/client';
import AppContext from 'src/AppContext';

const ResetPassword: FC = () => {
  const router = useIonRouter();
  const [present] = useIonToast();
  const { setResetToken } = useContext(AppContext);

  const initialValues = {
    code: '',
    password: '',
  };

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await postResetPassword(values);
      setResetToken('');
      router.push(loginPath);
    } catch (e: any) {
      isApolloError(e) &&
        present({
          message: e.graphQLErrors[0].message,
          duration: 3000,
          color: 'danger',
        });
    }
  };

  const {
    errors,
    values,
    isValid,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    onSubmit,
    initialValues,
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: resetPasswordValidationSchema,
  });

  return (
    <IonPage className='resetPassword'>
      <IonContent className='ion-padding-horizontal ion-padding-top'>
        <BackButton />
        <div className='resetPassword__header'>
          <IonText color='primary'>
            <h2>Set new password</h2>
          </IonText>
          <IonText>
            <p>Enter code sent your email and set a new password</p>
          </IonText>
        </div>
        <form className='resetPassword__form' onSubmit={handleSubmit}>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position='stacked'>Reset code</IonLabel>
                  <IonInput
                    name='code'
                    placeholder=''
                    value={values.code}
                    onIonBlur={handleBlur}
                    onIonInput={handleChange}
                    onIonChange={handleChange}
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <PasswordInput
                  name='password'
                  label='New Password'
                  onIonBlur={handleBlur}
                  value={values.password}
                  onIonInput={handleChange}
                  onIonChange={handleChange}
                  isValid={!Boolean(touched.password && errors.password)}
                  errorText={
                    touched.password && errors.password ? errors.password : ''
                  }
                />
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonButton
            type='submit'
            expand='full'
            disabled={!isValid || isSubmitting}
            className='resetPassword__formSubmit'
          >
            Submit
            {isSubmitting ? <IonSpinner name='crescent'></IonSpinner> : null}
          </IonButton>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default ResetPassword;
