import {
  IonModal,
  IonButton,
  IonText,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { CloseIcon } from 'src/assets/icons';

import './AcceptTradeRequestModal.scss';
import { PaymentMethod, TradeRequest } from 'src/types';
import AppContext from 'src/AppContext';
import { fetchPaymentMethods } from 'src/modules/ads/api';
import { useFormik } from 'formik';
import { object, string } from 'yup';

interface AcceptTradeRequestModalProps {
  isOpen: boolean;
  tradeRequest: TradeRequest;
  onDismiss: (isConfirmed: boolean, paymentMethodId?: string) => void;
}

interface AcceptTradeRequestModalState {
  isFetchingPaymentMethods: boolean;
  paymentMethods: PaymentMethod[];
}

const AcceptTradeRequestModal: FC<AcceptTradeRequestModalProps> = ({
  isOpen,
  onDismiss,
  tradeRequest,
}) => {
  const { profile } = useContext(AppContext);

  const [state, setState] = useState<AcceptTradeRequestModalState>({
    isFetchingPaymentMethods: false,
    paymentMethods: [],
  });

  const handleStateUpdate = (newState: Partial<AcceptTradeRequestModalState>) =>
    setState((_state) => ({ ..._state, ...newState }));

  const getPaymentMethods = useCallback(async () => {
    try {
      handleStateUpdate({
        isFetchingPaymentMethods: true,
      });
      const res = await fetchPaymentMethods(profile?.id!);
      handleStateUpdate({
        paymentMethods: res.data.payment_method,
        isFetchingPaymentMethods: false,
      });
    } catch (e) {
      handleStateUpdate({ isFetchingPaymentMethods: false });
    }
  }, [profile?.id]);

  const acceptablePaymentMethods = state.paymentMethods
    .filter((method) =>
      method.payment_source?.supported_currencies.includes(
        tradeRequest.source_currency
      )
    )
    .filter((method) =>
      tradeRequest.trade_request_payment_sources.find(
        (source) => source.payment_source.id === method.payment_source?.id
      )
    );

  const { values, isValid, handleChange, handleBlur, handleSubmit } = useFormik(
    {
      initialValues: {
        paymentMethod: '',
      },
      validateOnChange: true,
      validateOnBlur: true,
      validationSchema: object({
        paymentMethod: string().required('Select a payment method'),
      }),
      onSubmit: (values) => {
        onDismiss(true, values.paymentMethod);
      },
    }
  );

  useEffect(() => {
    getPaymentMethods();
  }, [getPaymentMethods]);

  return (
    <IonModal
      isOpen={isOpen}
      className='acceptRequest'
      onDidDismiss={() => onDismiss(false)}
    >
      <form className='acceptRequest__form' onSubmit={handleSubmit}>
        <div className='acceptRequest__header'>
          <IonButton
            className='acceptRequest__close'
            fill='clear'
            onClick={() => onDismiss(false)}
            color={'dark'}
          >
            <CloseIcon />
          </IonButton>
          <IonText color={'dark'} className='acceptRequest__title'>
            <h4>Accept Request from {tradeRequest.user.firstname}</h4>
          </IonText>
        </div>

        <div className='acceptRequest__content'>
          <IonItem>
            <IonLabel position='stacked'>Select Payment method</IonLabel>
            <IonSelect
              onIonBlur={handleBlur}
              name={'paymentMethod'}
              onIonChange={handleChange}
              value={values.paymentMethod}
              placeholder={'Select Payment method'}
            >
              {acceptablePaymentMethods.map((method) => (
                <IonSelectOption key={method.id} value={method.id}>
                  {method.payment_source?.name} (
                  {method.data[method.primary_field]})
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        </div>

        <div className='acceptRequest__actions'>
          <IonButton
            fill='solid'
            expand='full'
            color={'medium'}
            onClick={() => onDismiss(false)}
          >
            Cancel
          </IonButton>
          <IonButton
            fill='solid'
            type='submit'
            expand='full'
            color={'primary'}
            disabled={!isValid}
          >
            Accept request
          </IonButton>
        </div>
      </form>
    </IonModal>
  );
};

export default AcceptTradeRequestModal;
