import { useEffect, useReducer, useState } from 'react';
import { PERSIST_KEY } from 'src/data/env';
import appStorage from 'src/helpers/storage';
import {
  AppReducerAction,
  AppReducerActionKind,
  AppReducerState,
} from 'src/types';

const getState = async () => {
  const state = await appStorage.getItem(PERSIST_KEY);
  return state;
};

const usePersistedReducer = (
  reducer: (
    state: AppReducerState,
    action: AppReducerAction
  ) => AppReducerState,
  initialState: AppReducerState
) => {
  const [loading, setLoading] = useState(true);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    async function loadState() {
      setLoading(true);
      const storeState = await getState();
      if (storeState) {
        dispatch({
          type: AppReducerActionKind.SET_DEFAULT,
          payload: storeState,
        });
      }
      setLoading(false);
    }
    loadState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterBlacklist = (_state: AppReducerState) => {
    const blacklist = ['authorizationToken', 'profile'];
    return Object.keys(_state).reduce((acc: any, key) => {
      if (!blacklist.includes(key)) {
        acc[key] = _state[key as keyof AppReducerState];
      }
      return acc;
    }, {} as AppReducerState);
  };

  useEffect(() => {
    const persistState = filterBlacklist(state);
    appStorage.setItem(PERSIST_KEY, persistState);
  }, [state]);

  return { state, dispatch, loading };
};

export default usePersistedReducer;
