import { gql } from '@apollo/client';

export const GET_TRANSACTION_BY_PK = gql`
  query getTransactionByPk($id: uuid!) {
    transaction_by_pk(id: $id) {
      amount
      counterparty
      created_at
      data
      direction
      fees
      id
      memo
      provider
      provider_reference
      status
      type
      updated_at
      wallet {
        currency
      }
    }
  }
`;
