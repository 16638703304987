import { object, string } from 'yup';

export const createTradeValidationSchema = object({
  amount: string().required('Please enter an amount'),
  payment_method_id: string().required('Please select a payment method'),
});

export const chatValidationSchema = object({
  file: object().nullable(),
  body: string().when('file', {
    is: (file: any) => !Boolean(file),
    then: (s) => s.required('Please enter a message'),
  }),
});
