import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import { API_URL, PERSIST_KEY, WS_URL } from '../data/env';
import appStorage from './storage';
import { AppReducerState } from 'src/types';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';

const httpLink = createHttpLink({
  uri: API_URL,
});

export const wsClient = createClient({
  url: WS_URL,
  lazy: true,
  connectionParams: async () => {
    const state = (await appStorage.getItem(PERSIST_KEY)) as AppReducerState;
    const token = state.user?.accessToken || '';
    return {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  },
});

const wsLink = new GraphQLWsLink(wsClient);

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const state = (await appStorage.getItem(PERSIST_KEY)) as AppReducerState;
  const token =
    headers && headers['use-pin-token']
      ? headers['use-pin-token']
      : state.user?.accessToken || state.resetToken || '';
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // if (graphQLErrors.some((err) => err.extensions.code === 'access-denied')) {
    // WTF???? Why would you want to clear the storage if there's an access-denied error?
    //   appStorage.removeItem(PERSIST_KEY);
    // }
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

export const apiService = new ApolloClient({
  link: from([errorLink, splitLink]),
  cache: new InMemoryCache(),
});
