import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSkeletonText,
  IonText,
} from '@ionic/react';
import { FC, useContext, useState } from 'react';

import './PaymentMethods.scss';
import BackButton from 'src/components/BackButton';
import { PlusIcon } from 'src/assets/icons';
import { useQuery } from '@apollo/client';
import { GET_PAYMENT_METHODS } from '../../queries';
import AppContext from 'src/AppContext';
import { capitalize } from 'lodash';
import AddPaymentMethod from 'src/modules/ads/modals/AddPaymentMethod';
import { PaymentMethod } from 'src/types';

const PaymentMethods: FC = () => {
  const { profile } = useContext(AppContext);
  const { data, loading, refetch } = useQuery<{
    payment_method: PaymentMethod[];
  }>(GET_PAYMENT_METHODS, {
    variables: {
      userId: profile?.id,
    },
  });

  const [state, setState] = useState({
    isAddPaymentMethodOpen: false,
    activePaymentMethod: null,
  });

  const handleStateUpdate = (newState: any) =>
    setState({ ...state, ...newState });

  const onAddPaymentMethodDismiss = () => {
    handleStateUpdate({
      isAddPaymentMethodOpen: false,
      activePaymentMethod: null,
    });
    refetch();
  };

  const renderPaymentMethods = () => {
    if (loading) {
      return (
        <>
          <IonItem className='trades__skeleton'>
            <IonLabel>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
            </IonLabel>
          </IonItem>
          <IonItem className='trades__skeleton'>
            <IonLabel>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
            </IonLabel>
          </IonItem>
          <IonItem className='trades__skeleton'>
            <IonLabel>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
              <IonSkeletonText animated={true}></IonSkeletonText>
            </IonLabel>
          </IonItem>
        </>
      );
    }

    return data?.payment_method.map((method: any) => {
      return (
        <IonItem className='paymentMethods__method' key={method.id}>
          <IonLabel className='paymentMethods__methodLabel'>
            <div>
              <IonText>
                <h2>{method.payment_source.name}</h2>
              </IonText>
              <div className='paymentMethods__methodFields'>
                {Object.keys(method.data).map((key) => {
                  return (
                    <IonText color={'tertiary'}>
                      <p key={key}>
                        <span>{capitalize(key)}: </span>
                        <span>{method.data[key]}</span>
                      </p>
                    </IonText>
                  );
                })}
              </div>
            </div>
            <IonButton
              onClick={() => {
                handleStateUpdate({
                  activePaymentMethod: method,
                  isAddPaymentMethodOpen: true,
                });
              }}
              fill='clear'
              className='link'
            >
              Edit
            </IonButton>
          </IonLabel>
        </IonItem>
      );
    });
  };

  return (
    <IonPage className='paymentMethods'>
      <IonHeader className='paymentMethods__header' translucent={true}>
        <div className='paymentMethods__headerBg'></div>
        <div className='paymentMethods__headerContent'>
          <div className='paymentMethods__headerTop'>
            <BackButton />
            <div className='paymentMethods__title'>
              <IonText>
                <h1>Payment Methods</h1>
              </IonText>
            </div>
          </div>
        </div>
      </IonHeader>
      <IonContent className='paymentMethods__content' fullscreen={true}>
        <IonList lines='full'>{renderPaymentMethods()}</IonList>
        <IonFab slot='fixed' vertical='bottom' horizontal='end'>
          <IonFabButton
            onClick={() => handleStateUpdate({ isAddPaymentMethodOpen: true })}
            size='small'
            color='medium'
          >
            <PlusIcon />
            <span>Add Method</span>
          </IonFabButton>
        </IonFab>
      </IonContent>
      <AddPaymentMethod
        paymentMethod={state.activePaymentMethod}
        isOpen={state.isAddPaymentMethodOpen}
        onDismiss={onAddPaymentMethodDismiss}
      />
    </IonPage>
  );
};

export default PaymentMethods;
