//Auth routes
export const authSlug = 'auth';
export const authPath = `/${authSlug}`;
export const loginSlug = 'login';
export const loginPath = `${authPath}/${loginSlug}`;
export const registerSlug = 'register';
export const registerPath = `${authPath}/${registerSlug}`;
export const verifyEmailSlug = 'verify-email';
export const verifyEmailPath = `${authPath}/${verifyEmailSlug}`;
export const forgotPasswordSlug = 'forgot-password';
export const forgotPasswordPath = `${authPath}/${forgotPasswordSlug}`;
export const resetPasswordSlug = 'reset-password';
export const resetPasswordPath = `${authPath}/${resetPasswordSlug}`;

// Onboarding routes
export const onboardingSlug = 'onboarding';
export const onboardingPath = `/${onboardingSlug}`;
export const setPinSlug = 'set-pin';
export const setPinPath = `${onboardingPath}/${setPinSlug}`;
export const kycSlug = 'kyc';
export const kycPath = `${onboardingPath}/${kycSlug}`;
export const validateKycSlug = 'validate-kyc';
export const validateKycPath = `${onboardingPath}/${validateKycSlug}`;

// Tabs routes
export const tabsSlug = 'tabs';
export const tabsPath = `/${tabsSlug}`;
export const homeSlug = 'home';
export const homePath = `${tabsPath}/${homeSlug}`;
export const adsTabSlug = 'ads';
export const adsTabPath = `${tabsPath}/${adsTabSlug}`;
export const tradesSlug = 'trades';
export const tradesPath = `${tabsPath}/${tradesSlug}`;
export const transactionsSlug = 'transactions';
export const transactionsPath = `${tabsPath}/${transactionsSlug}`;

// Wallet routes
export const walletSlug = 'wallet';
export const walletPath = `/${walletSlug}`;
export const addMoneySlug = 'add-money';
export const addMoneyPath = `${walletPath}/${addMoneySlug}`;
export const withdrawSlug = 'withdraw';
export const withdrawPath = `${walletPath}/${withdrawSlug}`;

// Ads routes
export const adsSlug = 'ads';
export const adsPath = `/${adsSlug}`;
export const postAdSlug = 'post-ad';
export const postAdPath = `${adsPath}/${postAdSlug}`;
export const postTradeRequestSlug = 'post-trade-request';
export const postTradeRequestPath = `${adsPath}/${postTradeRequestSlug}`;

// Trade routes
export const tradeSlug = 'trade';
export const tradePath = `/${tradeSlug}`;
export const createTradeSlug = 'create-trade';
export const createTradePath = `${tradePath}/${createTradeSlug}`;
export const acceptTradeRequestSlug = 'accept-trade';
export const acceptTradeRequestPath = `${tradePath}/${acceptTradeRequestSlug}`;
export const tradeDetailsSlug = 'trade-details';
export const tradeDetailsPath = `${tradePath}/${tradeDetailsSlug}`;
export const tradeChatSlug = 'trade-chat';
export const tradeChatPath = `${tradePath}/${tradeChatSlug}`;
export const tradeRequestChatSlug = 'request-chat';
export const tradeRequestChatPath = `${tradePath}/${tradeRequestChatSlug}`;
export const tradeRequestNegotiationsSlug = 'request-negotiations';
export const tradeRequestNegotiationsPath = `${tradePath}/${tradeRequestNegotiationsSlug}`;

// Transaction routes
export const transactionSlug = 'transaction';
export const transactionPath = `/${transactionSlug}`;
export const transactionDetailsSlug = 'transaction-details';
export const transactionDetailsPath = `${transactionPath}/${transactionDetailsSlug}`;

// Settings routes
export const settingsSlug = 'settings';
export const settingsPath = `/${settingsSlug}`;
export const paymentMethodsSlug = 'payment-methods';
export const paymentMethodsPath = `${settingsPath}/${paymentMethodsSlug}`;
