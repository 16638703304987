import { FC, useContext, useMemo, useState } from 'react';

import './AddBankAccountModal.scss';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonSpinner,
  IonText,
  useIonToast,
} from '@ionic/react';
import { Checkmark, CloseIcon, ArrowDown } from '../../../../assets/icons';
import { isApolloError } from '@apollo/client';
import { useFormik } from 'formik';
import { addBankAccount, verifyBank } from '../../api';
import AutoComplete from 'src/components/AutoComplete';
import { Bank } from 'src/modules/common/types';
import { saveBankAccountSchema } from '../../validators';
import AppContext from 'src/AppContext';
import { IObject } from 'src/types';
import { omit } from 'lodash';

interface AddBankAccountModalProps {
  isOpen: boolean;
  onDismiss: (bankAccountId?: IObject) => void;
  banks: Bank[];
  refresh: () => void;
}

interface AddBankAccountState {
  isVerifyingAccount: boolean;
  isBankModalOpen: boolean;
}

const AddBankAccountModal: FC<AddBankAccountModalProps> = ({
  isOpen,
  onDismiss,
  banks,
  refresh,
}) => {
  const [present] = useIonToast();

  const { profile } = useContext(AppContext);

  const initialValues = {
    bank_code: '',
    account_number: '',
    account_name: '',
    bank_name: '',
    wallet_id: profile?.wallets[0].id as string,
  };

  const onSubmit = async () => {
    const data = {
      ...values,
    };
    try {
      const res = await addBankAccount(data);
      present({
        message: 'Bank account added',
        duration: 3000,
        color: 'success',
        position: 'bottom',
      });
      refresh();
      onDismiss(omit(res.data.insert_bank_account_one, ['__typename', 'id']));
    } catch (e: any) {
      isApolloError(e) &&
        present({
          message: e.graphQLErrors[0].message,
          duration: 3000,
          color: 'danger',
          position: 'bottom',
        });
    }
  };

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    isValid,
    setFieldValue,
    resetForm,
  } = useFormik<typeof initialValues>({
    initialValues,
    onSubmit,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: saveBankAccountSchema,
  });

  const closeModal = () => {
    resetForm();
    onDismiss();
  };

  const [state, setState] = useState<AddBankAccountState>({
    isVerifyingAccount: false,
    isBankModalOpen: false,
  });

  const handleStateUpdate = (newState: Partial<AddBankAccountState>) =>
    setState((_state) => ({ ..._state, ...newState }));

  const bankSelectionChanged = (bank: string[] | string) => {
    setFieldValue('bank_code', bank as string);

    handleStateUpdate({ isBankModalOpen: false });
    verifyAccount(values.account_number, bank as string);
  };

  const bankName = useMemo(() => {
    if (values.bank_code !== '') {
      setFieldValue(
        'bank_name',
        banks.filter((bank) => bank.bank_code === values.bank_code)[0].name
      );

      return values.bank_name;
    }
  }, [banks, setFieldValue, values.bank_code, values.bank_name]);

  const verifyAccount = (account_number: string, bank_code: string) => {
    if (!account_number || account_number.length < 10 || !bank_code) {
      return;
    }

    handleStateUpdate({
      isVerifyingAccount: true,
    });

    verifyBank({
      account_number: account_number,
      bank_code: bank_code,
    })
      .then((res) => {
        handleStateUpdate({
          isVerifyingAccount: false,
        });
        setFieldValue('account_name', res.data.verifyBank.data.account_name);
      })
      .catch((err: any) => {
        isApolloError(err) &&
          present({
            message: err.graphQLErrors[0].message,
            duration: 3000,
            color: 'danger',
            position: 'bottom',
          });
        handleStateUpdate({
          isVerifyingAccount: false,
        });
      });
  };

  return (
    <>
      <IonModal
        isOpen={isOpen}
        className='addBankAccount'
        onDidDismiss={closeModal}
      >
        <div className='addBankAccount__header'>
          <IonButton
            className='addBankAccount__close'
            fill='clear'
            onClick={closeModal}
            color={'dark'}
          >
            <CloseIcon />
          </IonButton>
          <IonText color={'dark'} className='addBankAccount__title'>
            <h4>Add Bank Account</h4>
          </IonText>
        </div>
        <form className='addBankAccount__form' onSubmit={handleSubmit}>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position='stacked'>Bank</IonLabel>
                  <div
                    className='addBankAccount__select'
                    role='button'
                    onClick={() => handleStateUpdate({ isBankModalOpen: true })}
                  >
                    <>
                      {banks && values.bank_code === ''
                        ? 'Select Bank'
                        : bankName}
                      <ArrowDown />
                    </>
                  </div>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position='stacked'>Account Number</IonLabel>
                  <IonInput
                    type='text'
                    inputMode='numeric'
                    placeholder='Enter Account Number'
                    onIonInput={handleChange}
                    name='account_number'
                    onIonBlur={(e) => {
                      handleBlur(e);
                      verifyAccount(e.target.value as string, values.bank_code);
                    }}
                    value={values.account_number}
                  ></IonInput>
                </IonItem>
                <div className='addBankAccount__accountName'>
                  {state.isVerifyingAccount ? (
                    <IonSpinner name='crescent'></IonSpinner>
                  ) : (
                    values.account_name !== '' && (
                      <>
                        <Checkmark />
                        <IonText color='tertiary'>
                          <span>{values.account_name}</span>
                        </IonText>
                      </>
                    )
                  )}
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton
                  type='submit'
                  expand='full'
                  disabled={!isValid || isSubmitting}
                >
                  Save{' '}
                  {isSubmitting && <IonSpinner name='crescent'></IonSpinner>}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonModal>
      <IonModal
        className='modal-fullscreen'
        isOpen={state.isBankModalOpen}
        onDidDismiss={() => handleStateUpdate({ isBankModalOpen: false })}
      >
        <AutoComplete
          items={banks.map((b) => ({
            label: b.name,
            value: b.bank_code,
          }))}
          value={values.bank_code}
          title='Select Bank'
          onSelectionCancel={() =>
            handleStateUpdate({ isBankModalOpen: false })
          }
          onSelectionChange={bankSelectionChanged}
        />
      </IonModal>
    </>
  );
};

export default AddBankAccountModal;
