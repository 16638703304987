import { IonApp, IonSpinner, setupIonicReact } from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
// import '@ionic/react/css/float-elements.css';
// import '@ionic/react/css/text-alignment.css';
// import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
// import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import './theme/main.scss';

import AppRouter from './AppRouter';
import AppContext from './AppContext';
import { appReducer, appReducerInitialState } from './AppReducer';
import { AppReducerActionKind, PaymentSource, User } from './types';
import usePersistedReducer from './hooks/usePersistedReducer';
import { useCallback, useEffect, useState } from 'react';
import {
  fetchBanks,
  fetchCurrencies,
  fetchPaymentSources,
  fetchProfile,
} from './modules/common/api';
import { apiService, wsClient } from './helpers';
import { Bank } from './modules/common/types';
import AuthorizationModal from './components/AuthorizationModal';
import If from './components/If';
import appStorage from './helpers/storage';
import { CAN_RESEND_KEY } from './data/constants';
import { ApolloProvider } from '@apollo/client';

setupIonicReact({
  mode: 'ios',
});

interface AppState {
  paymentSources: PaymentSource[];
  banks: Bank[];
  oneSignal: any;
  pwaPromptEvent: any;
  currencies: any[];
  isFetchingGlobalData: boolean;
  isAuthorizationModalOpen: boolean;
  onPinAuthorized: ((token: string) => void) | null;
}

const App: React.FC = () => {
  const [appState, setAppState] = useState<AppState>({
    paymentSources: [],
    banks: [],
    oneSignal: null,
    isAuthorizationModalOpen: false,
    onPinAuthorized: null,
    currencies: [],
    isFetchingGlobalData: false,
    pwaPromptEvent: null,
  });

  const handleStateUpdate = (newState: Partial<AppState>) =>
    setAppState((_state) => ({ ..._state, ...newState }));

  const { state, dispatch, loading } = usePersistedReducer(
    appReducer,
    appReducerInitialState
  );
  const setUser = (user: User | null) => {
    dispatch({ type: AppReducerActionKind.SET_USER, payload: user });
  };

  const logout = () => {
    wsClient.dispose();
    dispatch({
      type: AppReducerActionKind.SET_DEFAULT,
      payload: appReducerInitialState,
    });
    appStorage.setItem(CAN_RESEND_KEY, JSON.stringify(false));
    if (appState.oneSignal) {
      appState.oneSignal.logout();
    }
  };

  const setResetToken = (token: string) => {
    dispatch({ type: AppReducerActionKind.SET_RESET_TOKEN, payload: token });
  };

  const getGlobalData = useCallback(async () => {
    handleStateUpdate({ isFetchingGlobalData: true });
    const paymentSources = await fetchPaymentSources();
    const banks = await fetchBanks();
    const currencies = await fetchCurrencies();
    handleStateUpdate({
      paymentSources: paymentSources.data.payment_source,
      banks: banks.data.getBanks.data,
      currencies: currencies.data.currency,
      isFetchingGlobalData: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProfile = useCallback(async () => {
    if (state.user) {
      dispatch({
        type: AppReducerActionKind.SET_IS_FETCHING_PROFILE,
        payload: true,
      });
      const profile = await fetchProfile(state.user.id);
      dispatch({
        type: AppReducerActionKind.SET_PROFILE,
        payload: profile.data?.user_by_pk!,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.user]);

  const refreshProfile = async () => {
    if (state.user) {
      const profile = await fetchProfile(state.user.id);
      dispatch({
        type: AppReducerActionKind.SET_PROFILE,
        payload: profile.data?.user_by_pk!,
      });
      return profile;
    }
  };

  const onGetAuthorization = (onPinAuthorized: (token: string) => void) => {
    handleStateUpdate({ isAuthorizationModalOpen: true, onPinAuthorized });
  };

  const handlePinAuthorized = (token: string) => {
    dispatch({ type: AppReducerActionKind.SET_AUTH_TOKEN, payload: token });
    appState.onPinAuthorized && appState.onPinAuthorized(token);
    handleStateUpdate({
      isAuthorizationModalOpen: false,
      onPinAuthorized: null,
    });
  };

  const setOneSignalInstance = useCallback((oneSignal: any) => {
    handleStateUpdate({ oneSignal });
  }, []);

  useEffect(() => {
    if (state.user) {
      getProfile();
      getGlobalData();
    }
  }, [state.user, getGlobalData, getProfile]);

  // useEffect(() => {
  //   const handleBeforeInstallPrompt = (e: any) => {
  //     console.log({ e });

  //     // Prevent the mini-infobar from appearing on mobile
  //     e.preventDefault();
  //     // Save the event for later (to show the install prompt)
  //     handleStateUpdate({ pwaPromptEvent: e });
  //   };

  //   window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

  //   return () => {
  //     window.removeEventListener(
  //       'beforeinstallprompt',
  //       handleBeforeInstallPrompt
  //     );
  //   };
  // }, []);

  return (
    <IonApp className='app'>
      {loading ? (
        <IonSpinner className='app__loader' name='crescent' />
      ) : (
        <AppContext.Provider
          value={{
            user: state.user,
            isFetchingProfile: state.isFetchingProfile,
            profile: state.profile,
            setUser,
            setResetToken,
            logout,
            setOneSignalInstance,
            refreshProfile,
            oneSignal: appState.oneSignal,
            authorizeAction: onGetAuthorization,
            paymentSources: appState.paymentSources,
            resetToken: state.resetToken,
            banks: appState.banks,
            currencies: appState.currencies,
            pwaInstallPromptEvent: appState.pwaPromptEvent,
          }}
        >
          {appState.isFetchingGlobalData ? (
            <IonSpinner className='app__loader' name='crescent' />
          ) : (
            <ApolloProvider client={apiService}>
              <AppRouter />
            </ApolloProvider>
          )}
          <If condition={appState.isAuthorizationModalOpen}>
            <AuthorizationModal
              isOpen={appState.isAuthorizationModalOpen}
              onDismiss={() =>
                handleStateUpdate({ isAuthorizationModalOpen: false })
              }
              onPinAuthorized={handlePinAuthorized}
            />
          </If>
        </AppContext.Provider>
      )}
    </IonApp>
  );
};

export default App;
