import { NotificationType } from 'src/types';
import {
  homePath,
  tradeChatPath,
  tradeDetailsPath,
  transactionDetailsPath,
} from './pageRoutes';

export const NOTIFICATION_ACTIONS = {
  [NotificationType.CHAT]: {
    route: tradeChatPath,
  },
  [NotificationType.TRADE]: {
    route: tradeDetailsPath,
  },
  [NotificationType.TRANSACTION]: {
    route: transactionDetailsPath,
  },
  [NotificationType.SYSTEM]: {
    route: homePath,
  },
  [NotificationType.AD]: {
    route: homePath,
  },
  [NotificationType.TRADE_REQUEST]: {
    route: homePath,
  },
};

export const CAN_RESEND_KEY = 'canResendBVNOtp';
