import { FC, useContext } from 'react';
import { Redirect } from 'react-router';
import AppContext from 'src/AppContext';

const AuthRouteGuard: FC<any> = ({ children }) => {
  const { user } = useContext(AppContext);

  if (user) {
    return <Redirect to='/' />;
  }

  return <>{children}</>;
};

export default AuthRouteGuard;
