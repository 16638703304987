import { IonRouterOutlet } from '@ionic/react';
import { FC } from 'react';
import { Redirect, Route } from 'react-router';
import {
  adsPath,
  postAdPath,
  postTradeRequestPath,
} from '../../data/pageRoutes';
import PostAd from './pages/PostAd';
import PostTradeRequest from './pages/PostTradeRequest';

const Ads: FC = () => {
  return (
    <IonRouterOutlet>
      <Route exact path={postAdPath} component={PostAd}></Route>
      <Route
        exact
        path={postTradeRequestPath}
        component={PostTradeRequest}
      ></Route>
      <Route exact path={adsPath}>
        <Redirect to={postAdPath} />
      </Route>
    </IonRouterOutlet>
  );
};

export default Ads;
