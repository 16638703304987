import { IonModal, IonButton, IonText } from '@ionic/react';
import { FC } from 'react';
import { CloseIcon } from 'src/assets/icons';

import './ConfirmTradeAction.scss';
import { TradeStatus } from 'src/types';

interface ConfirmTradeActionProps {
  isOpen: boolean;
  confirmStatus:
    | TradeStatus.CANCELLED
    | TradeStatus.PAID
    | TradeStatus.COMPLETED
    | TradeStatus.IN_DISPUTE
    | null;
  onDismiss: (isConfirmed: boolean) => void;
}

const statusTexts = {
  [TradeStatus.CANCELLED]: {
    title: 'Cancel Trade',
    text: 'Are you sure you want to cancel this trade?',
    confirmText: 'Yes, Cancel Trade',
  },
  [TradeStatus.PAID]: {
    title: 'Confirm Payment',
    text: 'Please ensure you have made payment and confirmed, before we notify merchant.',
    confirmText: 'Yes, Confirm Payment',
  },
  [TradeStatus.COMPLETED]: {
    title: 'Confirm Trade',
    text: 'Please ensure you have received payment and confirmed, before we complete trade.',
    confirmText: 'Yes, Confirm Trade',
  },
  [TradeStatus.IN_DISPUTE]: {
    title: 'Dispute Trade',
    text: 'Are you sure you want to dispute this trade?',
    confirmText: 'Yes, Dispute Trade',
  },
};

const ConfirmTradeAction: FC<ConfirmTradeActionProps> = ({
  isOpen,
  onDismiss,
  confirmStatus,
}) => {
  return (
    <IonModal
      isOpen={isOpen}
      className='confirmTradeAction'
      onDidDismiss={() => onDismiss(false)}
    >
      {confirmStatus && (
        <>
          <div className='confirmTradeAction__header'>
            <IonButton
              className='confirmTradeAction__close'
              fill='clear'
              onClick={() => onDismiss(false)}
              color={'dark'}
            >
              <CloseIcon />
            </IonButton>
            <IonText color={'dark'} className='confirmTradeAction__title'>
              <h4>{statusTexts[confirmStatus].title}</h4>
            </IonText>
          </div>
          <IonText>
            <p className='confirmTradeAction__text'>
              {statusTexts[confirmStatus].text}
            </p>
          </IonText>
          <div className='confirmTradeAction__actions'>
            <IonButton
              onClick={() => onDismiss(false)}
              expand='full'
              fill='solid'
              color={'medium'}
            >
              Cancel
            </IonButton>
            <IonButton
              onClick={() => onDismiss(true)}
              expand='full'
              fill='solid'
              color={'primary'}
            >
              {statusTexts[confirmStatus].confirmText}
            </IonButton>
          </div>
        </>
      )}
    </IonModal>
  );
};

export default ConfirmTradeAction;
