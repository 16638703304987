import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonModal,
  IonRow,
  IonSpinner,
  IonText,
  useIonToast,
} from '@ionic/react';
import { useFormik } from 'formik';
import { FC, useRef } from 'react';
import { CloseIcon } from 'src/assets/icons';
import { validatePin } from 'src/modules/common/api';
import { otpValidationSchema } from 'src/modules/onboarding/validators';
import { OtpSelector } from 'src/types';

import { isApolloError } from '@apollo/client';

import './AuthorizationModal.scss';

interface AuthorizationModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  onPinAuthorized?: (token: string) => void;
}

const AuthorizationModal: FC<AuthorizationModalProps> = ({
  isOpen,
  onDismiss,
  onPinAuthorized,
}) => {
  const [present] = useIonToast();
  const initialValues = {
    char1: '',
    char2: '',
    char3: '',
    char4: '',
  };

  const inputRef = useRef<HTMLIonInputElement[]>([]);

  const onSubmit = async (values: typeof initialValues) => {
    const code = `${values.char1}${values.char2}${values.char3}${values.char4}`;
    try {
      const res = await validatePin(code);
      onPinAuthorized &&
        onPinAuthorized(res.data?.validatePin.data.access_token!);
    } catch (e: any) {
      isApolloError(e) &&
        present({
          message: e.graphQLErrors[0].message,
          duration: 3000,
          color: 'danger',
        });
    }
  };

  const {
    values,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormik<typeof initialValues>({
    initialValues,
    onSubmit,
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: otpValidationSchema,
  });

  const handleOnKeyUp = (
    e: React.KeyboardEvent<HTMLIonInputElement>,
    index: number
  ) => {
    setTimeout(() => {
      if (e.code === 'Backspace' && index === 1) {
        return;
      }

      if (e.code === 'Backspace' && index !== 1) {
        inputRef.current[index - 2].setFocus();
        return;
      }
      if (index !== 4) {
        inputRef.current[index].setFocus();
      }
    }, 50);
  };

  const handlePaste = (e: any) => {
    e.preventDefault();
    const paste = e.clipboardData.getData('text/plain');
    const pasteArray = paste.split('');
    const pasteLength = pasteArray.length;
    if (pasteLength > 4) return;
    for (let i = 0; i < pasteLength; i++) {
      setFieldValue(`char${i + 1}`, pasteArray[i]);
      if (i === 3) {
        inputRef.current[i].setFocus();
      }
    }
  };
  return (
    <IonModal
      className='authorizationModal'
      isOpen={isOpen}
      onDidDismiss={onDismiss}
    >
      <div className='authorizationModal__header'>
        <IonButton
          className='authorizationModal__close'
          fill='clear'
          onClick={onDismiss}
          color={'dark'}
        >
          <CloseIcon />
        </IonButton>
        <IonText color={'dark'} className='authorizationModal__title'>
          <h4>Enter your pin</h4>
        </IonText>
      </div>
      <form className='authorizationModal__form' onSubmit={handleSubmit}>
        <IonGrid>
          <IonRow>
            {Array.from({ length: 4 }).map((_, index) => {
              const pos = index + 1;
              const selector = `char${pos}` as OtpSelector;
              return (
                <IonCol key={selector}>
                  <IonItem>
                    <IonInput
                      max={9}
                      legacy={true}
                      id={selector}
                      maxlength={1}
                      type='password'
                      name={selector}
                      placeholder='●'
                      inputMode='numeric'
                      onPaste={handlePaste}
                      onIonBlur={handleBlur}
                      value={values[selector]}
                      onIonInput={handleChange}
                      onIonChange={handleChange}
                      onKeyUp={(e) => handleOnKeyUp(e, pos)}
                      ref={(el: HTMLIonInputElement) =>
                        (inputRef.current[index] = el)
                      }
                    ></IonInput>
                  </IonItem>
                </IonCol>
              );
            })}
          </IonRow>
        </IonGrid>
        <IonButton
          className='authorizationModal__submit'
          disabled={!isValid || isSubmitting}
          expand='full'
          fill='solid'
          type='submit'
          color={'primary'}
        >
          <span className='authorizationModal__submitText'>
            Continue
            {isSubmitting && <IonSpinner name='crescent' />}
          </span>
        </IonButton>
      </form>
    </IonModal>
  );
};

export default AuthorizationModal;
