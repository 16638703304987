import { gql } from '@apollo/client';

export const VERIFY_BANK = gql`
  mutation VerifyBank($data: VerifyBankInput!) {
    verifyBank(verifyBankInput: $data) {
      data {
        account_name
      }
    }
  }
`;

export const ADD_BANK_ACCOUNT = gql`
  mutation addBankAccount($data: bank_account_insert_input!) {
    insert_bank_account_one(object: $data) {
      id
      bank_code
      account_name
      account_number
      wallet_id
    }
  }
`;

export const WITHDRAW = gql`
  mutation withdraw($data: PayoutInput!) {
    payout(payoutInput: $data) {
      provider
    }
  }
`;
