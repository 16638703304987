import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonLabel,
  IonPage,
} from '@ionic/react';
import {
  AdsIcon,
  HomeIcon,
  TradesIcon,
  TransactionIcon,
} from '../../../../assets/icons';
import {
  adsTabPath,
  homePath,
  tabsPath,
  tradesPath,
  transactionsPath,
} from '../../../../data/pageRoutes';
import { FC } from 'react';
import { Redirect, Route } from 'react-router';

import Ads from '../../pages/Ads';
import Home from '../../pages/Home';
import Trades from '../../pages/Trades';
import Transactions from '../../pages/Transactions';

import './TabsLayout.scss';

const Tabs: FC = () => {
  return (
    <IonPage>
      <div className='onesignal-customlink-container'></div>
      <IonTabs className='tabs'>
        <IonRouterOutlet>
          <Redirect exact path={tabsPath} to={homePath} />
          <Route exact path={homePath} component={Home} />
          <Route exact path={adsTabPath} component={Ads} />
          <Route exact path={transactionsPath} component={Transactions} />
          <Route exact path={tradesPath} component={Trades} />
          <Route exact path={tabsPath}>
            <Redirect to={homePath} />
          </Route>
        </IonRouterOutlet>
        <IonTabBar slot='bottom'>
          <IonTabButton tab='home' href={homePath}>
            <HomeIcon />
            <IonLabel>Home</IonLabel>
          </IonTabButton>
          <IonTabButton tab='ads' href={adsTabPath}>
            <AdsIcon />
            <IonLabel>Ads</IonLabel>
          </IonTabButton>
          <IonTabButton tab='trades' href={tradesPath}>
            <TradesIcon />
            <IonLabel>Trades</IonLabel>
          </IonTabButton>
          <IonTabButton tab='transactions' href={transactionsPath}>
            <TransactionIcon />
            <IonLabel>Transactions</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonPage>
  );
};

export default Tabs;
