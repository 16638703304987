import OneSignalWeb from 'react-onesignal';
import OneSignal from 'onesignal-cordova-plugin';
import { isPlatform } from '@ionic/react';
import { ONE_SIGNAL_APP_ID } from 'src/data/env';

// Call this function when your app starts
async function OneSignalInit(onNotificationClick: (e: any) => void) {
  const clickListener = function (event: any) {
    onNotificationClick(event);
  };
  if (!isPlatform('mobileweb')) {
    // Uncomment to set OneSignal device logging to VERBOSE
    // OneSignal.Debug.setLogLevel(6);

    // Uncomment to set OneSignal visual logging to VERBOSE
    // OneSignal.Debug.setAlertLevel(6);

    OneSignal.initialize(ONE_SIGNAL_APP_ID);

    OneSignal.Notifications.addEventListener('click', clickListener);

    // Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
    OneSignal.Notifications.requestPermission(true).then(
      (accepted: boolean) => {
        console.log('User accepted notifications: ' + accepted);
      }
    );
    return OneSignal;
  } else {
    await OneSignalWeb.init({
      appId: ONE_SIGNAL_APP_ID,
      allowLocalhostAsSecureOrigin: true,
      serviceWorkerParam: { scope: '/push/onesignal/' },
    }).then(() => {
      OneSignalWeb.Notifications.requestPermission().then(() => {
        console.log('permission granted');
      });
      // OneSignalWeb.Notifications.addEventListener(
      //   'foregroundWillDisplay',
      //   (event) => {
      //     console.log(
      //       'OneSignal: foregroundWillDisplay event received:',
      //       event
      //     );
      //   }
      // );
      OneSignalWeb.Notifications.addEventListener('click', clickListener);
    });
    return OneSignalWeb;
  }
}

export default OneSignalInit;
