import { FC, useState } from 'react';
import {
  IonModal,
  IonButton,
  IonText,
  IonItem,
  IonLabel,
  IonInput,
  IonSpinner,
  useIonToast,
} from '@ionic/react';
import { useFormik } from 'formik';

import { CloseIcon } from 'src/assets/icons';

import './SetUsernameModal.scss';
import If from 'src/components/If';
import { object, string } from 'yup';
import { Profile } from 'src/types';
import { updateUser } from 'src/modules/common/api';
import { isApolloError } from '@apollo/client';

interface SetUsernameModalProps {
  isOpen: boolean;
  profile: Profile | null;
  onDismiss: (isUUpdated: boolean) => void;
}

interface SetUsernameModalState {
  isUpdatingUsername: boolean;
}

const SetUsernameModal: FC<SetUsernameModalProps> = ({
  isOpen,
  onDismiss,
  profile,
}) => {
  const [present] = useIonToast();

  const [state, setState] = useState<SetUsernameModalState>({
    isUpdatingUsername: false,
  });

  const handleStateUpdate = (newState: Partial<SetUsernameModalState>) =>
    setState((_state) => ({ ..._state, ...newState }));

  const onSubmit = async (values: { username: string }) => {
    handleStateUpdate({ isUpdatingUsername: true });
    try {
      const res = await updateUser(profile!.id, values);
      handleStateUpdate({ isUpdatingUsername: false });
      onDismiss(Boolean(res.data?.update_user_by_pk));
    } catch (e: any) {
      isApolloError(e) &&
        present({
          message: e.graphQLErrors[0].message,
          duration: 3000,
          color: 'danger',
        });
    }
  };

  const { values, handleBlur, handleChange, handleSubmit, isValid } = useFormik(
    {
      initialValues: {
        username: '',
      },
      onSubmit,
      validateOnChange: true,
      validationSchema: object({
        username: string().required('Username is required'),
      }),
    }
  );

  return (
    <IonModal
      className='setUsername'
      isOpen={isOpen}
      onDidDismiss={() => onDismiss(false)}
    >
      <form onSubmit={handleSubmit} className='setUsername__form'>
        <div className='setUsername__header'>
          <IonButton
            className='setUsername__close'
            fill='clear'
            onClick={() => onDismiss(false)}
            color={'dark'}
          >
            <CloseIcon />
          </IonButton>
          <IonText color={'dark'} className='setUsername__title'>
            <h4>Set Username</h4>
          </IonText>
        </div>
        <div className='setUsername__content'>
          <IonItem>
            <IonLabel position='stacked'>Username</IonLabel>
            <IonInput
              name='username'
              value={values.username}
              onIonBlur={handleBlur}
              onIonInput={handleChange}
              placeholder='Enter your username'
              type='text'
            />
          </IonItem>
        </div>
        <div className='acceptRequest__actions'>
          <IonButton
            fill='solid'
            expand='full'
            color={'medium'}
            onClick={() => onDismiss(false)}
          >
            Cancel
          </IonButton>
          <IonButton
            fill='solid'
            type='submit'
            expand='full'
            color={'primary'}
            disabled={!isValid}
          >
            Set Username
            <If condition={state.isUpdatingUsername}>
              <IonSpinner name='crescent' />
            </If>
          </IonButton>
        </div>
      </form>
    </IonModal>
  );
};

export default SetUsernameModal;
