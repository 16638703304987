import { IonModal, IonButton, IonText } from '@ionic/react';
import { FC } from 'react';
import { CloseIcon } from 'src/assets/icons';

import './EditTradeRequestModal.scss';
import { TradeRequest } from 'src/types';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import AmountInput from 'src/components/AmountInput';
import { isEqual } from 'lodash';

interface EditTradeRequestModalProps {
  isOpen: boolean;
  tradeRequest: TradeRequest;
  onDismiss: (
    isUpdated: boolean,
    update?: {
      amount: number;
      rate: number;
    }
  ) => void;
}

const EditTradeRequestModal: FC<EditTradeRequestModalProps> = ({
  isOpen,
  onDismiss,
  tradeRequest,
}) => {
  const { values, isValid, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      amount: tradeRequest.amount,
      rate: tradeRequest.rate,
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: object({
      amount: string()
        .test('is-zero', 'amount cannot be zero', (value) => value !== '0')
        .required('Please enter amount'),
      rate: string()
        .test('is-zero', 'rate cannot be zero', (value) => value !== '0')
        .required('Please enter a rate'),
    }),
    onSubmit: (values) => {
      if (
        isEqual(values, {
          amount: tradeRequest.amount,
          rate: tradeRequest.rate,
        })
      ) {
        return onDismiss(false);
      }
      onDismiss(true, values);
    },
  });

  return (
    <IonModal
      isOpen={isOpen}
      className='editRequest'
      onDidDismiss={() => onDismiss(false)}
    >
      <form className='editRequest__form' onSubmit={handleSubmit}>
        <div className='editRequest__header'>
          <IonButton
            className='editRequest__close'
            fill='clear'
            onClick={() => onDismiss(false)}
            color={'dark'}
          >
            <CloseIcon />
          </IonButton>
          <IonText color={'dark'} className='editRequest__title'>
            <h4>Edit trade request</h4>
          </IonText>
        </div>

        <div className='editRequest__content'>
          <AmountInput
            label='Amount'
            value={values.amount}
            name='amount'
            currency={tradeRequest.source_currency}
            onValueChange={(val) => setFieldValue('amount', val)}
          />
          <AmountInput
            label='Rate'
            name='rate'
            value={values.rate}
            onValueChange={(val) => setFieldValue('rate', val)}
          />
        </div>

        <div className='editRequest__actions'>
          <IonButton
            fill='solid'
            expand='full'
            color={'medium'}
            onClick={() => onDismiss(false)}
          >
            Cancel
          </IonButton>
          <IonButton
            fill='solid'
            type='submit'
            expand='full'
            color={'primary'}
            disabled={!isValid}
          >
            Edit request
          </IonButton>
        </div>
      </form>
    </IonModal>
  );
};

export default EditTradeRequestModal;
