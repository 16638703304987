import { IonSpinner } from '@ionic/react';
import { FC, Suspense, useContext } from 'react';
import { Redirect } from 'react-router';
import AppContext from 'src/AppContext';
import { loginPath } from 'src/data/pageRoutes';

const OnboardingRouteGuard: FC<any> = ({ children }) => {
  const { user, profile, isFetchingProfile } = useContext(AppContext);
  if (!user) {
    return <Redirect to={{ pathname: loginPath }} />;
  }
  if (!profile || isFetchingProfile) {
    return <IonSpinner className='app__loader' name='crescent' />;
  }
  return <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>;
};

export default OnboardingRouteGuard;
