import { IonRouterOutlet } from '@ionic/react';
import { Route } from 'react-router';
import { FC } from 'react';
import { paymentMethodsPath } from 'src/data/pageRoutes';
import PaymentMethods from './pages/PaymentMethods';

const Settings: FC = () => {
  return (
    <IonRouterOutlet>
      <Route exact path={paymentMethodsPath} component={PaymentMethods} />
    </IonRouterOutlet>
  );
};

export default Settings;
